import { Component} from '@angular/core';

@Component({
  selector: 'drag-drop',
  templateUrl: './oh.dragDrop.html',
  styleUrls: ['./oh.dragDrop.css']
})
export class DragDrop  {

  files: any = [];

  uploadFile(event) {
    console.log(event)
    for (let index = 0; index < event.length; index++) {
      const element = event[index];
      this.files.push(element.name)
    }  
  }
  deleteAttachment(index) {
    this.files.splice(index, 1)
  }
}