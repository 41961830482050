import { Component, ViewChild, OnInit, AfterViewInit } from '@angular/core';
import { environment } from '../../../environments/environment';

import { Ad, Loader, Camera } from './../../ohCore/components/oh.core';

import { shared } from 'src/environments/environmentShared';
import { OHService } from 'src/app/tis.ohService';
import { CoreService } from 'src/app/ind.coreService';
import { ohStorage } from 'src/app/ohCore/services/oh.core';
import { Router, ActivatedRoute } from '@angular/router';
import { INDAzureStorage } from 'src/app/azure/storage';

@Component({
	selector: 'ind-body',
	templateUrl: './ind.body.html'
})

export class Body implements OnInit, AfterViewInit {

	@ViewChild("loaderId", { static: true }) objLoader: Loader;
	@ViewChild("adId", { static: true }) objAd: Ad;
	@ViewChild("cameraId", { static: true }) objCamera: Camera;

	myStorage: ohStorage;

	constructor(private ohService: OHService, private cse: CoreService, private router: Router, private route: ActivatedRoute, private indAzureStorage: INDAzureStorage) {

		this.myStorage = new ohStorage();

		cse.config.jpo = {
			restSuffix: environment.hostType == 1 ? ((environment.env == 'local' || environment.env == 'production') ? '' : environment.env) : '',
			hosts: {
				main: {
					rest: environment.rest,
					service: environment.service
				},
				type: environment.hostType,
				after: environment.hostAfter
			}
		};

		if (typeof (environment.host) == "string") {
			cse.config.jpo.hosts.main.url = environment.protocol + "://" + environment.host;
		} else {
			cse.config.jpo.hosts.main.urlDinamic = true;
			cse.config.jpo.hosts.main.url = {};
			let hosts: any = environment.host;
			for (var i in hosts) {

				if (environment.hostType == 3) {
					cse.config.jpo.hosts.main.url[i] = environment.protocol + "://" + i.toLowerCase() + hosts[i];
				} else {
					cse.config.jpo.hosts.main.url[i] = environment.protocol + "://" + hosts[i];
				}

			}
		}

		/*
		cse.config.formatDate = "DD/MM/YYYY";
		cse.config.formatDateTime = "DD/MM/YYYY HH:mm:ss";
		cse.config.formatShortDateTime = "DD/MM/YYYY HH:mm";
		*/
		cse.config.formatDate = "dd/MM/yyyy";
		cse.config.formatDateTime = "dd/MM/yyyy HH:mm:ss";
		cse.config.formatShortDateTime = "dd/MM/yyyy HH:mm";

		var _lsData = this.myStorage.get("APM_DATA")

		if (_lsData) {

			try {
				this.cse.data.user = _lsData
				this.cse.mapFormatDates()

				let opts = window.location.href.split("#");
				if (opts.length == 1 || (opts.length == 2 && opts[1] == "/Login") || (opts.length > 1 && this.cse.validMainURL(opts[1]))) {
					this.router.navigate(["/" + shared.baseBackEnd], { relativeTo: this.route });
				}

			} catch (e) {
				this.myStorage.clear()
			}

		} else {
			this.myStorage.clear()
		}

	}

	ngOnInit() {
		this.ohService.initialize(this.objLoader, this.objAd, this.objCamera, {
			emailAdm: shared.support.email,
			number: shared.support.number,
			annexed: shared.support.anex,
			title: shared.support.title
		}, this.cse.config, this.cse.inland_main.logs);

	}

	ngAfterViewInit() {
		var child = document.getElementById("tisContent");
		child.parentNode.removeChild(child);
	}

}
