<div class="tableContent mb-0" *ngIf="!uno && vista !='hide'">
    <table class="table table-striped table-hover bg-white mb-0">
        <thead>
            <tr>
                <th>
                    <span>{{lang.file}} <span
                            class="text-muted font-italic">({{archivos.length}}/{{cantidadMaxima}})</span> <a
                            class="text-info ml-2" *ngIf="formatos.length>0" [ngbPopover]="tipFormatos" container="body"
                            triggers="mouseenter:mouseleave" placement="bottom"
                            [popoverTitle]="lang.formattedSupported"><i class="fas fa-info-circle"></i></a></span>
                </th>
                <th>
                    <span>{{lang.size}} <span class="text-muted font-italic">({{lang.MaxSize}}
                            {{mapSize(pesoMaximo)}})</span></span>
                </th>
                <th *ngIf="tipos.length>0">{{lang.type}}</th>
                <th *ngIf="!descriptionHide">{{lang.description}}</th>
                <th *ngIf="!fechaRegistroHide">{{lang.registerDate}}</th>
                <th *ngIf="!usuarioRegistroHide">{{lang.registerUser}}</th>
                <th style="width: 1rem" *ngIf="vista == 'editor'">
                    <i title="" class="fas fa-trash-alt text-muted text-size-10"></i>
                </th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let archivo of archivos; let e = index">
                <td>
                    <span *ngIf="archivo.porcentaje | async as porcentaje">
                        <a *ngIf="porcentaje==100" href="#"
                            (click)="$event.preventDefault(); descargar(e)">{{archivo.nombre}}</a>
                        <ngb-progressbar *ngIf="porcentaje<100" type="warning" [value]="porcentaje" [striped]="true"
                            [animated]="true"><i>{{porcentaje}}%</i></ngb-progressbar>
                    </span>
                    <div *ngIf="indAzureStorage.isEnabled() && !archivo.adjunto_id" class="progress"
                        id="pbl_{{archivo.uid}}">
                        <div id="pb_{{archivo.uid}}" role="progressbar"
                            class="bg-warning progress-bar progress-bar-animated progress-bar-striped"
                            style="width: 0%;"><i>0%</i></div>
                    </div>
                </td>
                <td style="width: 20%">
                    {{mapSize(archivo.tamano)}}
                </td>
                <td *ngIf="_adjuntos && _adjuntos[e] && tipos.length>0 && tipo_id && tipo_value">
                    <select class="form-control" [(ngModel)]="_adjuntos[e].tipo" name="inp_tipo_adjunto_{{e}}">
                        <option *ngFor="let tipo of tipos" [value]="tipo[tipo_id]">{{tipo[tipo_value]}}</option>
                    </select>
                </td>
                <td style="width: 20%" *ngIf="!descriptionHide">
                    <input *ngIf="_adjuntos && _adjuntos[e]" class="form-control" name="inp_descripción"
                        [(ngModel)]="_adjuntos[e].descripcion" type="text" [disabled]="vista != 'editor'">
                </td>

                <td style="width: 20%" *ngIf="!fechaRegistroHide">
                    {{archivo.fecha_registro | ohDate: cse.config.formatDateTime}}
                </td>
                <td style="width: 20%" *ngIf="!usuarioRegistroHide">
                    <oh-thumbnail *ngIf="archivo.usuario_registro_id" [id]="archivo.usuario_registro_id"
                        [name]="archivo.usuario_registro_nombres" [lastName]="archivo.usuario_registro_apellidos"
                        [size]="sm"></oh-thumbnail>
                    <span *ngIf="!archivo.usuario_registro_id">{{archivo.usuario_registro_nombres}}
                        {{archivo.usuario_registro_apellidos}}</span>
                </td>

                <td *ngIf="vista == 'editor'">
                    <span *ngIf="archivo.porcentaje | async as porcentaje">
                        <a *ngIf="porcentaje == 100" href="#" (click)="$event.preventDefault(); eliminar(e)"
                            class="text-danger text-size-10"><i class="fas fa-trash-alt"></i></a>
                        <a *ngIf="porcentaje<100" href="#" (click)="$event.preventDefault(); cancelar(e)"
                            class="text-danger text-size-10"><i class="fas fa-window-close"></i></a>
                    </span>
                </td>
            </tr>
        </tbody>
    </table>
    <ng-template #tipFormatos>
        <table class="table table-striped table-bordered table-hover bg-white" style="margin: 0px">
            <tbody>
                <tr *ngFor="let formato of formatos.split(',')">
                    <td>{{formato}}</td>
                </tr>
            </tbody>
        </table>
    </ng-template>

    <div *ngIf="vista == 'editor' && archivos.length != cantidadMaxima && draggable == true"
        class="uploadfilecontainer mt-3" (click)="inp_archivo.click()" ohDragDrop (onFileDropped)="cargar($event)">
    </div>
    <input [hidden]="true" #inp_archivo type="file" (change)="cargar($event)" [accept]="formatos" [multiple]="multiple">
    <a *ngIf="vista == 'editor' && archivos.length != cantidadMaxima && draggable == false" href="#"
        (click)="$event.preventDefault(); inp_archivo.click()" [disabled]="disabled" class="text-size-15"
        [ngClass]="disabled ? 'text-muted' : 'text-success'">
        <i class="fas fa-plus-square"></i>
    </a>
</div>
<div *ngIf="uno && vista !='hide'">
    <div class="custom-file form-control" *ngIf="archivos.length == 0 && vista == 'editor'">
        <input #inp_archivo type="file" id="inp_archivo" class="custom-file-input" (change)="cargar($event)"
            [accept]="formatos" [multiple]="false" [disabled]="disabled">
        <label class="custom-file-label" for="inp_archivo">{{lang.selectFile}}</label>
    </div>
    <div *ngIf="archivos.length != 0">
        <div *ngFor="let archivo of archivos; let e = index">
            <span *ngIf="archivo.porcentaje | async as porcentaje">
                <div class="input-group mb-3" *ngIf="porcentaje==100">
                    <div class="input-group-prepend" (click)="descargar(e)">
                        <span class="input-group-text text-primary"><i class="fas fa-download"></i></span>
                    </div>
                    <input type="text" [disabled]="true" class="form-control" value="{{archivo.nombre}}">
                    <div class="input-group-append" *ngIf="vista == 'editor'">
                        <button class="btn btn-outline-danger" (click)="$event.preventDefault(); eliminar(e)"
                            type="button"><i class="fas fa-times-circle"></i></button>
                    </div>
                </div>
                <ngb-progressbar *ngIf="porcentaje<100" type="warning" [value]="porcentaje" [striped]="true"
                    [animated]="true"><i>{{porcentaje}}%</i></ngb-progressbar>
            </span>
            <div *ngIf="indAzureStorage.isEnabled() && !archivo.adjunto_id" class="progress" id="pbl_{{archivo.uid}}">
                <div id="pb_{{archivo.uid}}" role="progressbar"
                    class="bg-warning progress-bar progress-bar-animated progress-bar-striped" style="width: 0%;">
                    <i>0%</i>
                </div>
            </div>
        </div>
    </div>
</div>