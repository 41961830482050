<div class="input-group" [ngClass]="{'oh_prtx_valid' : isValid, 'oh_prtx_invalid' : !isValid}">
    <div class="input-group-prepend">
        <button class="btn btn-outline-secondary" type="button" (click)="onRemove()"
            [disabled]="disabled || (min != null && quantity - increment < min)"><i class="fas fa-minus"></i></button>
    </div>
    <input type="text" class="form-control" value="{{quantity}}" [disabled]="true">
    <div class="input-group-append">
        <button class="btn btn-outline-secondary" type="button" (click)="onAdd()"
            [disabled]="disabled || (max != null && quantity + increment > max)"><i class="fas fa-plus"></i></button>
    </div>
</div>