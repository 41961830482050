export const shared = {
  proyect: "SD1",
  support: {
    email: "customer.tucontenedor360@inlandservices.com",
    number: "57 (320) 9277584 - 9277586 - 9277588",
    anex: "",
    title: "Tu Contenedor 360 - Incidencia"
  },
  systemId: 1, // Valor por defecto de sistema
  baseBackEnd: "Be",
  version: "2.3.5",
  key_send: "zxcfgh45#s-)1"
};