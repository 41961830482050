import { Jpo, JpoError } from "../../../ohCore/services/oh.core";
import { OHService } from "../../../tis.ohService";

export class pApsbusinessUnitAppointmentList {business_unit_id : number; name : string};

export class APSBusinessUnitServiceJPO {

    jpo : Jpo;

    constructor(public ohService : OHService){
        this.jpo = ohService.getOH().getJPO("ModuleAPS","APS","module.aps","APSBusinessUnitServiceImp");
    }

    apsbusinessUnitAppointmentList(call ? : { (resp: pApsbusinessUnitAppointmentList[]) }, handlerError ?: { (resp: JpoError) }){
        this.jpo.get("apsbusinessUnitAppointmentList",{
            response : (rs) => {
                if(call){
                    var out = [];
                        if(rs){
                            for(var i = 0; i < rs.length; i++){
                                out.push({business_unit_id : rs[i][0], name : rs[i][1]});
                            }
                        }
                    call(out);
                }
            }
        });
    }

}