<input type="hidden" #inp_date="ngModel" [(ngModel)]="dateValueFinal" [required]="required">
<div *ngIf="type == 'lineal'">
  <div style="width: fit-content;">
    <ngb-datepicker
      [ngClass]="{'required-ok' : (required || isValid) && dateValueFinal, 'required-error' : required && !dateValueFinal}"
      #inp_datepicker [(ngModel)]="__dateValue" (navigate)="date = $event.next" [dayTemplate]="currentDay"
      (dateSelect)="selectDate()" [minDate]="_minFinalDate" [maxDate]="_maxFinalDate" [disabled]="disabled"
      [markDisabled]="markDisabled"></ngb-datepicker>
    <div style="width: 100%; text-align: center;" *ngIf="_hour">
      <div style="width: 144px; display: inline-block;">
        <ngb-timepicker [(ngModel)]="time" [disabled]="disabled" (ngModelChange)="hourChange($event)"
          [hourStep]="hourStep" [minuteStep]="minuteStep" [secondStep]="secondStep" [meridian]="false"></ngb-timepicker>
      </div>
    </div>
  </div>
  <br>
  <button class="btn btn-sm btn-danger mt-1" *ngIf="__dateValue && !disabled && clean_option" (click)="clean()"
    type="button" [disabled]="disabled"><i class="fas fa-times"></i></button>
</div>
<div *ngIf="type == 'modal'">
  <div class="form-group" style="margin-bottom: 0rem;">
    <div class="input-group" *ngIf="!_hour">
      <input class="form-control"
        [ngClass]="{'required-ok' : (required || isValid) && dateValueFinal, 'required-error' : required && !dateValueFinal}"
        [placeholder]="_hour ? objDate.formatTime : objDate.format" name="inp_datepicker" [(ngModel)]="__dateValue"
        ngbDatepicker #inp_datepicker="ngbDatepicker" [markDisabled]="markDisabled" [dayTemplate]="currentDay"
        (dateSelect)="selectDate()" container="body" (change)="selectDate()" [minDate]="_minFinalDate"
        [maxDate]="_maxFinalDate" [disabled]="disabled">
      <div class="input-group-append">
        <button class="btn btn-outline-secondary" (click)="openView(inp_datepicker, modalEditor)" type="button"
          [disabled]="disabled"><i class="far fa-calendar-alt"></i></button>
        <button class="btn btn-outline-danger" *ngIf="!disabled && clean_option" [disabled]="disabled || !__dateValue"
          (click)="clean()" type="button"><i class="fas fa-times"></i></button>
      </div>
    </div>
    <div class="input-group" *ngIf="_hour">
      <input class="form-control"
        [ngClass]="{'required-ok' : (required || isValid) && dateValueFinal, 'required-error' : required && !dateValueFinal}"
        [placeholder]="_hour ? objDate.formatTime : objDate.format" name="inp_datepicker" [(ngModel)]="dateValueFormat"
        [disabled]="true">
      <div class="input-group-append">
        <button class="btn btn-outline-secondary" (click)="openView(inp_datepicker, modalEditor)" type="button"
          [disabled]="disabled"><i class="far fa-calendar-alt"></i></button>
        <button class="btn btn-outline-danger" (click)="clean()" type="button" *ngIf="!disabled && clean_option"
          [disabled]="disabled || !__dateValue"><i class="fas fa-times"></i></button>
      </div>
    </div>
  </div>
</div>
<ng-template #modalEditor let-c="close" let-d="dismiss">
  <div class="modal-header">
    <h4 class="modal-title"><i class="far fa-calendar-alt"></i> {{lang.modal.date}} <span
        *ngIf="_hour">{{lang.modal.andHour}}</span></h4>
    <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body text-center">
    <ngb-datepicker
      [ngClass]="{'required-ok' : (required || isValid) && _dateValue && _time, 'required-error' : (required || isValid) && (!_dateValue || !_time)}"
      #inp_datepicker [(ngModel)]="_dateValue" [markDisabled]="markDisabled" (dateSelect)="selectDateModal()"
      [dayTemplate]="currentDay" (navigate)="date = $event.next" [minDate]="_minFinalDate" [maxDate]="_maxFinalDate">
    </ngb-datepicker>
    <div style="width: 100%; text-align: center;">
      <div style="width: 144px; display: inline-block;">
        <ngb-timepicker [(ngModel)]="_time" [disabled]="!_dateValue || disabled" [hourStep]="hourStep"
          [minuteStep]="minuteStep" [secondStep]="secondStep" (ngModelChange)="hourChangeModal($event)"
          [meridian]="false"></ngb-timepicker>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-danger" (click)="c('cancel')"><i class="fas fa-times"></i>
      {{lang.modal.cancel}}</button>
    <button type="button" class="btn btn-outline-success" (click)="c('save')"
      [disabled]="!_dateValue || !_time || !validSelectedDate"><i class="fas fa-calendar-times"></i>
      {{lang.modal.select}}</button>
  </div>
</ng-template>
<ng-template #currentDay let-date let-currentMonth="currentMonth" let-selected="selected" let-disabled="disabled"
  let-focused="focused">
  <span class="current-day" [class.currenttoday]="isToday(date)" [class.focused]="focused"
    [class.bg-primary]="selected">
    {{ date.day }}
  </span>
</ng-template>