import { Directive, HostListener, Input, Output, EventEmitter, ElementRef } from '@angular/core';
import { FormGroup } from '@angular/forms';
// <input type="text" ... [ohInputFormat]="'upper'" ...>
@Directive({
  selector: '[ohInputFormat]'
})

export class InputFormat {

  @Input('form') form: FormGroup;
  @Input('formControlName') formControlName: string;
  @Input('ohInputFormat') format: string;

  @Input() ngModel;
  @Output() ngModelChange = new EventEmitter();

  constructor(private inputRef: ElementRef) { }

  onChange = (quantity) => { }

  @HostListener('keyup') onKeyUp() {
    if (this.format == 'upper') {
      if (this.ngModel) {
        this.ngModelChange.emit(this.ngModel.toUpperCase());
      } else {
        const elRef = this.inputRef.nativeElement
        if (elRef.value && this.form && this.formControlName) {
          elRef.value = elRef.value.toUpperCase()
          this.form.controls[this.formControlName].setValue(elRef.value)
        }
      }
    }
    if (this.format == 'lower') {
      if (this.ngModel) {
        this.ngModelChange.emit(this.ngModel.toLowerCase());
      } else {
        const elRef = this.inputRef.nativeElement
        if (elRef.value && this.form && this.formControlName) {
          elRef.value = elRef.value.toLowerCase()
          this.form.controls[this.formControlName].setValue(elRef.value)
        }
      }
    }
    if (this.format == 'fontawesome') {
      if (this.ngModel) {
        this.ngModelChange.emit((this.ngModel.substr(0, 2) == "<i" ? this.ngModel.split('"')[1] : this.ngModel));
      }
    }
  }

  @HostListener('keypress') onkeypress() {
    if (this.format == 'upper') {
      if (this.ngModel) {
        this.ngModelChange.emit(this.ngModel.toUpperCase());
      } else {
        const elRef = this.inputRef.nativeElement;
        if (elRef.value && this.form && this.formControlName) {
          elRef.value = elRef.value.toUpperCase()
          this.form.controls[this.formControlName].setValue(elRef.value)
        }
      }
    }
    if (this.format == 'lower') {
      if (this.ngModel) {
        this.ngModelChange.emit(this.ngModel.toLowerCase());
      } else {
        const elRef = this.inputRef.nativeElement
        if (elRef.value && this.form && this.formControlName) {
          elRef.value = elRef.value.toLowerCase()
          this.form.controls[this.formControlName].setValue(elRef.value)
        }
      }
    }
    if (this.format == 'fontawesome') {
      if (this.ngModel) {
        this.ngModelChange.emit((this.ngModel.substr(0, 2) == "<i" ? this.ngModel.split('"')[1] : this.ngModel));
      }
    }
  }

  @HostListener('paste') onPaste($event: ClipboardEvent) {
    window.setTimeout(() => {
      if (this.format == 'upper') {
        if (this.ngModel) {
          this.ngModelChange.emit(this.ngModel.toUpperCase());
        }
      }
      if (this.format == 'lower') {
        if (this.ngModel) {
          this.ngModelChange.emit(this.ngModel.toLowerCase());
        }
      }
      if (this.format == 'fontawesome') {
        if (this.ngModel) {
          this.ngModelChange.emit((this.ngModel.substr(0, 2) == "<i" ? this.ngModel.split('"')[1] : this.ngModel));
        }
      }
    })
  }
  @HostListener('input', ['$event']) onInput($event) {

    if (this.format == 'upper') {
      var start = $event.target.selectionStart;
      var end = $event.target.selectionEnd;
      $event.target.value = $event.target.value.toUpperCase();
      $event.target.setSelectionRange(start, end);
      $event.preventDefault();
    }
    if (this.format == 'lower') {
      var start = $event.target.selectionStart;
      var end = $event.target.selectionEnd;
      $event.target.value = $event.target.value.toLowerCase();
      $event.target.setSelectionRange(start, end);
      $event.preventDefault();
    }
    if (this.format == 'fontawesome') {
      if (this.ngModel) {
        this.ngModelChange.emit((this.ngModel.substr(0, 2) == "<i" ? this.ngModel.split('"')[1] : this.ngModel));
      }
    }
  }
}
