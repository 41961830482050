<ng-template [ngIf]="module == 1">
    <!-- <input type="datetime-local" #inp_fecha="ngModel" class="form-control" name="{{name}}" [(ngModel)]="fecha_hora_descripcion"> -->
    <div class="input-group">
        <!--  ngbDatepicker #h="ngbDatepicker" [showWeekNumbers]="false" -->
        <!-- mask="9999-99-99 Hh:m0:s0" -->
        <input type="text" #inp_fecha="ngModel" class="form-control" name="{{name}}" [(ngModel)]="fecha_hora_descripcion" [readonly]="!pasteDate" [required]="required" (paste)="pastedDate($event, inp_fecha)">
        <div class="input-group-append">
            <!-- {'btn-outline-danger' : !fecha_hora, 'btn-outline-success' : fecha_hora} -->
            <button style="border-color: #6c757d;" [disabled]="disabled" class="btn" [ngClass]="required ? (fecha_hora ? 'btn-outline-success': 'btn-outline-danger') : ''" (click)="abrirModal()" [disabled]="disabled" type="button"><i class="far fa-calendar-alt"></i></button>
        </div>
    </div>
    <div *ngIf="inp_fecha.invalid && (inp_fecha.dirty || inp_fecha.touched )" class="alert alert-danger alert-form">
        <ul>
            <li *ngIf="inp_fecha.errors.required">El campo es requerido.</li>
        </ul>
    </div>
</ng-template>
<ng-template [ngIf]="module == 2">
    <button style="border-color: #6c757d;" *ngIf="!fecha_hora" [disabled]="disabled" class="btn btn-outline-danger rounded-0 rounded-right" (click)="abrirModal()" [disabled]="disabled" type="button"><i class="far fa-calendar-alt"></i></button>
    <button style="border-color: #6c757d;" *ngIf="fecha_hora" [disabled]="disabled" class="btn btn-outline-success rounded-0 rounded-right" (click)="abrirModal()" [disabled]="disabled" type="button"><i class="far fa-calendar-alt"></i></button>
</ng-template>
<ng-template [ngIf]="module == 3">
    <div class="card bg-white">
        <div class="card-header" *ngIf="head == 'visible'">
            <span class="h4 m-0">Seleccionar fecha y hora {{title}}</span>
        </div>
        <div style="text-align: center" class="card-body d-flex flex-wrap justify-content-center align-items-center">
            <div class="p-1">
                <ngb-datepicker #dp [(ngModel)]="fecha" [minDate]="min_date" [maxDate]="max_date"></ngb-datepicker>
            </div>
            <div class="p-1">
                <!-- [spinners]="false" -->
                <ngb-timepicker *ngIf="!onlyDate" #tm [(ngModel)]="hora" (keydown)="seleccionarEnter($event)" [meridian]="false"></ngb-timepicker>
            </div>
        </div>
        <div class="card-footer" style="text-align: right;">
            <button type="button" class="btn btn-outline-info mr-1" (click)="cancel()"><i class="fas fa-reply" aria-hidden="true"></i> Retornar</button>
            <button type="button" id="btn_confirm" class="btn btn-success" (click)="select()"><i class="far fa-hand-point-up" aria-hidden="true"></i> Aceptar</button>
        </div>
    </div>
</ng-template>