<div class="modal-header d-flex align-items-center">
    <span class="h5 m-0">Seleccionar Fecha y Hora</span>
    <button class="close d-flex text-dark m-0 p-0" (click)="cancelar()"><i class="fas fa-times text-size-10"></i></button>
</div>
<div class="modal-body">
    <div style="text-align: center">
        <ngb-datepicker #dp [(ngModel)]="fecha" [minDate]="min_date" [maxDate]="max_date"></ngb-datepicker><br>
        <ngb-timepicker *ngIf="!onlyDate" style="display: inline-block" #tm [(ngModel)]="hora" (keydown)="seleccionarEnter($event)" [meridian]="false" (ngModelChange)="validHour()"></ngb-timepicker>
        <div *ngIf="errorMin" class="w-100 text-center text-danger">
            <small>Fecha debe ser mayor a: {{pad(min_date.day)}}/{{pad(min_date.month)}}/{{min_date.year}} {{pad(min_date.hour)}}:{{pad(min_date.minute)}}</small>
        </div>
        <div *ngIf="errorMax" class="w-100 text-center text-danger">
            <small>Fecha debe ser menor a: {{pad(max_date.day)}}/{{pad(max_date.month)}}/{{max_date.year}} {{pad(max_date.hour)}}:{{pad(max_date.minute)}}</small>
        </div>
    </div>
</div>
<div class="modal-footer">
    <button type="button" class="btn btn-sm btn-outline-danger" (click)="cancelar()"><i class="far fa-times-circle"></i> Cancelar</button>
    <button *ngIf="!onlyDate" type="button" [disabled]="!hora || !fecha " class="btn btn-sm btn-success" (click)="guardar()"><i class="far fa-save"></i> Guardar</button>
    <button *ngIf="onlyDate" type="button" [disabled]="!fecha" class="btn btn-sm btn-success" (click)="guardar()"><i class="far fa-save"></i> Guardar</button>
</div>