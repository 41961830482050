import { Component, Input, EventEmitter, Output, ViewChild } from '@angular/core';

import { AngularFireStorage } from '@angular/fire/storage';
import { OHService } from 'src/app/tis.ohService';
import { MainService } from '../../services/oh.mainService';
import { of } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';

import { Util } from '../../services/util/oh.util';
import * as FileSaver from 'file-saver';
import { TranslocoService } from '@ngneat/transloco';
import { INDAzureStorage } from 'src/app/azure/storage';
import { environment } from 'src/environments/environment';

/*

[fotos]
	[
	  {
		adjunto_id : 1,
		id : "232",
		nombre : "foto_1",
		formato : "jpg",
		peso : 123,
		ubicacion : "",
		tipo_adjunto : 30892,
		accion : "N",
		url : "",
		thumbnail_id : 1,
		thumbnail_url : asd,
		accion : 'N'
	  }
	]

[ruta]
		/MAERSK_TC360_DESA/ADM

[config]
	{
	  cantidad : 3,
	  autocerrar : true
	}

(eventosFoto)
		onEvent($event){
			$event.response.cargarAntes((files : any) => {

			})
			$event.response.cargarFinalizado((cargado : boolean) => {

			})
			$event.response.eliminarFoto((adjunto_id : number) => {
				if(adjunto_id){

				}
			})
		}

<oh-imageUpload [fotos]="fotos" [ruta]="dcs.config.ruta_firestore" [config]="configFoto" (eventos)="onEvent($event)"></oh-imageUpload>

*/

/*

Considerar actualizar el valor de adjunto id para que no se borre cuando no se usa

	this.sDOSalidaVehiculoService.sdosalidaCamionRegistrar({
		eir_id 								: this.eir_id,
		observacion_salida_camion 			: this.observacion.observacion,
		notificar_encargado_salida_camion 	: this.observacion.notificar_encargado == true ? '1':'0',
		usuario_id 							: this.cse.data.user.data.userid,
		camaras_salida						: JSON.stringify(this.deteccion_placa_completa),
		evidencia_salida					: JSON.stringify(this.fotos),
		tipo_adjunto_eir_id					: this.scs.config.catalogo.tipo_adjunto_eir_id
	}, (resp : pSdosalidaCamionRegistrar) => {
		if (resp.resp_estado == 1) {
			this.ohService.getOH().getAd().success(resp.resp_mensaje);

			for(var i in this.fotos){
				if(this.fotos[i].accion == 'N'){
					this.fotos[i].adjunto_id = true;
				}
			}
			......
*/

/*
  type : "edit" -> photos = [src1, src2]
  type : "view" -> photos = [{photo : src1, id : 1}]
*/

export interface imageUploadConfig {
	cantidad?: number
	auto_cerrar?: boolean
	auto_abrir?: boolean
}

@Component({
	selector: 'oh-imageUpload',
	templateUrl: './oh.imageUpload.html',
	styleUrls: ['./oh.imageUpload.css']
})

export class ImageUpload {

	private ohMainService: MainService;

	private tipo_adjunto_fire_id: number = 30892;
	private tipo_adjunto_azure_id: number = 48028;

	/*
		[
			{
				adjunto_id : 1,
				id : "232",
				nombre : "foto_1",
				formato : "jpg",
				peso : 123,
				ubicacion : "",
				tipo_adjunto : 30892,
				accion : "N",
				url : "",
				thumbnail_id : 1,
				thumbnail_url : asd
			}
		]
	*/
	private _fotos: any = [];

	/*
		[
			{
				porcentaje: of(100)
			}
		]
	*/
	_fotos_data: any = [];

	/*
		[
			data:image/jpeg;base64,/9j/4A,
			data:image/jpeg;base64,/1s/8B
		]
	*/
	public _fotos_camara: any = [];
	private _fotos_thumbnai: any = [];

	imagenSeleccionada: any;

	@Input() ruta: any;
	@Input() readonly: boolean;
	@Input() enable_preview: boolean = true; // Deprecated
	@Input() mode_preview: boolean;
	@Input() show_in_mode_preview: boolean = false;

	@Input('fotos')
	set fotos(fotos: any) {
		if (fotos) {
			//this.archivos = [];
			this._fotos = fotos; //this._adjuntos.adjunto_id  // Si tiene adjunto_id es edición sino es nuevo

			this._fotos_data = [];
			for (var i in this._fotos) {
				this._fotos_data[i] = {
					uid: this._fotos[i].id,
					url: this._fotos[i].url,
					porcentaje: of(100),
					thumbnail_uid: this._fotos[i].thumbnail_id,
					thumbnail_url: this._fotos[i].thumbnail_url
				}
			}

			this.cargarFotosCamara();

		} else {
			this._fotos_data = [];
		}
	}

	_thumbnail: any

	@Input('thumbnail')
	set thumbnail(thumbnail: any) {
		if (thumbnail) {

			if (typeof (thumbnail) == "boolean") {
				this._thumbnail = {
					maxResizeWidth: 300,
					maxResizeHeight: 300
				}
			} else {
				this._thumbnail = thumbnail
			}
		}
	}

	@Input() cantidad: number = 1;     			// Defecto 1
	@Input() auto_cerrar: boolean = true;  	// Defecto Si     | Valida si al completar de cargar la foto se cierra
	@Input() auto_abrir: boolean = false;  	// Defecto Si     | Valida si al completar de cargar la foto se cierra

	@Input('config')
	set config(config: imageUploadConfig) {
		if (config) {
			if (config.cantidad) {
				this.cantidad = config.cantidad;
			}
			if (config.auto_cerrar) {
				this.auto_cerrar = config.auto_cerrar;
			}
			if (config.auto_abrir) {
				this.auto_abrir = config.auto_abrir;
				if (window['browserInfo'] && window['browserInfo'].mobile) {
					this.abrirCamara()
				}
			}
		}
	}

	@Output() eventos: EventEmitter<any>;

	@Input() enable_paste: boolean = true;

	@Input() restrict_mode: boolean;

	cargando: boolean;

	private util: Util;

	_generatedId: string

	langSubs: any
	lang: any = {}

	constructor(private ohCore: OHService, private indAzureStorage: INDAzureStorage, private fireStorage: AngularFireStorage, private modalService: NgbModal, private translocoService: TranslocoService) {
		this.ohMainService = ohCore.getOH();
		this._fotos = [];
		this.util = new Util(null);
		this.eventos = new EventEmitter<any>();
		this.enableClip();

		this._generatedId = this.ohMainService.getUtil().getUID()

		this.langSubs = this.translocoService.selectTranslateObject('component.imageUpload').subscribe((valor) => {
			this.lang = valor
		});

	}

	mostrarImagen(content: any, foto: any) {
		this.imagenSeleccionada = foto;
		this.modalService.open(content, { backdrop: 'static', keyboard: false, size: 'lg', scrollable: true }).result.then((result) => { });
	}

	paste_function: any
	enableClip() {

		var _this = this

		this.paste_function = (e: any) => {

			if (document.activeElement && document.activeElement.id == this._generatedId) {

				if (e.clipboardData != false) {
					var items = e.clipboardData.items;
					if (items) {
						let files = []
						for (var i = 0; i < items.length; i++) {
							if (items[i].type.indexOf("image") == -1) continue;
							files.push(items[i].getAsFile());
						}
						if (files.length > 0) {
							_this.abrirCargaDrop(files)
						}
					};
				}

			}

		}

		if (this.enable_paste) {
			window.addEventListener("paste", this.paste_function, false)
		}
	}

	ngOnDestroy() {

		if (this.restrict_mode && this._fotos) {
			for (var i = 0; i < this._fotos.length; i++) {
				if (this._fotos[i].accion == 'N' && !this._fotos[i].adjunto_id) {
					this.fotoEliminarConfirmar(i);
					i--;
				}
			}
		}
		if (this.enable_paste) {
			window.removeEventListener("paste", this.paste_function, false)
		}

		this._fotos = [];
		this._fotos_data = [];
		this._fotos_camara = [];
	}

	private cargarFotosCamara() {
		let _cargas = [];
		for (let foto of this._fotos) {
			if (foto.url) {
				_cargas.push(this.cargarFotoUrl(foto.url));
			} else {
				_cargas.push(this.cargarFotoUid(foto.id));
			}
		}
		Promise.all(_cargas).then(fotos_camara => {
			this._fotos_camara = fotos_camara
		});
	}

	private cargarFotoUid(uid) {
		return new Promise((resolve, reject) => {

			if (this.indAzureStorage.isEnabled()) {

				let _ruta = this.ohCore.getOH().getUtil().mapRealRoute(environment.firebase_coleccion_base, this.ruta)
				let _file = this.indAzureStorage.download(_ruta.container, _ruta.folder + uid)

				_file.then(it => {

					var reader = new FileReader();
					reader.readAsDataURL(it);
					reader.onloadend = () => {
						let base = ("" + reader.result).split(',')[1];
						resolve("data:image/jpeg;base64," + base);
					}

				})

			} else {
				this.fireStorage.ref('/' + this.ruta + '/' + uid).getDownloadURL().subscribe((url) => {
					this.cargarHttp(url, resolve);
				});
			}

		});
	}

	private cargarFotoUrl(url) {
		return new Promise((resolve, reject) => {
			this.cargarHttp(url, resolve);
		});
	}

	private cargarHttp(url: string, resolve: any) {
		var xhr = new XMLHttpRequest();
		xhr.responseType = 'blob';
		xhr.onload = (event) => {
			let archivo: Blob = xhr.response;
			var reader = new FileReader();
			reader.readAsDataURL(archivo);
			reader.onloadend = () => {
				let base = ("" + reader.result).split(',')[1];
				resolve("data:image/jpeg;base64," + base);
			}
		};
		xhr.open('GET', url);
		xhr.send();
	}

	abrirCamara() {

		this.ohMainService.getCamera().open({
			source: this._fotos_camara,
			source_thumbnai: this._fotos_thumbnai,
			closeOnFull: this.auto_cerrar,
			size: this.cantidad,
			habilitarAdjunto: false,
			thumbnail_config: this._thumbnail,
			onClose: (fotos: any, thumbnail_photos: any) => {
				this.mapPictures(fotos, thumbnail_photos);
			},
			onBeforeDelete: (indice: number, call: any) => {
				if (this._fotos[indice].adjunto_id) {
					this.ohCore.getOH().getUtil().confirm(this.lang.confirmDelete, () => {
						call();
						this.fotoEliminarConfirmar(indice);
					});
				} else {
					call();
					this.fotoEliminarConfirmar(indice);
				}
			},
			onDelete: (indice: number) => {
				this.fotoEliminar(indice);
			}
		})
	}

	private mapPictures(fotos: any, thumbnail_photos?: any) {
		if (this.indAzureStorage.isEnabled()) {
			this.mapearFotosAzure(fotos, thumbnail_photos)
		} else {
			this.mapearFotosFire(fotos, thumbnail_photos)
		}
	}


	private mapearFotosAzure(fotos: any, thumbnail_photos?: any) {

		let _ruta = this.ohCore.getOH().getUtil().mapRealRoute(environment.firebase_coleccion_base, this.ruta) // container | folder

		this.cargando = true;
		this._fotos_camara = fotos;
		this._fotos_thumbnai = thumbnail_photos;

		this.eventoCargarAntes((solicitud: any) => {
			if (solicitud) {
				solicitud(fotos);
			}
		})

		let _cargando = [];
		let _cargando_tb = [];

		for (var i = 0; i < fotos.length; i++) {

			if (!this._fotos_data[i]) {

				let _base = fotos[i].split(",");

				let _archivo: Blob = this.ohCore.getOH().getUtil().base64strToBlog(_base[1], "image/jpeg");

				let _base_tb = null
				let _archivo_tb: Blob
				let uid_tb: any

				if (thumbnail_photos && thumbnail_photos[i]) {
					_base_tb = thumbnail_photos[i].split(",");
					_archivo_tb = this.ohCore.getOH().getUtil().base64strToBlog(_base_tb[1], "image/jpeg");
					uid_tb = this.ohCore.getOH().getUtil().getUID()
				}

				this._fotos_data[i] = {
					uid: this.ohCore.getOH().getUtil().getUID(),
					archivo: _archivo,
					thumbnail_uid: uid_tb,
					thumbnail_archivo: _archivo_tb,
					porcentaje: of(0)
				};

				this._fotos.push({
					id: this._fotos_data[i].uid,
					nombre: "foto_" + (i + 1),
					formato: "jpg",
					peso: _archivo.size,
					ubicacion: _ruta.folder,
					tipo_adjunto: this.tipo_adjunto_azure_id,
					accion: "N",
					thumbnail_id: this._fotos_data[i].thumbnail_uid,
				})


				_cargando.push({
					name: _ruta.folder + this._fotos_data[i].uid,
					blob: this._fotos_data[i].archivo,
					index: this._fotos_data[i].uid,
					onFinished: (uid) => {
						let _arc = this._fotos_data.find(it => it.uid == uid)
						if (_arc) {
							_arc.porcentaje = of(100)
						}
					}
				})

				if (thumbnail_photos && thumbnail_photos[i]) {
					_cargando_tb.push({
						name: _ruta.folder + this._fotos_data[i].thumbnail_uid,
						blob: this._fotos_data[i].thumbnail_archivo,
						index: this._fotos_data[i].thumbnail_uid
					})
				}

			}

		}

		if (thumbnail_photos) {
			_cargando = _cargando.concat(_cargando_tb);
		}

		this.indAzureStorage.uploadItems(_ruta.container, _cargando).then(values => {

			for (var e in this._fotos) {

				let value = values.find(it => it.url.indexOf(this._fotos[e].id) >= 0)
				if (value) {
					this._fotos[e].url = value.url
					this._fotos_data[e].url = value.url
				}

				if (thumbnail_photos) {
					let value = values.find(it => it.url.indexOf(this._fotos[e].thumbnail_id) >= 0)
					if (value) {
						this._fotos[e].thumbnail_url = value.url
					}
				}

			}

			this.eventoCargarFinalizado((solicitud: any) => {
				if (solicitud) {
					solicitud(true);
				}
			})

			this.cargando = false;

		})

	}

	private mapearFotosFire(fotos: any, thumbnail_photos?: any) {

		this.cargando = true;
		this._fotos_camara = fotos;
		this._fotos_thumbnai = thumbnail_photos;

		this.eventoCargarAntes((solicitud: any) => {
			if (solicitud) {
				solicitud(fotos);
			}
		})

		let _cargando = [];
		let _cargando_tb = [];

		for (var i = 0; i < fotos.length; i++) {

			if (!this._fotos_data[i]) {

				let _base = fotos[i].split(",");

				let _archivo: Blob = this.ohCore.getOH().getUtil().base64strToBlog(_base[1], "image/jpeg");

				let _base_tb = null
				let _archivo_tb: Blob
				let uid_tb: any

				if (thumbnail_photos && thumbnail_photos[i]) {
					_base_tb = thumbnail_photos[i].split(",");
					_archivo_tb = this.ohCore.getOH().getUtil().base64strToBlog(_base_tb[1], "image/jpeg");
					uid_tb = this.ohCore.getOH().getUtil().getUID()
				}

				this._fotos_data[i] = {
					uid: this.ohCore.getOH().getUtil().getUID(),
					archivo: _archivo,
					thumbnail_uid: uid_tb,
					thumbnail_archivo: _archivo_tb
				};

				this._fotos.push({
					id: this._fotos_data[i].uid,
					nombre: "foto_" + (i + 1),
					formato: "jpg",
					peso: _archivo.size,
					ubicacion: this.ruta,
					tipo_adjunto: this.tipo_adjunto_fire_id,
					accion: "N",
					thumbnail_id: this._fotos_data[i].thumbnail_uid,
				})

				const storageRef = this.fireStorage.ref(this.ruta + this._fotos_data[i].uid);
				this._fotos_data[i].storage = this.fireStorage.upload(this.ruta + this._fotos_data[i].uid, this._fotos_data[i].archivo);

				let fotos_promresp;
				_cargando.push(new Promise((resolve, reject) => {
					fotos_promresp = resolve;
				}))

				this._fotos_data[i].porcentaje = this._fotos_data[i].storage.percentageChanges();
				this._fotos_data[i].cambios = this._fotos_data[i].storage.snapshotChanges();
				this._fotos_data[i].cambios.pipe(
					finalize(() => {
						storageRef.getDownloadURL().subscribe((url) => {
							fotos_promresp(url);
						});
					})
				).subscribe();

				if (thumbnail_photos && thumbnail_photos[i]) {
					const storageRef_tb = this.fireStorage.ref(this.ruta + this._fotos_data[i].thumbnail_uid);
					this._fotos_data[i].storage_tb = this.fireStorage.upload(this.ruta + this._fotos_data[i].thumbnail_uid, this._fotos_data[i].thumbnail_archivo);

					let fotos_promresp_tb;
					_cargando_tb.push(new Promise((resolve, reject) => {
						fotos_promresp_tb = resolve;
					}))

					this._fotos_data[i].porcentaje_tb = this._fotos_data[i].storage_tb.percentageChanges();
					this._fotos_data[i].cambios_tb = this._fotos_data[i].storage_tb.snapshotChanges();
					this._fotos_data[i].cambios_tb.pipe(
						finalize(() => {
							storageRef_tb.getDownloadURL().subscribe((url) => {
								fotos_promresp_tb(url);
							});
						})
					).subscribe();
				}

			}

		}

		if (thumbnail_photos) {
			_cargando = _cargando.concat(_cargando_tb);
		}

		Promise.all(_cargando).then(values => {
			for (var e in this._fotos) {
				for (var i = 0; i < values.length; i++) {
					let ind = values[i].indexOf(this._fotos[e].id);
					if (ind >= 0) {
						this._fotos[e].url = values[i];
						this._fotos_data[e].url = values[i]
						if (thumbnail_photos) {
							this._fotos[e].thumbnail_url = values[i + (values.length / 2)];
						}

						break;
					}
				}
			}

			this.eventoCargarFinalizado((solicitud: any) => {
				if (solicitud) {
					solicitud(true);
				}
			})

			this.cargando = false;
		});
	}


	abrirCargaDrop(evento: any) {
		this.abrirCarga({
			target: {
				files: evento
			}
		})
	}

	abrirCarga(evento: any, inp_adjunto?: any) {

		let camera = this.ohMainService.getCamera();

		let _cargando = [];
		let _fotos_camara_tmp = [];
		let _fotos_camara_thumbnail_tmp = [];

		for (let i = 0; i < evento.target.files.length; i++) {

			if (i < (this.cantidad - this._fotos_camara.length)) {

				let fotos_promresp;
				_cargando.push(new Promise((resolve, reject) => {
					fotos_promresp = resolve;
				}))

				let reader = new FileReader()

				let format = evento.target.files[i].type;
				reader.readAsDataURL(evento.target.files[i])
				reader.onload = (readerEvent: any) => {
					var image = new Image();
					image.onload = (imageEvent) => {

						let minimo_ancho = image.naturalWidth;
						let minimo_alto = image.naturalHeight;

						if (camera.maxResizeWidth && minimo_ancho > camera.maxResizeWidth) {
							minimo_ancho = camera.maxResizeWidth;
							minimo_alto = Math.floor((minimo_ancho * image.naturalHeight) / image.naturalWidth);
						}
						if (camera.maxResizeHeight && minimo_alto > camera.maxResizeHeight) {
							minimo_alto = camera.maxResizeHeight;
							minimo_ancho = Math.floor((minimo_alto * image.naturalWidth) / image.naturalHeight);
						}

						var canvas = document.createElement('canvas');
						canvas.width = minimo_ancho;
						canvas.height = minimo_alto;
						canvas.getContext('2d').drawImage(image, 0, 0, image.naturalWidth, image.naturalHeight, 0, 0, minimo_ancho, minimo_alto);

						_fotos_camara_tmp.push(canvas.toDataURL(format ? format : "image/jpeg"));

						if (this._thumbnail) {
							let minimo_ancho = image.naturalWidth;
							let minimo_alto = image.naturalHeight;
							if (this._thumbnail.maxResizeWidth) {
								minimo_ancho = this._thumbnail.maxResizeWidth;
								minimo_alto = Math.floor((minimo_ancho * image.naturalHeight) / image.naturalWidth);
							}
							if (this._thumbnail.maxResizeHeight && minimo_alto > this._thumbnail.maxResizeHeight) {
								minimo_alto = this._thumbnail.maxResizeHeight;
								minimo_ancho = Math.floor((minimo_alto * image.naturalWidth) / image.naturalHeight);
							}
							var canvas_sm = document.createElement('canvas');
							canvas_sm.height = minimo_alto;
							canvas_sm.width = minimo_ancho;
							canvas_sm.getContext('2d').drawImage(image, 0, 0, image.naturalWidth, image.naturalHeight, 0, 0, minimo_ancho, minimo_alto);

							_fotos_camara_thumbnail_tmp.push(canvas_sm.toDataURL(format ? format : "image/jpeg"));
						}


						fotos_promresp(true);

					}
					image.src = readerEvent.target.result;

				};

			} else {
				this.ohCore.getOH().getAd().warning(this.lang.maxLimit + this.cantidad)
				break
			}
		}

		Promise.all(_cargando).then(values => {

			if (this._fotos_camara && this._fotos_camara.length > 0) {
				_fotos_camara_tmp = this._fotos_camara.concat(_fotos_camara_tmp)
			}

			if (this._fotos_thumbnai && this._fotos_thumbnai.length > 0) {
				_fotos_camara_thumbnail_tmp = this._fotos_thumbnai.concat(_fotos_camara_thumbnail_tmp)
			}

			this.mapPictures(_fotos_camara_tmp, _fotos_camara_thumbnail_tmp);
			if (inp_adjunto) {
				inp_adjunto.value = '';
			}
		});

	}

	fotoEliminar(indice: number) {
		if (this._fotos[indice].adjunto_id) {
			this.ohCore.getOH().getUtil().confirm(this.lang.confirmDelete, () => {
				this.fotoEliminarConfirmar(indice);
			});
		} else {
			this.fotoEliminarConfirmar(indice);
		}
	}

	private fotoEliminarConfirmar(indice: number) {
		let _adjunto_id = this._fotos[indice].adjunto_id;
		var _charge = this._fotos_data[indice];
		if (_charge) {

			if (this.indAzureStorage.isEnabled()) {
				let _ruta = this.ohCore.getOH().getUtil().mapRealRoute(environment.firebase_coleccion_base, this.ruta)

				let deleteFiles = [_ruta.folder + _charge.uid]
				if (_charge.thumbnail_uid) {
					deleteFiles.push(_ruta.folder + _charge.thumbnail_uid)
				}
				this.indAzureStorage.deleteItems(_ruta.container, deleteFiles).then()
			} else {
				this.fireStorage.ref(this.ruta + _charge.uid).delete()
				if (_charge.thumbnail_uid) {
					this.fireStorage.ref(this.ruta + _charge.thumbnail_uid).delete()
					this._fotos_thumbnai.splice(indice, 1);
				}
			}

			this._fotos_data.splice(indice, 1);
			this._fotos_camara.splice(indice, 1);
			this._fotos.splice(indice, 1);
			for (var i in this._fotos) {
				this._fotos[i].nombre = "foto_" + (i + 1)
			}
		}
		this.eventoEliminarFoto((solicitud: any) => {
			if (solicitud) {
				solicitud({ adjunto_id: _adjunto_id });
			}
		})
	}

	private eventoCargarAntes(evento) {
		this.eventos.emit({
			response: {
				cargarAntes: evento,
				cargarFinalizado: (call: any) => { },
				eliminarFoto: (call: any) => { }
			}
		});
	}

	private eventoCargarFinalizado(evento: any) {
		this.eventos.emit({
			response: {
				cargarAntes: (call: any) => { },
				cargarFinalizado: evento,
				eliminarFoto: (call: any) => { }
			}
		});
	}

	private eventoEliminarFoto(evento) {
		this.eventos.emit({
			response: {
				cargarAntes: (call: any) => { },
				cargarFinalizado: (call: any) => { },
				eliminarFoto: evento
			}
		});
	}


	//-------------------------------------------------------------------
	@ViewChild("modalDetail", { static: true }) modalDetail: NgbModalRef;
	foto_vista: any;
	indice: number;

	vistaPrevia(photo: any, index: number, openFoto: boolean) {
		this.indice = index;
		this.openPhoto(photo);
	}

	openPhoto(photo: any) {
		this.foto_vista = photo;
		this.modalService.open(this.modalDetail, { windowClass: 'oh_dark-modal', size: 'lg', scrollable: true });
	}

	atras() {
		this.indice = this.indice - 1;
		this.foto_vista = this._fotos_camara[this.indice];
	}

	adelante() {
		this.indice = this.indice + 1;
		this.foto_vista = this._fotos_camara[this.indice];
	}

	descargar(photo: any) {
		let p = photo.split(",");
		//let nombre = Math.random()*1000+2;
		photo = p[1];
		FileSaver.saveAs(this.util.base64strToBlog(photo), "foto_.jpg");
	}

}