import { Component, Input, Output, EventEmitter, ElementRef, ViewChild } from "@angular/core";
import { OHService } from 'src/app/tis.ohService';
import * as moment from "moment";
/*

	Implementacion 
	
	export class excelVillaObj {
		transporte : string; 
		empresa_cliente_nombre : string;
		metro_cubico : number;
	}
	var excelVillaCnf = {
		"transporte" : true,
		"conecta" : "empresa_cliente_nombre",
		"m3" : { campo : "metro_cubico", tipo : "number"},
		"today":{ campo : "metro_cubico", tipo : "date", format:"dd/MM/yyyy hh:mm"},
	}

	export class MonitorvillaLoad extends TMSBase implements OnInit, AfterViewInit, OnDestroy {

		excelVillaCnf = excelVillaCnf;

		cargarExcel(excel : excelVillaObj[]){

		}

		cargarExcelError(e){

		}

	}
	
	<oh-paste-table [config]="excelVillaCnf" (onPaste)="cargarExcel($event)" (onError)="cargarExcelError($event)"></oh-paste-table>

*/
@Component({
	selector: 'oh-paste-table',
	templateUrl: './oh.pasteTable.html',
	styleUrls: ['./oh.pasteTable.css']
})

export class PasteTable {

	@ViewChild('ohc_text_data', { static: true }) ohc_text_data: ElementRef;

	data: any;
	table: any[] = [];
	@Input() name: any = '';
	@Input() disabled: boolean = false;
	@Input() option: any = 0;
	@Input() lista: any;
	@Output() listaChange: EventEmitter<any>; // hace binding al padre.

	@Input() config: any;
	_config: any = null;

	@Output() onPaste = new EventEmitter(); // llama a un evento
	@Output() onError = new EventEmitter(); // llama a un evento


	constructor(private ohCore: OHService) {
		this.listaChange = new EventEmitter<any>();
	}

	ngOnInit() {
		// Mapear encabezado de configuracion correctamente
		if (this.config) {
			this._config = {};
			//! For pasteTable
			if (this.option == 0) {
				let ind = 0;
				for (var id in this.config) {
					let indice_ = this.trimIfString(id.toLowerCase());
					if (typeof (this.config[id]) == "boolean") {
						this._config[indice_] = { campo: id, tipo: "string", indice: ind };
					} else if (typeof (this.config[id]) == "string") {
						this._config[indice_] = { campo: this.config[id], tipo: "string", indice: ind };
					} else {
						let _map: any = this.config[id];
						if (_map.campo && _map.tipo) {
							_map.indice = ind;
							this._config[indice_] = _map;
						} else {
							this._config[indice_] = { campo: this.config[id], tipo: _map.tipo, indice: ind };
						}

					}
					ind++;
				}
			}
			//! For bulk load ;)
			if (this.option == 1) {
				let arr_ = this.cloneArray(this.config);
				// console.log('this.config:', this.config)
				arr_.forEach((el, ind) => {
					let obj = { ...el, indice: ind }
					obj.label = obj.label ? obj.label : this.trimIfString(obj.id.split("_").join(" ").toUpperCase());
					obj.tipo = obj.type ? obj.type : 'string';
					this._config[el.id] = obj
				});
			}
		}
	}

	removeAccents(str) {
		return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
	}

	cloneArray(arr) {
		return arr.map(item => ({ ...item }));
	}

	getPlaceholder() {
		return (this.table.length == 0 ? 'Pegue' : 'Reemplace') + ' aquí datos a cargar (ctrl + v)';
	}

	paste(event: ClipboardEvent) {
		if (event.clipboardData) {
			var clipboardData = event.clipboardData;
			this.data = clipboardData.getData('text');
			if (this.data != '' && this.data) {
				let rows = this.data.split("\n");
				if (rows) {
					if (rows.length > 0) {
						rows = rows.filter(row => row != '').map(row => row.split("\t"));
						this.table = this.formatData(rows);
						var bool = this.table.length > 0 && ((this._config && Object.values(this.table[0]).length) || this.table[0].length);
						if (bool) {
							this.listaChange.emit(this.table);
							this.onPaste.emit(this.table);
						} else {
							this.onError.emit({ _config: this._config, rows: rows, table: this.table, bool: bool, msg: 'Tabla parseada vacía' });
						}
					} else {
						this.onError.emit({ _config: this._config, rows: rows, table: this.table, msg: 'Rows vaciós' });
					}
				} else {
					this.onError.emit({ _config: this._config, rows: rows, table: this.table, msg: 'Rows no encontrados' });
				}
			} else {
				this.onError.emit({ _config: this._config, table: this.table, msg: 'Error data' });
			}
			setTimeout(() => {
				this.ohc_text_data.nativeElement.value = '';
				this.data = '';
				this.ohc_text_data.nativeElement.blur();
			});
		}
	}

	trimIfString(dato) {
		return Object.prototype.toString.call(dato) == '[object String]' ? dato.trim() : dato;
	}

	formatData(rows) {
		try {
			let table = [];
			// Si tiene config validar el encabezado
			if (this._config) {
				let found_head = false;
				let head = rows[0];
				let head_not_empty = head.filter(h => h != '');
				for (var e = 0; e < head_not_empty.length; e++) {
					let cell = this.trimIfString(head_not_empty[e].toLowerCase());
					if (this.option == 0) {
						if (this._config[cell]) {
							if (!found_head) found_head = true;
							this._config[cell].indice_real = e;
						}
					}
					if (this.option == 1) {
						Object.keys(this._config).forEach(el_ => {
							let cnf_lbl = this.trimIfString(this._config[el_].label.toLowerCase())
							let cnf_id = this.trimIfString(this._config[el_].id.toLowerCase())
							if (this.removeAccents(cnf_lbl) == this.removeAccents(cell) || this.removeAccents(cnf_id) == this.removeAccents(cell)) {
								if (!found_head) found_head = true;
								this._config[el_].indice_real = e;
							}
						});
					}
				}
				if (found_head) {
					rows.shift()
					let empty_head_idx: number[] = head.map((h, i) => h == '' ? i : undefined).filter(x => x > -1);
					if (empty_head_idx.length > 0) {
						rows.forEach(row => {
							for (let index = 0; index < empty_head_idx.length; index++) {
								const idx = empty_head_idx[index];
								row.splice(idx, 1)
							}
						});
					}
					rows.forEach(itemrow => {
						if (itemrow.length > 0) {
							let newrow = {};
							for (var u in this._config) {
								let cnf = this._config[u];
								let head_ = this.option == 0 ? cnf.campo : cnf.id;
								let itm = itemrow[cnf.indice_real] ? this.trimIfString(itemrow[cnf.indice_real]) : this.trimIfString(itemrow[cnf.indice]);
								switch (true) {
									case (cnf.tipo == "number" && itm.length > 0):
										newrow[head_] = Number(itm.replace(/,/g, '.').replace(/[.](?=.*[.])/g, ""));
										break;
									case (cnf.tipo == "date" && itm.length > 0):
										/* Verficar la existencia de un formato */
										newrow[head_] = cnf.format ? moment(itm, cnf.format).toDate() : new Date(itm);
										break;
									default:
										newrow[head_] = itm;
										break;
								}
							}
							table.push(newrow);
						}
					});
				} else {
					rows.forEach(itemrow => {
						if (itemrow.length > 0) {
							let newrow = {};
							for (var u in this._config) {
								let cnf = this._config[u];
								let head_ = this.option == 0 ? cnf.campo : cnf.id;
								if (typeof (cnf.indice) == "number" && itemrow[cnf.indice] != undefined) {
									let itm = this.trimIfString(itemrow[cnf.indice]);
									switch (true) {
										case (cnf.tipo == "number" && itm.length > 0):
											newrow[head_] = Number(itm.replace(/,/g, '.').replace(/[.](?=.*[.])/g, ""));
											break;
										case (cnf.tipo == "date" && itm.length > 0):
											/* Verficar la existencia de un formato */
											newrow[head_] = cnf.format ? moment(itm, cnf.format).toDate() : new Date(itm);
											break;
										default:
											newrow[head_] = itm;
											break;
									}
								} else {
									newrow[head_] = '';
								}
							}
							table.push(newrow);
						}
					});
				}
			} else {
				table = rows.map(row => row.map(cell => this.trimIfString(cell)));
			}
			return table;
		} catch (e) {
			this.ohCore.getOH().getLoader().close();
			this.ohCore.getOH().getAd().warning('Error' + String(e));
			console.log('e:', e)
			return [];
		}

	}

	// verificar(event) {
	// 	// console.log('verificar event:', event)
	// 	setTimeout(() => {
	// 		this.ohc_text_data.nativeElement.value = '';
	// 		this.data = '';
	// 	});
	// }
}