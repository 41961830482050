import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'oh-qr-scanner',
  templateUrl: './oh.qrScanner.html'
})
export class QrScanner implements OnInit {

  /* == INPUTS & OUTPUTS == */
  @Input() enable: boolean = true;
  @Output() scanSuccess: EventEmitter<any> = new EventEmitter<any>();
  @Output() scanComplete: EventEmitter<any> = new EventEmitter<any>();


  constructor() { }

  ngOnInit(): void {
  }

  /**
   * Metodo que maneja el evento que se encuentre un codigo qr valido.
   * Devuelve via el Output el resultado del QR, es decir, la informacion almacenada
   * @param $event 
   */
  scanSuccessHandler($event) {
    this.scanSuccess.emit($event);
  }
}
