import { Component, Input, SimpleChanges, EventEmitter, Output, ViewChild } from '@angular/core';
import { NgbTypeahead, NgbTypeaheadConfig } from '@ng-bootstrap/ng-bootstrap';
import { NgModel, NgForm, NG_VALUE_ACCESSOR, ControlValueAccessor, NG_VALIDATORS, Validator, AbstractControl, ValidationErrors } from '@angular/forms';
import { debounceTime, distinctUntilChanged, map, filter, tap, switchMap, catchError, finalize } from 'rxjs/operators';
import { Observable, Subject, merge, of } from 'rxjs';

/**
 * Implementation Examples DATA LOCAL
 * 
 * INPUT: Array object
 * OUTPUT: property
 * <predictive-text [form]="frmFilterMonitoreo" [name]="'inp_filtro_empresa_transporte'" [data]="empresa_transporte" [searchBy]="['documento','razon_social']" [return]="'empresa_id'" [(value)]="ohfiltro.field.empa_razon_social.value"></predictive-text>
 * 
 * INPUT: Array object
 * OUTPUT: object
 * <predictive-text [form]="frmItinerario" [name]="'inp_nave'" [data]="naves" [(value)]="itinerario.nave" [searchBy]="['nave']" [required]="regla" [placeholder]="'Buscar nave...'" [upperCase]="true" (onChange)="oplnaveConfiguracionListar()"></predictive-text>
 * 
 * INPUT: Array text
 * OUTPUT: object
 * C:\jhonny\web\003_inlandnet\src\app\module\sharedDepot\component\document\documentRegister\shd.documentRegister.html
 * <predictive-text [form]="frmSolicitud" [name]="'inp_documento_puerto_descarga'" [data]="puertos_descarga" [(value)]="documento.puerto_descarga" [required]="regla == 'normal'" [placeholder]="'Buscar PDO...'" [openOnClick]="true" [upperCase]="true"></predictive-text>
 * 
 * 
 * Implementation Examples DATA REQUEST
 * 
 * INPUT: Array object
 * OUTPUT: property
 * <predictive-text [(value)]="buscarValor" placeholder="Buscar Req" [searchBy]="['titulo','usua_nombres','usua_apellidos']" [return]="'titulo'" (onChange)="buscarEvento($event)" (onClean)="buscarLimpiar()" (onSearch)="buscarServicio($event)" [template]="resultProducto"></predictive-text>
 * 
 * INPUT: Array object
 * OUTPUT: object
 * <predictive-text [(value)]="buscarValor" placeholder="Buscar Req" [searchBy]="['titulo','usua_nombres','usua_apellidos']" (onChange)="buscarEvento($event)" (onClean)="buscarLimpiar()" (onSearch)="buscarServicio($event)" ></predictive-text>
 * 
 * 
 * Enviar Template es Opcional
 * [template]="resultProducto"
 * Se usa para dar formato al listado mostrado ejemplo:
 * <ng-template #resultProducto let-r="result" let-t="term">
        <div style="position: relative;">
            <ngb-highlight [result]="r.titulo" [term]="t"></ngb-highlight> ({{r.detalle}})
        </div>
    </ng-template>
 * 

    Consideraciones

    1.- Siempre instanciar la variable antes de bindear en el campo data
 */


@Component({
    selector: 'predictive-text',
    styleUrls: ['./predictiveTextOld.css'],
    templateUrl: './predictiveTextOld.html',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            multi: true,
            useExisting: predictiveTextOld
        },
        {
            provide: NG_VALIDATORS,
            multi: true,
            useExisting: predictiveTextOld
        }
    ]
    // providers: [NgbTypeaheadConfig]
})

export class predictiveTextOld implements ControlValueAccessor, Validator {

    search: any;
    formatter: any;
    selected: any;
    item: any;
    item_check: any;
    type_array: any = null;
    out_change: boolean = true;

    @Input() name: any;
    @Input() data: any[] = [];// data for list search, support array to: object, string, number
    @Input() form: NgForm;// form to append control when if required
    @Input() readonly: boolean = false;// determines if control is readonly
    @Input() disabled: boolean = false;// determines if control is disabled
    @Input() required: boolean = false;// determines if control is required
    // @Input() placement: string;
    @Input() placeholder: string = 'Search...';// placeholder for Input
    @Input() return: string; // property return, if null return obj
    @Input() searchBy: any[] = []; // array properties for template search
    @Input() formatterTmp: any[] = []; // array properties for template list
    @Input() selectedTmp: any[] = []; // array properties  for template result
    @Input() noWrap: boolean = true;// list and result select in Upper Case
    @Input() upperCase: boolean = false;// list and result select in Upper Case
    @Input() openOnClick: boolean = false;// list result on click or focus input    
    @Input() numViewOpen: number = 17;// number of results show when click or focus input
    @Input() numViewResult: number = 17;// number of results show when search anything
    @Input() value: any = '';// value to return
    @Output() valueChange: EventEmitter<any>;
    @Output() onSet: EventEmitter<any> = new EventEmitter();
    @Output() onChange: EventEmitter<any> = new EventEmitter();

    @Output() onClean: EventEmitter<any> = new EventEmitter();
    // @Output() listPagin: EventEmitter<any> = new EventEmitter();

    @Input() template: any;// value to return

    @ViewChild('inp_hd', { static: false }) inp_hd: NgModel;
    @ViewChild('instnc', { static: false }) instnc: NgbTypeahead;

    focus$ = new Subject<string>();
    click$ = new Subject<string>();
    // inp: any;
    _service: any
    @Output() onSearch: EventEmitter<any> = new EventEmitter(); // new
    searching: boolean = false;

    constructor(config: NgbTypeaheadConfig) {
        this.valueChange = new EventEmitter<any>();
    }

    ngOnInit() { }

    ngAfterViewInit() {
        setTimeout(() => {
            if (this.required && this.form && this.inp_hd) {
                this.inp_hd.name = this.name;
                this.form.addControl(this.inp_hd);
            }
            this.disabled = (this.disabled !== false && this.disabled !== undefined && this.disabled !== null);
            this.numViewOpen = (this.data ? (this.numViewOpen === -1 && this.data.length !== 0 ? this.data.length : this.numViewOpen) : 0);
            this.placeholder = (this.placeholder == 'Search...' ? (this.openOnClick ? this.placeholder : 'Type to search...') : this.placeholder)
            if (this.onSearch && this.onSearch.observers.length > 0) {
                this.onSearch.emit((_resp) => this._service = _resp);
            } else {
                this.setTypeArray();
            }
            this.createElementsTypeahead();
            this.checkValue();
        });

        var root: any = document.querySelector(':root');
        root.style.setProperty('--ngtypeaheadmaxwidth', '100%');
    }

    ngOnChanges(changes: SimpleChanges) {
        this.setTypeArray();

        if (changes.required) {
            if (changes.required.previousValue == false && changes.required.currentValue == true && this.form && this.inp_hd) {
                this.inp_hd.name = this.name;
                this.form.addControl(this.inp_hd);
            }
            if (changes.required.previousValue == true && changes.required.currentValue == false && this.form && this.inp_hd) {
                this.inp_hd.name = this.name;
                this.form.removeControl(this.inp_hd);
            }
        }
        if (changes.data || changes.value) {
            setTimeout(() => {
                this.checkValue();
            });
        }
        if (changes.disabled) {
            this.disabled = (changes.disabled.currentValue !== false && changes.disabled.currentValue !== undefined && changes.disabled.currentValue !== null);
        }
        this.numViewOpen = (this.data ? (this.numViewOpen === -1 && this.data.length !== 0 ? this.data.length : this.numViewOpen) : 0);
        // this.createElementsTypeahead();
    }

    getClassInpt() {
        let _class = '';
        if (this.disabled || this.readonly) _class += 'inpt-disabled ';
        if (this.required) _class += 'required-off ';
        return _class;
    }

    getClassContent() {
        let _class = '';
        if (this.readonly) _class += 'content-readonly ';
        if (this.noWrap) _class += 'nowrap ';
        return _class;
    }

    checkValue() {
        this.markAsTouched();
        if (this.onSearch && this.onSearch.observers.length > 0) {

        } else {
            if (this.value != null && this.value != undefined && this.data != undefined && this.data != []) {
                if (!this.return) {
                    if (this.type_array == '[object Object]') {
                        this.data.forEach(element => {
                            let _data = '', _find = '';
                            for (let index = 0; index < this.searchBy.length; index++) {
                                const head = this.searchBy[index];
                                _data = element[head];
                                _find = this.value[head];
                            }

                            if (_data === _find) {
                                this.item = element;
                            }
                        });
                    } else {
                        this.item = this.value;
                    }
                } else {
                    this.item = this.data.find(el => el[this.return] == this.value);
                }
                if (this.out_change) {
                    this.changeItem(this.item, this.onSet, true);
                }
                this.out_change = true;
            } else {
                this.item_check = '';
                this.item = null;
                this.valueChange.emit(this.value);
                this.onChangeCVA(this.value);
            }
        }
    }

    findText(term) {
        if (!this.data) return [];
        return term === '' ? this.data : this.data.filter(it => (this.getSearchTemplate(it)).toLowerCase().indexOf(term.toLowerCase()) > -1)
    }

    createElementsTypeahead() {
        if (this.onSearch && this.onSearch.observers.length > 0) {
            this.search = (text$: Observable<string>) => text$.pipe(
                debounceTime(300),
                distinctUntilChanged(),
                switchMap(term =>
                    this._service(term).pipe(
                        finalize(() => {
                            this.searching = false
                        }),
                        catchError(() => {
                            return of([]);
                        }))
                ),
                tap(() => {
                    this.searching = true
                })
            )
        } else {
            this.selected = (it) => (this.getSelectedTemplate(it));
            this.formatter = (it) => (this.getFormatterTemplate(it));
            if (this.openOnClick) {
                this.search = (text: Observable<string>) => {
                    const debouncedText = text.pipe(debounceTime(200), distinctUntilChanged());
                    const clicksWithClosedPopup = this.click$.pipe(filter(() => !this.instnc.isPopupOpen()));
                    const inputFocus = this.focus$;
                    return merge(debouncedText, inputFocus, clicksWithClosedPopup).pipe(
                        map(term => {
                            return this.findText(term).slice(0, this.numViewOpen)
                        })
                    );
                }
            } else {
                this.search = (text: Observable<string>) => text.pipe(
                    debounceTime(200),
                    distinctUntilChanged(),
                    map(term => {
                        return this.findText(term).slice(0, this.numViewResult)
                    })
                )
            }
        }
    }

    getSearchTemplate(obj) {
        let template: string = '';
        if (obj) {
            if (this.searchBy.length != 0) {
                this.searchBy.forEach(element => {
                    template += obj[element] + ' '
                });
            }
            if (this.onSearch && this.onSearch.observers.length > 0) {

            } else if (this.type_array !== '[object Object]') {
                template = obj;
            }
        }
        if (Object.prototype.toString.call(template) != '[object String]') {
            template = '';
            this.searchBy.forEach(element => {
                template += template[element] + ' '
            });
        }
        return template;
    }

    getFormatterTemplate(obj) {
        let template: string = '';
        if (obj) {
            this.formatterTmp.forEach(element => {
                template += obj[element] + ' '
            });
        }
        template = (this.formatterTmp.length == 0 ? this.getSearchTemplate(obj) : template);
        return (this.upperCase ? template.toUpperCase() : template);
    }

    getSelectedTemplate(obj) {
        let template: string = '';
        if (obj) {
            this.selectedTmp.forEach(element => {
                template += obj[element] + ' '
            });
        }
        template = (this.selectedTmp.length == 0 ? this.getSearchTemplate(obj) : template);
        return (this.upperCase ? template.toUpperCase() : template);
    }

    setTypeArray() {
        let it_0 = this.data ? this.data[0] : null;
        if (it_0) {
            this.type_array = Object.prototype.toString.call(it_0);
        }
    }

    validItem(it) {
        if (it != undefined) {
            if (this.onSearch && this.onSearch.observers.length > 0) {
                return Object.prototype.toString.call(it) === '[object Object]';
            } else {
                let find_ = true;
                if (this.type_array !== '[object Object]' && this.data != undefined) {
                    find_ = this.data.find(el => el == it);
                }
                if (this.data == undefined) {
                    find_ = false;
                }
                return Object.prototype.toString.call(it) === this.type_array && find_;
            }
        } else {
            return false;
        }
    }

    clean(inp) {
        this.markAsTouched();
        if (!this.readonly && !this.disabled) {
            this.item_check = '';
            this.item = null;
            this.value = null;
            this.valueChange.emit(this.value);
            this.onChangeCVA(this.value);
            this.onClean.emit()
            if (inp) {
                setTimeout(() => {
                    inp._elementRef.nativeElement.value = '';
                    inp._elementRef.nativeElement.dispatchEvent(new Event('input'));
                    inp._elementRef.nativeElement.focus();
                }, 250)
            }
        }
    }

    changeItem(val, ev?, bool?) {
        this.markAsTouched();
        this.item_check = '';
        if (val !== null && val !== undefined) {
            let val_final: any
            if (Object.prototype.toString.call(val) === this.type_array && this.validItem(val)) {
                val_final = this.item
            }
            if (this.onSearch && this.onSearch.observers.length > 0 && Object.prototype.toString.call(val) === '[object Object]') {
                val_final = val
            }
            if (val_final) {
                this.item_check = 'valid';
                if (!this.return) {
                    this.value = val_final
                } else {
                    this.value = val_final[this.return];
                }
                this.valueChange.emit(this.value);
                this.valueChange.emit(this.value);
                this.onChangeCVA(this.value);
                if (ev) {
                    ev.emit(val);
                }
                this.out_change = bool;
            }
        }
    }

    ngOnDestroy() {
        if (this.required && this.form) {
            this.form.removeControl(this.inp_hd);
        }
    }

    //#region ControlValueAccesor
    onChangeCVA = (value) => { };
    onTouched = () => { };
    touched = false;
    registerOnChange(onChange: any) {
        this.onChangeCVA = onChange;
    }
    registerOnTouched(onTouched: any) {
        this.onTouched = onTouched;
    }
    setDisabledState(disabled: boolean) {
        this.disabled = disabled;
    }
    markAsTouched() {
        if (!this.touched) {
            this.onTouched();
            this.touched = true;
        }
    }
    writeValue(value: any) {
        this.value = value;
        this.checkValue();
    }
    //#endregion ControlValueAccesor

    //#region Validator
    validate(control: AbstractControl): ValidationErrors | null {
        if (control.errors?.required) {
            this.required = true;
        }

        return null;
    }
    //#endregion Validator
}