<div style="display: inline-block;" class="w-100">
    <div class="input-group" style="max-width: 474px;">
        <!-- readonly=readonly -->
        <!-- ="ngModel" -->
        <!-- [(ngModel)]="text_" -->
        <input #inputFecha class="form-control" [name]="'inp_hd' + name" [placeholder]="oneDay ? 'dd/mm/yyyy' : 'dd/mm/yyyy - dd/mm/yyyy'" placeholder="dd/mm/yyyy - dd/mm/yyyy" name="fecha" required="true" (input)="checkInput($event)" [value]="oneDay ? (initValue | date: 'd/M/y') : ((initValue ? (initValue | date: 'd/M/y') : '') + (initValue && endValue ? ' - ' + (endValue | date: 'd/M/y') : ''))"
            autocomplete="off" [ngClass]="getClassInpt()" [disabled]="disabled">
        <div class="input-group-append">
            <button type="button" class="btn btn-outline-secondary" (click)="toogleDate()" [attr.aria-expanded]="!collapsed" aria-controls="collapse" [disabled]="disabled">
                <i class="far fa-calendar-alt" aria-hidden="true"></i>
            </button>
            <button *ngIf="changeMode" class="btn btn-outline-secondary" type="button" id="button-addon2" (click)="changingMode()" [disabled]="disabled">Fecha{{oneDay ? ' Única' : ' Rango'}}</button>
            <!-- <i class="mx-1 far" [ngClass]="oneDay ? 'fa-square' : 'far fa-check-square'" aria-hidden="true"></i> -->
        </div>
    </div>
    <!-- [style.top.px]="top" [style.left.px]="left" -->
    <!-- (mouseleave)="focusOut()" -->
    <!-- (mouseout)="focusOut()" -->
    <div id="collapseFecha_modal" [ngbCollapse]="collapsed" style="position: fixed; background: #FFF;z-index: 99999;" [ngStyle]="{'top': top, 'left': left}">
        <oh-dateranges [(from)]="initValue" [(to)]="endValue" (onChange)="changeRange()"></oh-dateranges>
    </div>
</div>