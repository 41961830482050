import { Component, Input, Output, EventEmitter } from '@angular/core';
import { AbortController } from "@azure/abort-controller";

import * as FileSaver from 'file-saver';
import { finalize } from 'rxjs/operators';
import { AngularFireStorage } from '@angular/fire/storage';
import { MainService } from '../../services/oh.mainService';
import { OHService } from 'src/app/tis.ohService';
import { of } from 'rxjs';
import { CoreService } from 'src/app/ind.coreService';
import { INDAzureStorage } from 'src/app/azure/storage';
import { environment } from 'src/environments/environment';
import { TranslocoService } from '@ngneat/transloco';

/*
    <oh-fileUpload [adjuntos]="adjuntos" (eventoEliminar)="adjuntoEliminar($event)"></oh-fileUpload>
    ruta = '';
    adjuntos = [
      {
          uid : ,
          nombre : ,
          tamano : ,
          archivo: ,
          descripcion : ,
          fecha_registro : ,  //se muestra solo si tiene valores de fecha registro en la tabla "adjunto"
          usuario_registro :  //se muestra solo si tiene valores de usuario registro en la tabla "adjunto"
      }
    ]

    eventoEliminar Cuando vamos a eliminar de una edicion de archivos
    Ejemplo
    adjuntoEliminar(evento : any){
          this.oPLPropuestaService.oplpropuestaEliminarAdjunto({
              propuesta_id : this.propuesta.propuesta_id,
              usuario_id : this.cse.data.user.data.userid,
              adjunto_id : evento.adjunto_id
          }, (resp : pOplpropuestaEliminarAdjunto) => {
          });
    }
  
    Eventos adicionale
    <oh-fileUpload [adjuntos]="adjuntos" (eventoEliminar)="adjuntoEliminar($event)" vista="editor" formatos="image/png, image/jpeg" [multiple]="false" [pesoMaximo]="5120" [cantidadMaxima]="5"></oh-fileUpload>

    <oh-fileUpload [adjuntos]="adjuntos" [ruta]="ocs.config.ruta_firestore" [config]="{'formatos':'image/png, image/jpeg','pesoMaximo':2048, 'cantidadMaxima':3}"></oh-fileUpload>

    <oh-fileUpload [adjuntos]="adjuntos" [ruta]="ocs.config.ruta_firestore" [config]="ocs.config.file"></oh-fileUpload>
    vista Editor
    Formatos png y jpg
    No soporta la carga múltiple
    peso máximo 5 MBs por archivo
*/

export interface fileUploadConfig {
  vista?: string
  formatos?: string
  multiple?: boolean
  pesoMaximo?: number
  cantidadMaxima?: number
}

export interface FiletoUpload {

  adjunto_id?: number
  uid: string
  nombre: string
  tamano: number
  archivo?: any
  porcentaje?: any // Subject<number> | Observable<number>
  url?: string
  fecha_registro?: Date
  usuario_registro_nombres?: string
  usuario_registro_apellidos?: string
  usuario_registro_id?: number
  storage?: any
  cambios?: any
  loader?: any
  abortController?: AbortController

}

@Component({
  selector: 'oh-fileUpload',
  styles: ['button.text-success:hover {color: #19692c!important;}'],
  templateUrl: './oh.fileUpload.html',
  styleUrls: ['./oh.fileUpload.css']
})
export class FileUpload {

  private ohMainService: MainService;
  private tipo_adjunto_fire_id: number = 30892;
  private tipo_adjunto_azure_id: number = 48028;
  _adjuntos: any;

  private fechaRegistroHide: boolean = true;
  private usuarioRegistroHide: boolean = true;

  @Input() draggable: boolean = false
  @Input() disabled: any = false;
  @Input() vista: string = "editor";  // Defecto Editor | Vita de pantalla como Edición o Solo lectura | hide
  @Input() formatos: string = "";     // Defecto Todos  | Agrega los formatos aceptados
  @Input() multiple: boolean = true;  // Defecto Si     | Configuración del input a ser múltiple o no.
  @Input() pesoMaximo: number = 2048; // Defecto 2 MB   | Configuración por archivo validar el peso máximo.
  @Input() cantidadMaxima: number = 5;// Defecto 5      | Cantidad máxima a subir
  @Input() tipos: any;
  @Input() tipo_id: string;
  @Input() tipo_value: string;
  @Input() tipos_defecto: any = [];
  // @Input() readOnly: any = false; -> use: [vista]=""

  @Input() uno: boolean;

  @Input() descriptionHide: boolean;

  @Output()
  eventoCargar: any;

  @Input('config')
  set config(config: fileUploadConfig) {
    if (config) {
      if (config.vista) {
        this.vista = config.vista;
      }
      if (config.formatos) {
        this.formatos = config.formatos;
      }
      if (config.multiple) {
        this.multiple = config.multiple;
      }
      if (config.pesoMaximo) {
        this.pesoMaximo = config.pesoMaximo;
      }
      if (config.cantidadMaxima) {
        this.cantidadMaxima = config.cantidadMaxima;
      }
    }
  }

  @Input('adjuntos')
  set adjuntos(adjuntos: any) {

    if (adjuntos) {
      this.archivos = [];

      this._adjuntos = adjuntos; //this._adjuntos.adjunto_id  // Si tiene adjunto_id es edición sino es nuevo

      for (var i = 0; i < adjuntos.length; i++) {

        if (adjuntos[i].fecha_registro != null) this.fechaRegistroHide = false;
        if (adjuntos[i].usuario_registro_id != null) this.usuarioRegistroHide = false;

        this.archivos.push({
          adjunto_id: adjuntos[i].adjunto_id,
          uid: adjuntos[i].id,
          nombre: adjuntos[i].nombre,
          tamano: Math.round((adjuntos[i].peso / 1024) * 100) / 100,
          porcentaje: of(100),
          url: adjuntos[i].url,
          fecha_registro: adjuntos[i].fecha_registro,
          usuario_registro_nombres: adjuntos[i].usuario_registro_nombres,
          usuario_registro_apellidos: adjuntos[i].usuario_registro_apellidos,
          usuario_registro_id: adjuntos[i].usuario_registro_id
        })

      }

    }
  }

  @Output() eventoEliminar: EventEmitter<any>; // hace binding al padre.
  /* Con el formato
      [
      {
        "id": "0ec7f269-9322-4d02-8cbf-1747346c12c7",
        "nombre": "arhivo1.jpg",
        "formato": "jpg",
        "peso": 232322,
        "ubicacion": "test",
        "tipo_adjunto": 30892, -- Firebase
        "descripcion": "", -- Opcional adicional
        "tipo": 1 -- Opcional adicional
      },
      {
        "id": "9yc7f269-9322-4d02-8cbf-1747346c1223",
        "nombre": "arhivo2.jpg",
        "formato": "png",
        "peso": 65555,
        "ubicacion": "testb",
        "tipo_adjunto": 30892,
        "descripcion": "",
        "tipo": 1
      }
    */
  @Input() archivos: FiletoUpload[]; // Con Metadata incluida
  @Input() ruta: any;

  @Output() eventos: EventEmitter<any>;
  @Output() eliminacionCompleta: EventEmitter<boolean> = new EventEmitter<boolean>();

  @Input() restrict_mode: boolean;

  langSubs: any
  lang: any = {}

  constructor(private ohCore: OHService, private fireStorage: AngularFireStorage, public cse: CoreService, private indAzureStorage: INDAzureStorage, private translocoService: TranslocoService) {
    this.ohMainService = ohCore.getOH();
    this._adjuntos = [];
    this.archivos = [];
    this.tipos = [];
    this.eventoEliminar = new EventEmitter<any>();
    this.eventos = new EventEmitter<any>();
    this.eventoCargar = new EventEmitter<any>();


    this.langSubs = this.translocoService.selectTranslateObject('component.fileUpload').subscribe((valor) => {
      this.lang = valor
    });

  }

  ngOnDestroy() {
    if (this.restrict_mode && this._adjuntos) {
      for (var i = 0; i < this._adjuntos.length; i++) {
        if (this._adjuntos[i].accion == 'N' && !this._adjuntos[i].adjunto_id) {
          this.eliminar(i);
          i--;
        }
      }
    }
  }

  cargar($event: any, call?: any) {

    if (this.indAzureStorage.isEnabled()) {
      this.cargarAzure($event, call)
    } else {
      this.cargarFire($event, call)
    }
  }

  private cargarFire($event: any, call?: any) {

    //this.ohCore.getOH().getLoader().show();

    //let files = $event.target.files;
    let files = $event.target ? $event.target.files : $event;

    this.eventoCargarAntes((solicitud: any) => {
      if (solicitud) {
        solicitud(files);
      }
    })

    let tipo_defecto = null
    if (this.tipos && this.tipos.length == 1) {
      tipo_defecto = this.tipos[0][this.tipo_id]
    }

    if (files && files.length > 0) {

      let _cargando = [];

      for (var i = 0; i < files.length; i++) {

        if (files[i].customFile) {
          files[i].size = files[i].customFile.size
        }

        if (files[i].size <= this.pesoMaximo * 1024) { // Validando el peso máximo

          if (this.archivos.length < this.cantidadMaxima) { // Validando la cantidad máxima

            var file = files[i]
            let subFormato = files[i].name.split(".");

            var uid = this.ohMainService.getUtil().getUID();

            this.archivos.push({
              uid: uid,
              nombre: file.name,
              tamano: Math.round((file.size / 1024) * 100) / 100,
              archivo: file.customFile ? file.customFile : file
            });

            if (this.tipos_defecto && this.tipos_defecto[0]) {
              tipo_defecto = this.tipos_defecto[0]
            }

            var nuevo_adjunto = {
              id: uid,
              nombre: file.name,
              formato: subFormato[subFormato.length - 1],
              peso: file.size,
              ubicacion: this.ruta,
              tipo_adjunto: this.tipo_adjunto_fire_id,
              descripcion: '',
              tipo: tipo_defecto,
              accion: 'N'
            }

            this._adjuntos.push(nuevo_adjunto);

            var cargado = this.archivos[this.archivos.length - 1];
            const storageRef = this.fireStorage.ref(this.ruta + cargado.uid);
            cargado.storage = this.fireStorage.upload(this.ruta + cargado.uid, cargado.archivo);

            let dass_res;
            let dass = new Promise((resolve, reject) => {
              dass_res = resolve;
            });
            _cargando.push(dass)
            cargado.porcentaje = cargado.storage.percentageChanges();
            cargado.cambios = cargado.storage.snapshotChanges();
            cargado.cambios.pipe(
              finalize(() => {
                storageRef.getDownloadURL().subscribe((url) => {
                  //this.ohCore.getOH().getLoader().close();
                  dass_res(url);
                });
              })
            ).subscribe();
          }
        } else {
          //this.ohCore.getOH().getLoader().close();
          this.ohCore.getOH().getAd().warning(this.lang.maxsizeA + " (" + this.mapSize(file.size) + ") " + this.lang.maxsizeB + " " + this.mapSize(this.pesoMaximo));
        }
      }

      //this.eventoCargar.emit(this.archivos);

      Promise.all(_cargando).then(values => {

        for (var e in this._adjuntos) {

          for (var i in values) {
            let ind = values[i].indexOf(this._adjuntos[e].id);
            if (ind >= 0) {
              this._adjuntos[e].url = values[i];
              break;
            }
          }
        }

        for (var i in this.archivos) {
          this.archivos[i].porcentaje = of(100)
        }

        $event.target.value = '';
        if (call) {
          call(this._adjuntos)
        }

        this.eventoCargarFinalizado((solicitud: any) => {
          //this.ohCore.getOH().getLoader().close();

          if (solicitud) {
            solicitud(true);
          }
        })
      });

    }

  }

  private cargarAzure($event: any, call?: any) {

    //this.ohCore.getOH().getLoader().show();

    let _ruta = this.ohCore.getOH().getUtil().mapRealRoute(environment.firebase_coleccion_base, this.ruta) // container | folder

    let files = $event.target ? $event.target.files : $event;

    this.eventoCargarAntes((solicitud: any) => {
      if (solicitud) {
        solicitud(files);
      }
    })

    let tipo_defecto = null
    if (this.tipos && this.tipos.length == 1) {
      tipo_defecto = this.tipos[0][this.tipo_id]
    }

    if (files && files.length > 0) {

      let _cargando = [];

      for (var i = 0; i < files.length; i++) {

        if (files[i].customFile) {
          files[i].size = files[i].customFile.size
        }

        if (files[i].size <= this.pesoMaximo * 1024) { // Validando el peso máximo

          var file = files[i]

          if (this.archivos.length < this.cantidadMaxima) { // Validando la cantidad máxima
            let subFormato = file.name.split(".");

            var uid = this.ohMainService.getUtil().getUID();

            var new_Arc = {
              uid: uid,
              nombre: file.name,
              tamano: Math.round((file.size / 1024) * 100) / 100,
              archivo: file.customFile ? file.customFile : file,
              porcentaje: of(0),
              abortController: new AbortController()
            }

            this.archivos.push(new_Arc);

            if (this.tipos_defecto && this.tipos_defecto[0]) {
              tipo_defecto = this.tipos_defecto[0]
            }

            var nuevo_adjunto = {
              id: uid,
              nombre: file.name,
              formato: subFormato[subFormato.length - 1],
              peso: file.size,
              ubicacion: _ruta.folder,
              tipo_adjunto: this.tipo_adjunto_azure_id,
              descripcion: '',
              tipo: tipo_defecto,
              accion: 'N'
            }

            this._adjuntos.push(nuevo_adjunto);

            _cargando.push({
              name: _ruta.folder + uid,
              blob: file.customFile ? file.customFile : file,
              index: uid,
              onUpload: (percent, uid) => {

                var pb: any = document.getElementById("pb_" + uid)
                if (pb) {
                  pb.innerHTML = "" + Math.round(percent) + "%"
                  pb.style = "width: " + Math.round(percent) + "%"
                }

              },
              onFinished: (uid) => {
                let _arc = this.archivos.find(it => it.uid == uid)
                if (_arc) {
                  _arc.porcentaje = of(100)
                }
              },
              abortSignal: new_Arc.abortController.signal
            })

          }
        } else {
          this.ohCore.getOH().getAd().warning(this.lang.maxsizeA + " (" + this.mapSize(file.size) + ") " + this.lang.maxsizeB + " " + this.mapSize(this.pesoMaximo));
        }
      }

      this.indAzureStorage.uploadItems(_ruta.container, _cargando).then(values => {

        for (var e in this._adjuntos) {

          for (var i in values) {
            let ind = values[i].url.indexOf(this._adjuntos[e].id);
            if (ind >= 0) {
              this._adjuntos[e].url = values[i].url;
              break;
            }
          }

          var pb: any = document.getElementById("pbl_" + this._adjuntos[e].id)
          if (pb) {
            pb.remove()
          }

        }

        if ($event.target) {
          $event.target.value = ''
        }

        if (call) {
          call(this._adjuntos)
        }

        this.eventoCargarFinalizado((solicitud: any) => {
          if (solicitud) {
            solicitud(true);
          }
        })

      })

    }
  }

  eventoCargarAntes(evento) {
    this.eventos.emit({
      response: {
        cargarAntes: evento,
        cargarFinalizado: (call: any) => { }
      }
    });
  }

  eventoCargarFinalizado(evento: any) {
    this.eventos.emit({
      response: {
        cargarAntes: (call: any) => { },
        cargarFinalizado: evento
      }
    });
    this.eventoCargar.emit(this.archivos);
  }

  descargar(indice: number) {
    if (this.archivos[indice].archivo) {
      FileSaver.saveAs(this.archivos[indice].archivo, this.archivos[indice].nombre);
    } else {
      this.ohCore.getOH().getLoader().show();
      if (this.archivos[indice].url) {
        this.archivoDescargar(indice, this.archivos[indice].url);
      } else {
        if (this.indAzureStorage.isEnabled()) {
          let _ruta = this.ohCore.getOH().getUtil().mapRealRoute(environment.firebase_coleccion_base, this.ruta)
          let _file = this.indAzureStorage.download(_ruta.container, _ruta.folder + this.archivos[indice].uid)

          _file.then(it => {
            FileSaver.saveAs(it, this.archivos[indice].nombre);
            this.ohCore.getOH().getLoader().close();
          })
        } else {
          this.fireStorage.ref(this.ruta + this.archivos[indice].uid).getDownloadURL().subscribe((url) => {
            this.archivoDescargar(indice, url);
          })
        }
      }
    }
  }

  private archivoDescargar(indice: number, url: string) {
    var xhr = new XMLHttpRequest();
    xhr.responseType = 'blob';
    xhr.onload = (event) => {
      if (xhr.status == 200) {
        this.archivos[indice].archivo = xhr.response;
        FileSaver.saveAs(this.archivos[indice].archivo, this.archivos[indice].nombre);
      } else {
        this.ohCore.getOH().getAd().warning(this.lang.errorDownload);
      }
      this.ohCore.getOH().getLoader().close();
    };
    xhr.open('GET', url);
    xhr.send();
  }

  eliminar(indice: number, oviarConfirmacion?: boolean) {

    if (this._adjuntos[indice].adjunto_id) {

      if (!oviarConfirmacion) {
        this.ohCore.getOH().getUtil().confirm(this.lang.deleteConfirm, () => {
          if (this.eventoEliminar.observers.length > 0) {
            this.eventoEliminar.emit({ adjunto_id: this._adjuntos[indice].adjunto_id });
          }
          this.eliminarConfirmar(indice);
        });
      } else {
        if (this.eventoEliminar.observers.length > 0) {
          this.eventoEliminar.emit({ adjunto_id: this._adjuntos[indice].adjunto_id });
        }
        this.eliminarConfirmar(indice);
      }

    } else {
      this.eliminarConfirmar(indice);
    }

  }

  eliminarConfirmar(indice: number) {

    if (this.indAzureStorage.isEnabled()) {
      let _ruta = this.ohCore.getOH().getUtil().mapRealRoute(environment.firebase_coleccion_base, this.ruta)
      this.indAzureStorage.deleteItems(_ruta.container, [_ruta.folder + this.archivos[indice].uid]).then()
    } else {
      this.fireStorage.ref(this.ruta + this.archivos[indice].uid).delete();
    }

    this.archivos.splice(indice, 1);
    this._adjuntos.splice(indice, 1);
    this.ohMainService.getAd().success(this.lang.deleteConfirmed);
    this.eliminacionCompleta.emit(true);
  }

  public deleteFile(routePath: string, name: string) {
    if (this.indAzureStorage.isEnabled()) {
      let _ruta = this.ohCore.getOH().getUtil().mapRealRoute(environment.firebase_coleccion_base, routePath)
      this.indAzureStorage.deleteItems(_ruta.container, [_ruta.folder + name]).then()
    } else {
      this.fireStorage.ref(this.ruta + name).delete();
    }
  }

  cancelar(indice: number, fileInput: any) {

    if (this.indAzureStorage.isEnabled()) {
      this.archivos[indice].abortController.abort()
    } else {
      this.archivos[indice].storage.cancel()
    }

    this.archivos.splice(indice, 1);
    this._adjuntos.splice(indice, 1);

    fileInput.value = ""

    this.ohMainService.getAd().success(this.lang.uploadCanceled);
  }

  mapSize(size: number) {
    if (size <= 1024) {
      return size + " KB"
    } else {
      let _size = Math.round((size / 1024) * 100) / 100
      return _size + " MB"
    }
  }

}