<input type="hidden" #inp_date="ngModel" [(ngModel)]="dateValue" [required]="required">
<div *ngIf="type == 'lineal'">
  <ngb-datepicker name="datepicker" #datepicker="ngbDatepicker"
    [ngClass]="{'required-ok' : required && dateValue, 'required-error' : required && !dateValue}"
    (dateSelect)="onDateSelection($event, $event)" [displayMonths]="2" [dayTemplate]="t" outsideDays="hidden"
    [startDate]="fromDate!" [minDate]="_minFinalDate" [maxDate]="_maxFinalDate" [disabled]="disabled"
    [markDisabled]="markDisabled"></ngb-datepicker>
  <ng-template #t let-date let-currentMonth="currentMonth" let-selected="selected" let-disabled="disabled"
    let-focused="focused">
    <span class="oh_daterange_customday" [ngClass]="{'disabled' : disabled}" [class.focused]="focused"
      [class.range]="isRange(date)" [class.faded]="isHovered(date) || isInside(date)"
      [class.currenttoday]="isToday(date)" (mouseenter)="hoveredDate = date" (mouseleave)="hoveredDate = null">
      {{ date.day }}
    </span>
  </ng-template>
  <br>
  <button class="btn btn-sm btn-danger mt-1" *ngIf="dateValue && !disabled" (click)="clean();" [disabled]="disabled"><i
      class="fas fa-times"></i></button>
</div>
<div class="form-inline" *ngIf="type == 'modal'">
  <div class="form-group oh_daterange_formgroup hidden">
    <div class="input-group">
      <input name="datepicker" class="form-control" ngbDatepicker #datepicker="ngbDatepicker" [autoClose]="'outside'"
        (dateSelect)="onDateSelection($event, datepicker)" [displayMonths]="2" [dayTemplate]="t" outsideDays="hidden"
        [markDisabled]="markDisabled" [startDate]="fromDate!" tabindex="-1" container="body" [minDate]="_minFinalDate"
        [maxDate]="_maxFinalDate">
      <ng-template #t let-date let-focused="focused">
        <span class="oh_daterange_customday" [ngClass]="{'disabled' : disabled}" [class.focused]="focused"
          [class.range]="isRange(date)" [class.faded]="isHovered(date) || isInside(date)"
          [class.currenttoday]="isToday(date)" (mouseenter)="hoveredDate = date" (mouseleave)="hoveredDate = null">
          {{ date.day }}
        </span>
      </ng-template>
    </div>
  </div>
  <div class="form-group">
    <div class="input-group">
      <input #dpFromDate class="form-control oh_daterange_input" [placeholder]="objDate.format" name="dpFromDate"
        [ngClass]="{'required-ok' : required && dateValue, 'required-error' : (required && !dateValue) || (required && !fromDate)}"
        [value]="formatter.format(fromDateImp)" (input)="fromDateImp = validateInputFrom(fromDate, dpFromDate.value)"
        [disabled]="disabled">
      <div class="input-group-append">
        <button class="btn btn-outline-secondary" (click)="datepicker.toggle()" type="button" [disabled]="disabled"><i
            class="far fa-calendar-alt" aria-hidden="true"></i></button>
      </div>
    </div>
  </div>
  <div class="form-group ml-2">
    <div class="input-group">
      <input #dpToDate class="form-control oh_daterange_input" [placeholder]="objDate.format" name="dpToDate"
        [ngClass]="{'required-ok' : required && dateValue, 'required-error' : (required && !dateValue) || (required && !toDate)}"
        [value]="formatter.format(toDateImp)" (input)="toDateImp = validateInputTo(toDate, dpToDate.value)"
        [disabled]="disabled">
      <div class="input-group-append">
        <button class="btn btn-outline-secondary" (click)="datepicker.toggle()" type="button" [disabled]="disabled"><i
            class="far fa-calendar-alt" aria-hidden="true"></i></button>
      </div>
    </div>
  </div>
  <div class="form-group ml-2">
    <button class="btn btn-danger" *ngIf="dateValue && !disabled" (click)="clean()" [disabled]="disabled"><i
        class="fas fa-times"></i></button>
  </div>
</div>