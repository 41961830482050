import { environment } from 'src/environments/environment';

declare var _aeu: any;

export class ohStorage {
  _uri_: string = '47e6ce46-f1a1-40ae-8811-ed1bd7ff2b87';
  _lsa_: string = 'loc';
  _lsb_: string = 'alSt';
  _lsc_: string = 'orage';

  public has(name: string) {
    if (this._getls().getItem(name)) {
      return true;
    } else {
      return false;
    }
  }

  private _get(value: string) {
    if (environment.env == 'production' || environment.env == 'dev') {
      let _aes = new _aeu(this._uri_);
      let value_decripted;
      try {
        value_decripted = _aes.decrypt(value);
      } catch (e) {
        value_decripted = null;
      }
      return value_decripted;
    } else if (value) {
      return value;
    } else {
      return null;
    }
  }

  private _set(value: string) {
    if (environment.env == 'production' || environment.env == 'dev') {
      let _aes = new _aeu(this._uri_);
      return _aes.encrypt(value);
    } else if (value) {
      return value;
    } else {
      return null;
    }
  }

  private _getls() {
    var _wn = window;
    var _ss = this._lsa_ + this._lsb_ + this._lsc_;
    return _wn[_ss];
  }

  // For parents object
  public get(name: string) {
    var data = this._getls().getItem(name);
    if (data) {
      var value = this._get(data);

      try {
        let _data = value ? JSON.parse(value) : null;
        return _data;
      } catch (e) {
        console.log(e);
        return null;
      }
    } else {
      return null;
    }
  }

  private getUnfused(name: string) {
    var data = this._getls().getItem(name);
    if (data) {
      try {
        let _data = data ? JSON.parse(data) : null;
        return _data;
      } catch (e) {
        console.log(e);
        return null;
      }
    } else {
      return null;
    }
  }

  public set(name: string, data: any) {
    var value = this._set(JSON.stringify(data));
    this._getls().setItem(name, value);
  }

  public remove(name: string) {
    this._getls().removeItem(name);
  }

  // For childrens
  public item(name: string, field: string) {
    var obj = this.get(name);
    if (obj) {
      return obj[field];
    } else {
      return null;
    }
  }

  public add(name: string, field: string, data: any) {
    var temp = this.get(name);
    if (!temp) {
      temp = {};
    }
    temp[field] = data;
    this.set(name, temp);
  }

  public itemUnfused(name: string, field: string) {
    var obj = this._getls().getItem(name);
    if (obj) {
      return JSON.parse(obj)[field];
    } else {
      return null;
    }
  }

  public addUnfused(name: string, field: string, data: any) {
    var _data = {}
    var _item = this._getls().getItem(name);
    if (_item) {
      _data = JSON.parse(_item)
    }
    _data[field] = data
    this._getls().setItem(name, JSON.stringify(_data))
  }

  public subtractUnfused(name: string, field: string) {
    var temp = this._getls().getItem(name);
    if (temp) {
      temp = JSON.parse(temp)
      delete temp[field];
      this._getls().setItem(name, JSON.stringify(temp))
    }
  }

  public subtract(name: string, field: string) {
    var temp = this.get(name);
    if (temp) {
      delete temp[field];
      this.set(name, temp);
    }
  }

  public exists(name: string, field: string) {
    var temp = this.get(name);
    return temp && temp[field] ? true : false;
  }

  public clear() {
    this._getls().clear();
  }
}
