<p
  *ngIf="type != 'HTML'"
  (click)="openModal(modalDetalle)"
  [ngStyle]="class"
  [title]="inputText"
>
  {{inputText}}
</p>
<a
  *ngIf="type == 'HTML'"
  href="#"
  (click)="$event.preventDefault();openModal(modalDetalle)"
>
  Ver detalle
</a>
<ng-template #modalDetalle let-c="close" let-d="dismiss">
  <div class="modal-header">
    <span class="h5 m-0"
      ><i class="fas fa-align-justify mr-1"></i>Detalle completo:</span
    >
    <a
      href="#"
      class="close"
      aria-label="Close"
      (click)="$event.preventDefault(); d('Cross click')"
      ><i class="fas fa-times fa-xs"></i
    ></a>
  </div>
  <div class="modal-body">
    <div class="row">
      <div class="col">
        <div *ngIf="type == 'HTML'" [innerHTML]="inputText | ohSafeHtml"></div>

        <textarea
          *ngIf="type != 'HTML'"
          id="textAreaCopy"
          name="textAreaCopy"
          class="textarea-text"
          readonly
        >
{{inputText}}</textarea
        >
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button
      type="button"
      class="btn btn-outline-dark mr-1"
      (click)="c('Close')"
    >
      Cerrar
    </button>
    <button
      *ngIf="type != 'HTML'"
      type="button"
      class="btn btn-outline-success mr-1"
      (click)="copyContent()"
    >
      Copiar
    </button>
  </div>
</ng-template>
