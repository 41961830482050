import { Component, Input, EventEmitter, Output, ViewChild, SimpleChanges } from '@angular/core';
import { NgbModal, NgbModalRef, NgbDatepicker, NgbInputDatepicker } from '@ng-bootstrap/ng-bootstrap';
import { NgModel, NgForm } from '@angular/forms';
import { OHService } from 'src/app/tis.ohService';
import { DatetimeModal } from './oh.datetimeModal';

/**
* Implementacion
* <oh-datetime [form]="frmSolicitud" [name]="'inp_fecha_cita'" [(fecha_hora)]="solicitud.fecha_cita"></oh-datetime>
*/

@Component({
	selector: 'oh-datetime',
	templateUrl: './oh.datetime.html'
})

export class DateTime {

	@Input() title: string;
	@ViewChild("dp", { static: false }) dp: NgbDatepicker;

	@Input() formatDate :string = 'YYYY/MM/DD'
	@Output() fecha_horaChange: EventEmitter<Date>;
	@Input()
	set fecha_hora(valor: any) {
		if (valor) {
			let fecha = new Date(valor);
			if(this.formatDate == 'YYYY/MM/DD'){
				this.fecha_hora_descripcion = this.ohCore.getOH().getUtil().dateToStringTwo(fecha) + " " + this.ohCore.getOH().getUtil().pad(fecha.getHours()) + ":" + this.ohCore.getOH().getUtil().pad(fecha.getMinutes()) + ":00";

			}
			if(this.formatDate == 'DD/MM/YYYY'){
				this.fecha_hora_descripcion = this.ohCore.getOH().getUtil().dateToStringDayMonthYear(fecha) + " " + this.ohCore.getOH().getUtil().pad(fecha.getHours()) + ":" + this.ohCore.getOH().getUtil().pad(fecha.getMinutes()) + ":00";
			}
			//if(this.module == 1){
			//}
			if (fecha) {
				let fechareal = this.ohCore.getOH().getUtil().dateTimeToString(fecha, "SQL");
				this.fecha_hora_realChange.emit(fechareal);
			}
			this._fecha_hora = fecha;
			if (this._fecha_hora) {//  && this.module == 3
				this.fecha = this.ohCore.getOH().getUtil().dateToNgb(this._fecha_hora);
				if (!this.onlyDate) {
					this.hora = this.ohCore.getOH().getUtil().dateToNgbTime(this._fecha_hora);
				}
			}

		}
	}
	get fecha_hora(): any {
		return this._fecha_hora;
	}

	_fecha_hora: Date;

	@Output() public onComplete: EventEmitter<any> = new EventEmitter();

	@Input() fecha_hora_real: string;
	@Output() fecha_hora_realChange: EventEmitter<string>;

	@Input() name: string;
	@Input() required: boolean;
	@Input() form: NgForm;
	@Input() module: number = 1; // with input = 1 / sin input = 2 
	@Input() head: string = 'visible'; // with input = 1 / sin input = 2 
	@Input() withComent: boolean = false; // with input = 1 / sin input = 2 	
	@Input() min: any
	@Input() max: any
	@Input() disabled: boolean
	@Input() onlyDate: boolean = false
	@Input() pasteDate: boolean = false

	@Input() default: any;
	@Input() time: any;

	public fecha_hora_descripcion: string;
	public fecha: any;
	public hora: any;
	public min_date: any
	public max_date: any
	public minDate = { year: 2019, month: 7, day: 25 };

	@Output() onChange: EventEmitter<any> = new EventEmitter();

	@ViewChild('inp_fecha', { static: false }) inp_fecha: NgModel;

	constructor(private servicioModal: NgbModal, private ohCore: OHService) {
		this.fecha_horaChange = new EventEmitter<Date>();
		this.fecha_hora_realChange = new EventEmitter<string>();
	};

	ngOnInit() {
		if (this.default) {
			if (Object.prototype.toString.call(this.default) === '[object Date]' && this.module == 1) {
				this.fecha_hora_descripcion = this.default.getFullYear() + '-' + (this.default.getMonth() + 1) + '-' + this.default.getDate() + ' ' + this.ohCore.getOH().getUtil().pad(this.default.getHours()) + ":" + this.ohCore.getOH().getUtil().pad(this.default.getMinutes()) + ":00";
			} else {
				console.log('default not Date Obj');
			}
		}
		if (this.time) {
			this.hora = this.time;
		}
	}

	ngAfterViewInit() {
		if (this.form && this.inp_fecha) {
			this.inp_fecha.name = this.name;
			this.form.addControl(this.inp_fecha);
		}
		setTimeout(() => {
			if (this.fecha && this.dp) {
				this.dp.navigateTo(this.fecha);
			}
		});
	}

	ngOnChanges(changes: SimpleChanges) {
		if (changes.min) {
			if (this.min) {
				if (typeof this.min.getFullYear === "function") {
					this.min_date = { year: this.min.getFullYear(), month: this.min.getMonth() + 1, day: this.min.getDate(), hour: this.min.getHours(), minute: this.min.getMinutes() };
				} else {
					this.min_date = { year: this.min.year, month: this.min.month, day: this.min.day, hour: this.min.hour, minute: this.min.minute };
				}
			}
		}
		if (changes.max) {
			if (this.max) {
				if (typeof this.max.getFullYear === "function") {
					this.max_date = { year: this.max.getFullYear(), month: this.max.getMonth() + 1, day: this.max.getDate(), hour: this.max.getHours(), minute: this.max.getMinutes() };
				} else {
					this.max_date = { year: this.max.year, month: this.max.month, day: this.max.day, hour: this.max.hour, minute: this.max.minute };
				}
			}
		}
	}

	select() {
		if (this.onlyDate) {
			if (typeof this.fecha.getFullYear === "function") {
				this.fecha_hora = new Date(this.fecha.getFullYear(), this.fecha.getMonth(), this.fecha.getDate());
			} else {
				this.fecha_hora = new Date(this.fecha.year, this.fecha.month - 1, this.fecha.day);
			}
		} else {
			if (typeof this.fecha.getFullYear === "function") {
				this.fecha_hora = new Date(this.fecha.getFullYear(), this.fecha.getMonth(), this.fecha.getDate(), this.hora.hour, this.hora.minute, 0);
			} else {
				this._fecha_hora = new Date(this.fecha.year, this.fecha.month - 1, this.fecha.day, this.hora.hour, this.hora.minute, 0);
			}
		}
		this.fecha_horaChange.emit(this.fecha_hora);
		this.onChange.emit();
		this.onComplete.emit()

	}

	cancel() {
		this.servicioModal.dismissAll()
	}
	modalRef: any;
	abrirModal() {
		if (this._fecha_hora) {
			this.fecha = this.ohCore.getOH().getUtil().dateToNgb(this._fecha_hora);
			if (!this.onlyDate) {
				this.hora = this.ohCore.getOH().getUtil().dateToNgbTime(this._fecha_hora);
			}
		}

		this.modalRef = this.servicioModal.open(DatetimeModal, { centered: true, size: 'sm', scrollable: true, backdrop: 'static' });
		this.modalRef.componentInstance.fecha = this.fecha;
		this.modalRef.componentInstance.min_date = this.min_date;
		this.modalRef.componentInstance.max_date = this.max_date;
		this.modalRef.componentInstance.hora = this.hora;
		this.modalRef.componentInstance.onlyDate = this.onlyDate;

		this.modalRef.result.then((fecha_hora) => {
			this._fecha_hora = fecha_hora;
			this.fecha_horaChange.emit(fecha_hora);
			this.onChange.emit();
			this.onComplete.emit()
		}, (reason) => {
		});

	}

	ngOnDestroy() {
		if (this.form) {
			this.form.removeControl(this.inp_fecha);
		}
	}

	seleccionarEnter(event) {
		if (event.key === "Enter") {
			this.select();
		}
	}

	// NgbInputDatepicker
	flag: boolean = false
	pastedDate(event: ClipboardEvent, picker: NgModel) {
		if (this.pasteDate) {
			this.inp_fecha.model = null;
			this.inp_fecha.viewModel = null;
			this.fecha_hora_descripcion = '';
			let pastedText = event.clipboardData.getData('text');
			event.clipboardData.setData('text', '');



			if (pastedText.indexOf('/') != -1) {
				console.log('pastedText:', pastedText)
				// let fecha = this.ohCore.getOH().getUtil().dateToNgbThree(pastedText);
				let fecha = new Date(pastedText)
				console.log('fecha:', fecha)
				console.log(Object.prototype.toString.call(fecha))

				// let fecha = new Date(dateFormat.year, dateFormat.month, dateFormat.day)
				// this.fecha_hora_descripcion = dateFormat;
				// = dateFormat;
				// picker.open();	
				// picker.navigateTo(dateFormat);
				this.fecha_horaChange.emit(this.fecha)

				// this.fecha_hora_descripcion = this.ohCore.getOH().getUtil().dateToStringTwo(fecha) + " " + this.ohCore.getOH().getUtil().pad(fecha.getHours()) + ":" + this.ohCore.getOH().getUtil().pad(fecha.getMinutes()) + ":" + this.ohCore.getOH().getUtil().pad(fecha.getSeconds());

			}
		}
	}
}