import { Injectable } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import { ohStorage } from './ohCore/services/oh.core';
import { environment } from 'src/environments/environment';
import { shared } from 'src/environments/environmentShared';
import { ohObjDateFormatCustom, OHService } from './tis.ohService';
import { INLANDmain } from './firebase/main';
import { AngularFirestore } from '@angular/fire/firestore';
import { FIRECatalogo } from './firebase/catalogo';
import { AngularFireStorage } from '@angular/fire/storage';
import { INLANDimagen } from './firebase/storage_imagen';
import { merge, of, fromEvent, Observable } from 'rxjs';
import { mapTo } from 'rxjs/operators';
import { LoginServiceJPO, pDbowevLisMenu } from './service/ind.loginService';

declare var apmdata: any;

import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import { TranslocoService } from '@ngneat/transloco';
import { INDProcessService } from './view/tasks/ind.processService';
import { AzureCosmos } from './azure/cosmos';
import { HttpClient } from '@angular/common/http';
import { ADMCatalogoServiceJPO, pGescatalogoGetByParentAlias } from './module/ADM/service/adm.aDMCatalogoService';

export interface csConfig {
  disableSeparator: boolean;
  jpo: any;
  formatDate: string;
  formatDateTime: string;
  formatShortDateTime: string;
  formatTimeZone: string;
  mensajeRecarga: string;
  openMenu: boolean;
}

class MapCatalog {
  alias: string
  onLoad?: any
}

interface CatalogMap {
  [key: string]: pGescatalogoGetByParentAlias[];
}

export class DataMain {
  catalogo?: any
  catalog?: CatalogMap
}

export class ohLoadCatalog {

  storage: ohStorage;
  private aDMCatalogoService: ADMCatalogoServiceJPO

  private ecs: any
  private storageName: string
  private sincronize: boolean

  constructor(public cse: CoreService, ohService: OHService, ecs: any, storageName: string, sincronize?: boolean) {
    this.storage = new ohStorage()
    this.ecs = ecs
    this.storageName = storageName
    this.aDMCatalogoService = new ADMCatalogoServiceJPO(ohService)
    this.sincronize = sincronize
  }

  catalogs: MapCatalog[]
  catalogsMaps: any

  public check() {
    if (this.storage.has(this.storageName) && !this.ecs.data._loaded) {
      this.ecs.data = this.storage.get(this.storageName)
    }
    return !this.storage.has(this.storageName) || this.ecs.data.version != environment.version || this.ecs.data.lang != this.cse._translocoService.getActiveLang()
  }

  mapLoad(catalogs: MapCatalog[], externalService?: Promise<any>, call?: any) {// Promise<any>

    this.catalogs = catalogs

    return new Promise<void>((resolve, reject) => {

      if (this.storage.has(this.storageName) && !this.ecs.data._loaded) {
        this.ecs.data = this.storage.get(this.storageName)
      }

      if (this.check()) {
        this.initialLoad(externalService, call, resolve)

      } else {
        if (this.sincronize) {
          this.initialLoad(externalService, call)
        }
        resolve()
      }

    })

  }

  initialLoad(externalService: any, call: any, resolve?: any) {

    this.ecs.data.version = environment.version
    this.ecs.data.lang = this.cse._translocoService.getActiveLang()
    if (!this.ecs.data.catalog) {
      this.ecs.data.catalog = {}
    }
    this.ecs.data._loaded = true

    this.catalogsMaps = []

    if (this.catalogs) {

      this.catalogsMaps.push(new Promise<void>((resolve, reject) => {

        this.aDMCatalogoService.gescatalogoGetByParentAlias({
          language_id: this.cse.data.user.language_id,
          catalog_alias: JSON.stringify(this.catalogs.map((it) => { return it.alias }))
        }, (resp: pGescatalogoGetByParentAlias[]) => {

          this.catalogs.forEach((catalogMap) => {

            let _childrens = resp.filter(it => it.parent_alias == catalogMap.alias)

            if (catalogMap.onLoad) {
              _childrens = catalogMap.onLoad(_childrens)
            }

            _childrens.forEach((catalog) => {

              let _var2 = null
              if (catalog.variable_2) {
                try {
                  _var2 = JSON.parse(catalog.variable_2)
                } catch (e) {
                  console.error("error parsing " + catalog.variable_2)
                }
              }
              catalog["variable_2"] = _var2

            })

            this.ecs.data.catalog[catalogMap.alias] = _childrens

          })

          resolve()

        })

      }))

    }

    if (externalService) {
      this.catalogsMaps.push(externalService)
    }

    Promise.all(this.catalogsMaps).then(values => {

      this.storage.set(this.storageName, this.ecs.data)

      if (call) {
        call()
      }

      if (resolve) {
        resolve();
      }

    })

  }

}

export class ohLoadSubModule {

  storage: ohStorage;

  private ecs: any
  private storageName: string

  constructor(public cse: CoreService, ecs: any, storageName: string) {
    this.storage = new ohStorage()
    this.ecs = ecs
    this.storageName = storageName
  }

  catalogs: any
  catalogsMaps: any

  public check() {
    if (this.storage.has(this.storageName) && !this.ecs.data._loaded) {
      this.ecs.data = this.storage.get(this.storageName)
    }
    return !this.storage.has(this.storageName) || this.ecs.data.version != environment.version || this.ecs.data.lang != this.cse._translocoService.getActiveLang()
  }

  mapLoad(catalogs: any, externalService?: Promise<any>, call?: any) {// Promise<any>

    this.catalogs = catalogs

    return new Promise<void>((resolve, reject) => {

      if (this.storage.has(this.storageName) && !this.ecs.data._loaded) {
        this.ecs.data = this.storage.get(this.storageName)
      }

      if (this.check()) {

        this.ecs.data = {
          version: environment.version,
          lang: this.cse._translocoService.getActiveLang(),
          catalogo: {},
          _loaded: true
        };

        this.catalogsMaps = []
        if (this.catalogs) {

          if (this.cse.azureCosmos.client) {
            this.mapCatalogs(this.catalogs)
          } else {
            for (var i in this.catalogs) {
              this.mapCatalog(this.catalogs[i]);
            }
          }

        }

        if (externalService) {
          this.catalogsMaps.push(externalService)
        }

        Promise.all(this.catalogsMaps).then(values => {

          this.storage.set(this.storageName, this.ecs.data)

          if (call) {
            call()
          }

          resolve();

        })

      } else {

        resolve()
      }

    })

  }

  private mapCatalogs(catalogs: any) {

    if (catalogs && catalogs.length > 0) {

      let _ids = []

      for (var i in catalogs) {

        let _shr = null

        if (catalogs[i].id) {
          _shr = _ids.find(it => it == catalogs[i].id)
          if (!_shr) {
            _ids.push(catalogs[i].id)
          }
        } else {
          _shr = _ids.find(it => it == catalogs[i].alias)
          if (!_shr) {
            _ids.push(catalogs[i].alias)
          }
        }

      }

      this.catalogsMaps.push(new Promise<void>((resolve, reject) => {
        var _query: any
        if (catalogs[0].id) {
          _query = this.cse.adm_catalogo.getCatalogoByPadres(_ids)
        } else {
          _query = this.cse.adm_catalogo.getCatalogoByPadresAlias(_ids)
        }

        _query.subscribe((elements: any) => {

          for (var i in catalogs) {

            if (catalogs[i].id) {
              let _childrens = elements.filter(it => it.catalogo_padre_id == catalogs[i].id)
              if (_childrens) {
                this.mapHijos(catalogs[i], _childrens)
              }
            } else {
              let _childrens = elements.filter(it => it.catalog_parent_alias == catalogs[i].alias)
              if (_childrens) {
                this.mapHijos(catalogs[i], _childrens)
              }
            }


          }

          resolve()

        })

      }))


    }



  }

  private mapCatalog(catalogo: any) {

    this.catalogsMaps.push(new Promise<void>((resolve, reject) => {

      this.cse.adm_catalogo.getCatalogoByPadre(catalogo.id).subscribe((elementos: any) => {
        this.mapHijos(catalogo, elementos)
        resolve()

      })

    }))

  }

  private mapHijos(catalogo, elementos) {

    if (elementos && elementos.length > 0) {
      for (var i in elementos) {
        /* Eliminar los campos que tengan null */
        for (var u in elementos[i]) {
          if (elementos[i][u] == null) {
            elementos[i][u] = undefined;
          }
        }

        if (elementos[i].idioma) {
          let lng = elementos[i].idioma.find(it => it.codigo == this.cse._translocoService.getActiveLang())
          if (lng) {
            elementos[i].descripcion = lng.descripcion ? lng.descripcion : elementos[i].descripcion
            elementos[i].descricion_larga = lng.descricion_larga ? lng.descricion_larga : elementos[i].descricion_larga
          }
        }
      }
      if (catalogo.onLoad) {
        elementos = catalogo.onLoad(elementos)
      }
      this.ecs.data.catalogo[catalogo.nombre] = elementos
    }

    if (elementos && elementos.length > 0 && catalogo.mapAsValue) {

      var estado_objetos = {}

      for (var i in elementos) {

        let _var2 = {}

        if (elementos[i].variable_2 && (typeof elementos[i].variable_2 == 'object' || typeof elementos[i].variable_2 == 'string')) {
          try {
            _var2 = JSON.parse(elementos[i].variable_2)
          } catch (e) {
            console.error("error parsing " + elementos[i].variable_2)
          }
        }

        //let _var2 = elementos[i].variable_2 && (typeof elementos[i].variable_2 == 'object' || typeof elementos[i].variable_2 == 'string') ? JSON.parse(elementos[i].variable_2) : {}
        estado_objetos[elementos[i].catalogo_id] = Object.assign({ descripcion: elementos[i].descripcion }, _var2);

      }

      this.ecs.data[catalogo.nombre] = estado_objetos

    }
  }

}

@Injectable({
  providedIn: 'root',
})
export class CoreService {

  precarga: Promise<any>;
  private loginService: LoginServiceJPO;
  public data: any = {};
  public config: csConfig;
  private myStorage: ohStorage;
  public inland_main: INLANDmain;
  public inland_imagen: INLANDimagen;
  public adm_catalogo: FIRECatalogo;
  public isOnline = new Observable<boolean>();
  public processService: INDProcessService;

  public action: any = null;

  public params: any = {
    estado: {
      activo: 20253,
      inactivo: 20254,
    },
    field_max: {
      integer: 2000000000,
    },
    data_activo: {
      '1': {
        value: '1',
        btn: 'btn-outline-success',
        badge: 'badge-outline-success',
        icon: 'fas fa-thumbs-up',
        descripcion: 'Activo',
      },
      '0': {
        value: '0',
        btn: 'btn-outline-danger',
        badge: 'badge-outline-danger',
        icon: 'fas fa-thumbs-down',
        descripcion: 'Inactivo',
      },
    },
    scriptURL: {
      jsonEditor: {
        script:
          'https://cdnjs.cloudflare.com/ajax/libs/jsoneditor/8.6.6/jsoneditor.min.js',
        css:
          'https://cdnjs.cloudflare.com/ajax/libs/jsoneditor/8.6.6/jsoneditor.min.css',
      },
    }
  };

  public sis_rule: any = {
    user_questions: 'sis_rule_user_questions',
    person_user: 'sis_rule_person_user',
    preferences: 'sis_rule_preferences',
    foot_page: 'sis_rule_foot_page',
    font_maersk: 'sis_rule_font_maersk',
  };

  catalogos: any = {
    catalogo: {},
    referencia: {},
  };

  _translocoService: TranslocoService;

  languajeLoad: any;
  languajeLoaResolve: any;

  azureCosmos: AzureCosmos

  constructor(
    db: AngularFirestore,
    private router: Router,
    private ohService: OHService,
    fireStore: AngularFireStorage,
    private route: ActivatedRoute,
    private translocoService: TranslocoService,
    private http: HttpClient
  ) {

    this.azureCosmos = new AzureCosmos()
    this._translocoService = translocoService

    this.languajeLoad = {};
    this.languajeLoaResolve = {};

    this.isOnline = merge(
      of(navigator.onLine),
      fromEvent(window, 'online').pipe(mapTo(true)),
      fromEvent(window, 'offline').pipe(mapTo(false))
    );

    this.config = {
      disableSeparator: false,
      jpo: null,
      formatDate: '',
      formatDateTime: '',
      formatShortDateTime: '',
      formatTimeZone: '',
      mensajeRecarga: 'Los cambios que haces no serán guardados',
      openMenu: false,
    };
    this.data.onBeforeUnload = false;
    this.myStorage = new ohStorage();
    this.inland_main = new INLANDmain(
      db,
      environment.firebase_coleccion_base,
      'INLAND',
      this.azureCosmos
    );
    this.adm_catalogo = new FIRECatalogo(
      db,
      environment.firebase_coleccion_base,
      this.azureCosmos.client ? 'INLAND' : 'ADM',
      this.azureCosmos
    );

    this.precarga = new Promise((resolve, reject) => {
      this.inland_imagen = new INLANDimagen(
        fireStore,
        environment.firebase_coleccion_base,
        ohService
      );
      resolve(null);
    });
  }

  subSystemLanguaje(subSystems: any, systemId: string) {

    for (var i in subSystems) {
      if (subSystems[i][systemId]) {
        let _data = subSystems[i][systemId];

        for (var u in _data) {

          if (!_data[u].description_) {
            _data[u].description_ = "" + _data[u].description
          }

          let _langkey = i + "-" + systemId + "-" + this.data.user.languaje_selected

          if (this.data.user.languajeMenu[_langkey]) {

            _data[u].description = this.data.user.languajeMenu[_langkey][_data[u].description_] || _data[u].description_

          }

        }
      }
    }

    return subSystems;
  }

  private roles: any;
  public buildMenu(
    companyId?: string,
    systemId?: string,
    searchNoLanguaje?: boolean
  ) {
    if (!searchNoLanguaje && systemId) {
      this.data.user.subSystems = this.subSystemLanguaje(
        this.data.user.subSystems,
        systemId
      );
    }

    this.roles = [];

    var validSyPr = true;
    var systemIndex = {};
    if (
      !this.data.user.systemByProfile ||
      (this.data.user.systemByProfile &&
        this.data.user.systemByProfile.length == 1 &&
        this.data.user.systemByProfile[0].systemId.length == 0)
    ) {
      validSyPr = false;
    } else {
      var filtered = this.data.user.systemByProfile.filter(
        (sypr) => sypr.profileId == this.data.user.profile
      );
      for (var e in filtered) {
        systemIndex[filtered[e].systemId] = true;
      }
    }

    for (var i = 0; i < this.data.user.systems.length; i++) {
      var item = this.data.user.systems[i];
      if (
        item.id &&
        ((validSyPr && systemIndex[item.id.trim()]) || !validSyPr)
      ) {
        this.roles.push({
          id: item.id.trim(),
          type: 1, // PROYECT
          url: item.id.trim(),
          name: item.description,
          icon: item.icon,
        });
      }
    }

    if (companyId && systemId) {
      if (
        this.data.user['subSystems'] &&
        this.data.user['subSystems'][companyId] &&
        this.data.user['subSystems'][companyId][systemId]
      ) {
        var companySystem = this.data.user['subSystems'][companyId][systemId];
        for (i = 0; i < companySystem.length; i++) {
          var item = this.data.user['subSystems'][companyId][systemId][i];
          this.roles.push({
            id: item.id.trim(),
            idParent: item.parentId.trim(),
            type: 2, // PROYECT
            url: item.url.trim(),
            name: item.description,
            icon: item.icon,
            hasId: item.hasId && item.hasId == '1' ? true : false,
            actions: item.actions,
          });
        }
      }
    }

    this.data.routeRol = this.buildMenuRouteRol();

    this.data.user.routeRol = this.data.routeRol;

    this.myStorage.set("APM_DATA", this.data.user);

    this.data.routeId = {};
    this.data.roles = this.roles;
  }

  tmpList: any = [];
  private buildMenuRouteRol() {
    let routeRol: any = {};
    for (var i = 0; i < this.roles.length; i++) {
      this.roles[i].urlTree = '/Be' + this.buildMenuURLTree(this.roles[i].id);
    }
    for (i = 0; i < this.roles.length; i++) {
      this.tmpList = [];
      this.buildMenuTree(this.roles[i].id);

      routeRol[this.roles[i].urlTree] = {
        id: this.roles[i].id,
        list: this.tmpList,
        hasId: this.roles[i].hasId ? true : false,
        actions: this.roles[i].actions,
      };
    }
    return routeRol;
  }

  private buildMenuURLTree(id: any) {
    for (var i = 0; i < this.roles.length; i++) {
      if (this.roles[i].id == id) {
        let preUrl: string = '';
        if (this.roles[i].idParent) {
          preUrl += this.buildMenuURLTree(this.roles[i].idParent);
        }
        if (this.roles[i].hasId) {
          preUrl += '/' + this.roles[i].url; // +"/{id}"
        } else {
          preUrl += '/' + this.roles[i].url;
        }
        return preUrl;
      }
    }
  }

  private buildMenuTree(id: any) {
    for (var i = 0; i < this.roles.length; i++) {
      if (this.roles[i].id == id) {
        this.tmpList.push(this.roles[i]);
        if (this.roles[i].idParent) {
          this.buildMenuTree(this.roles[i].idParent);
        }
      }
    }
  }

  public setUserOptions(uo: any) {
    this.data.uo = uo;
    this.data.uo_id = {};
    for (var i = 0; i < uo.length; i++) {
      this.data.uo_id[uo[i].url] = i;
    }
  }

  getUO() {
    let uo: any = [];
    uo.push({
      name: this.translocoService.translate('userOptions.preferences'),
      icon: 'far fa-address-card',
      url: '/' + shared.baseBackEnd + '/Preferences',
    });
    uo.push({
      name: this.translocoService.translate('userOptions.changePassword'),
      icon: 'fas fa-key',
      url: '/' + shared.baseBackEnd + '/PasswordChange',
    });
    uo.push({
      name: this.translocoService.translate('userOptions.reports'),
      icon: 'fas fa-file-excel',
      url: '/' + shared.baseBackEnd + '/Report',
    });
    uo.push({
      name: this.translocoService.translate('userOptions.dashboards'),
      icon: 'far fa-chart-bar',
      url: '/' + shared.baseBackEnd + '/Dashboard',
    });
    uo.push({
      name: this.translocoService.translate('userOptions.tasks'),
      icon: 'fas fa-tasks',
      url: '/' + shared.baseBackEnd + '/Tasks',
    });
    uo.push({
      name: this.translocoService.translate('userOptions.messages'),
      icon: 'fas fa-envelope',
      url: '/' + shared.baseBackEnd + '/Messages',
    });

    if (this.data.user.rules) {
      this.data.user.rules.forEach((element) => {
        if (element.id == this.sis_rule.user_questions) {
          uo.push({
            name: this.translocoService.translate('Questions Setting'),
            icon: 'fas fa-keyboard',
            url: '/' + shared.baseBackEnd + '/QuestionsSetting',
          });
        }
      });
    }

    if (this.data.user.terms) {
      this.data.user.terms.forEach((element) => {
        if (element.unidad_negocio_id == this.data.user.profile) {
          uo.push({
            name: this.translocoService.translate('userOptions.termscondition'),
            icon: 'fas fa-file-contract',
            url: '/' + shared.baseBackEnd + '/TermsConditions',
          });
        }
      });
    }

    if (this.data.user.faqs) {
      this.data.user.faqs.forEach((element) => {
        if (element.unidad_negocio_id == this.data.user.profile) {
          uo.push({
            name: this.translocoService.translate('userOptions.faq'),
            icon: 'fas fa-question-circle',
            url: '/' + shared.baseBackEnd + '/Faqs',
          });
        }
      });
    }
    return uo;
  }

  public getTreeChild(url: string) {
    var treeChild = [];
    if (this.data.routeRol[url]) {
      treeChild.push(Object.assign({}, this.data.routeRol[url].list[0]));
      treeChild[0].isMain = true;
    }

    for (var i in this.data.routeRol) {
      var ind = i.indexOf(url);
      if (ind != -1 && ind == 0) {
        var subPart = i.substr(url.length);
        if (subPart != '') {
          var subParts = subPart.split('/');
          if (subParts.length == 2 && !this.data.routeRol[i].hasId) {
            treeChild.push(this.data.routeRol[i].list[0]);
          }
        }
      }
    }
    return treeChild;
  }

  public getTreeChilds(url: string) {
    var treeChild = [];
    for (var i in this.data.routeRol) {
      var ind = i.indexOf(url);
      if (ind != -1 && ind == 0) {
        var subPart = i.substr(url.length);
        if (subPart != '') {
          var subParts = subPart.split('/');
          if (subParts.length == 2 && !this.data.routeRol[i].hasId) {
            treeChild.push(this.data.routeRol[i].list[0]);
          }
        }
      }
    }
    return treeChild;
  }

  public hasChilds(route: string, urls: string[]): boolean {
    var treeChild = [];
    var tieneHijos = 0;
    for (var i in this.data.routeRol) {
      var ind = i.indexOf(route);
      if (ind != -1 && ind == 0) {
        var subPart = i.substr(route.length);
        if (subPart != '') {
          var subParts = subPart.split('/');
          if (subParts.length == 2 && !this.data.routeRol[i].hasId) {
            if (urls.find((it) => it == this.data.routeRol[i].list[0].url)) {
              tieneHijos++;
            }
            treeChild.push(this.data.routeRol[i].list[0]);
          }
        }
      }
    }
    return tieneHijos > 0 ? true : false;
  }

  logoutHref() {
    this.router.navigate(['/Login']).then(() => {
      window.location.reload();
    });
  }

  cleanLS() {
    this.myStorage.clear()
  }

  onUpdate(reload?: boolean) {
    if (apmdata && apmdata.service) {
      apmdata.service.update().then(() => {
        if (reload) {
          //Parametro "TRUE" fuerza a una actualizacion borrando cache
          window.location.reload();
        }
      });
    }
  }

  validateBeforeUnload(urlId: (string | any)) {
    if (this.data.onBeforeUnload) {

      let _call
      if (typeof (urlId) == "string") {
        _call = () => {
          this.data.onBeforeUnload = false;
          this.router.navigate([urlId]);
        }
      } else {
        _call = urlId
      }

      this.ohService
        .getOH()
        .getUtil()
        .confirm(this.translocoService.translate('messages.beforeUnload'), _call);
      return false;
    } else {
      return true;
    }
  }

  toLoadModule(rol: any, call?: any) {

    this.loginService = new LoginServiceJPO(this.ohService)

    this.ohService.getOH().getLoader().show()
    var user = this.data.user

    var companyId = this.data.user.profile;

    let _langkey = companyId + "-" + rol.id + "-" + this.data.user.languaje_selected

    if (
      user['subSystems'] &&
      user['subSystems'][companyId] &&
      user['subSystems'][companyId][rol.id]
      && (this.data.user.languajeMenu && this.data.user.languajeMenu[_langkey])
    ) {

      if (call) {
        this.ohService.getOH().getLoader().close();
        call();
      } else {
        this.router.navigate([rol.urlTree]);
      }
    } else {

      Promise.all([
        this.loadSubModuleLanguaje(companyId, rol.id, this.data.user.languaje_selected),
        this.dbowevLisMenu(companyId, rol.id)
      ]).then(its => {

        let resp: pDbowevLisMenu[] = its[1]

        if (resp && resp.length > 0) {
          this.ohService.getOH().getLoader().show();
          for (var u = 0; u < resp.length; u++) {
            resp[u].id = '' + resp[u].id;
            resp[u].parentId = '' + resp[u].parentId;

            if (resp[u].acciones) {
              resp[u]['actions'] = this.getAcciones(resp[u].acciones);
            }
          }
          if (!user['subSystems']) {
            user.subSystems = {};
          }
          if (!user['subSystems'][companyId]) {
            user.subSystems[companyId] = {};
          }
          if (!user['subSystems'][companyId][rol.id]) {
            user.subSystems[companyId][rol.id] = resp;
          }

          this.myStorage.set("APM_DATA", user);

          if (call) {
            this.ohService.getOH().getLoader().close();
            call();
          } else {

            this.onEnterModule(rol.id)

            this.router.navigate([rol.urlTree]);
          }
        } else {
          this.ohService
            .getOH()
            .getAd()
            .warning("No cuentas con submódulos en '" + rol.name + "'");
        }

      })

    }
  }

  private dbowevLisMenu(companyId: string, rolid: string) {
    return new Promise<any>((resolve, reject) => {

      this.loginService.dbowevLisMenu(
        {
          Origen: this.data.user.data.source,
          Usuario: this.data.user.data.userid,
          Empresa: companyId,
          Sistema: rolid,
        },
        (resp: pDbowevLisMenu[]) => {
          resolve(resp)
        }
      );

    })
  }

  private loadSubModuleLanguaje(companyId: string, submodule: string, languaje: string) {
    return new Promise<void>((resolve, reject) => {

      let _langkey = companyId + "-" + submodule + "-" + this.data.user.languaje_selected

      if (!this.data.user.languajeMenu) {
        this.data.user.languajeMenu = {}
      }

      if (!this.data.user.languajeMenu[_langkey]) {

        this.http.get("./assets/i18n/" + submodule + "/" + languaje + ".json").subscribe({
          next: (it) => {

            this.data.user.languajeMenu[_langkey] = it['menu'] ? this.getMapLanguajeMenu(it['menu'], submodule) : null

            resolve()
          },
          error: (error) => {
            resolve()
          }
        })

      } else {
        resolve()
      }

    })
  }

  private getMapLanguajeMenu(menu: any, prefix: string) {
    var final = {}
    this.mapMenu(final, "$" + prefix + ".menu", menu)
    return final
  }

  private mapMenu(final, prefix, menu) {
    for (var value in menu) {
      let _pref = prefix + "." + value
      if (typeof (menu[value]) == "string") {
        final[_pref] = menu[value]
      } else {
        this.mapMenu(final, _pref, menu[value])
      }
    }
  }

  public onEnterModule(scope: any) {
    this.data.user.subSystems = this.subSystemLanguaje(this.data.user.subSystems, scope)
    /*
        if (this.languajeLoad[scope] && this.languajeLoaResolve[scope]) {
          this.languajeLoaResolve[scope]()
        }*/

    this.buildMenu(this.data.user.profile, scope, true)

  }

  private getAcciones(acciones_xml: any) {
    var acciones = {};
    if (acciones_xml) {
      var misAcciones = this.ohService
        .getOH()
        .getUtil()
        .StringXMLtoJSON(acciones_xml);
      if (
        misAcciones &&
        misAcciones.resultado &&
        misAcciones.resultado.acciones
      ) {
        if (misAcciones.resultado.acciones.length) {
          for (var i in misAcciones.resultado.acciones) {
            acciones[
              misAcciones.resultado.acciones[i]['@attributes'].accion
            ] = true;
          }
        } else {
          acciones[misAcciones.resultado.acciones['@attributes'].accion] = true;
        }
      }
    }
    return acciones;
  }

  arrayRemover(arr, key, value) {
    return arr.filter((element) => {
      return element[key] != value;
    });
  }
  arrayRemover2(arr, key, value1, value2) {
    return arr.filter((element) => {
      return element[key] != value1 && element[key] != value2;
    });
  }
  arrayRemoverExcept(arr, key, value) {
    return arr.filter((element) => {
      return element[key] == value;
    });
  }
  arrayRemoverExcept2(arr, key, value1, value2) {
    return arr.filter((element) => {
      return element[key] == value1 || element[key] == value2;
    });
  }

  getZxingError(nro: number) {
    if (nro == 1) {
      return 'Funcionalidad permitida sólo para dispositivos Android';
    }
    if (nro == 2) {
      return 'Funcionalidad permitida sólo en Chrome';
    }
    if (nro == 3) {
      return 'El App Barcode Scanner no se encuentra en tu dispositivo, instálelo primero';
    }
  }

  tinymceConfig = {
    height: 300,
    plugins:
      'print preview searchreplace autolink directionality visualblocks visualchars fullscreen image link media template table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists textcolor wordcount imagetools contextmenu colorpicker textpattern help code',
    toolbar:
      'formatselect | bold italic strikethrough forecolor backcolor | link | alignleft aligncenter alignright alignjustify | numlist bullist outdent indent | removeformat code',
    tinymceScriptURL: 'assets/tinymce/tinymce.min.js',
    baseURL: 'assets/js/tinymce',
    skin_url: '/assets/js/tinymce/skins/ui/oxide',
    theme_url: '/assets/js/tinymce/themes/silver/theme.min.js',
  };

  mapRol() {
    this.data.roles_id = {};
    if (this.data.user.roles) {
      for (var i in this.data.user.roles) {
        let rol = this.data.user.roles[i];
        if (rol.id_) {
          this.data.roles_id[rol.id_] = rol;
        }
      }
    }
  }

  tieneRol(roles: any) {
    if (typeof roles == 'string') {
      return this.data.roles_id[roles] ? true : false;
    } else {
      var tieneRol = 0;
      for (var i in roles) {
        if (this.data.roles_id[roles[i]]) {
          tieneRol++;
        }
      }
      return tieneRol > 0 ? true : false;
    }
  }

  obtenerPerfil(profiles) {
    var defaultProfile = profiles[0].id;
    var finded = profiles.filter((profile) => profile.default == '1');
    if (finded.length > 0) {
      return finded[0].id;
    }
    return defaultProfile;
  }

  // Valida si es la url principal
  validMainURL(url) {
    return url == '/' || url == '/' + shared.baseBackEnd + '/Main';
  }

  trackTable(index, item) {
    return index; // or item.id
  }

  getSubUnidad(sub_unidad_negocio_id: any): any {
    return (
      this.getSubUnidades().find(
        (it) => it.unidad_negocio_id == sub_unidad_negocio_id
      ) || null
    );
  }

  toExcel(config) {
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(config.data);
    if (config.cols) {
      worksheet['!cols'] = config.cols;
    }
    const workbook: XLSX.WorkBook = {
      Sheets: { data: worksheet },
      SheetNames: ['data'],
    };
    const excelBuffer: any = XLSX.write(workbook, {
      bookType: 'xlsx',
      type: 'array',
    });

    const data: Blob = new Blob([excelBuffer], {
      type:
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8',
    });
    FileSaver.saveAs(
      data,
      config.final_name
        ? config.final_name
        : config.name + '_export_' + new Date().getTime() + '.xlsx'
    );
  }

  /* Obtener las subunidades por la unidad de negocio seleccionada */
  obtenerSubUnidades() {
    if (this.data.user.sub_unidades) {
      const sub_unidades = this.data.user.sub_unidades_sistema.filter((x) => {
        //const sub_unidades = this.data.user.sub_unidades.filter(x => {
        return x.unidad_negocio_padre_id == this.data.user.profile;
      });
      /* Obtener los locales de las subunidades */
      sub_unidades.forEach((disponible) => {
        const locales = this.data.user.sub_unidades_sistema.filter((x) => {
          //const locales = this.data.user.sub_unidades.filter(x => {
          return x.unidad_negocio_padre_id == disponible.unidad_negocio_id;
        });
        disponible.locales = locales;
      });

      return sub_unidades;
    } else {
      return [];
    }
  }

  /* Obtener el valor del padre seleccionado del valor profile_sub_unit_default
  (Devuelve 22 CALLAO por default) */
  obtenerPadreUnidadNegocio(sub_unidad: number) {
    if (this.data.user.sub_unidades_sistema) {
      const sub_unidad_elegida = this.data.user.sub_unidades_sistema.find(
        (x) => x.unidad_negocio_id == sub_unidad
      );
      return sub_unidad_elegida
        ? sub_unidad_elegida.unidad_negocio_padre_id
        : 22;
    }
    return 22;
  }

  getSubUnidadesLista() {
    let subUnidadesDisponibles = this.obtenerSubUnidades();

    var subUnidades = [];

    for (var item of subUnidadesDisponibles) {
      if (item.locales && item.locales.length > 0) {
        for (var local of item.locales) {
          subUnidades.push({
            unidad_negocio_id: local.unidad_negocio_id,
            unidad_negocio_padre_id: local.unidad_negocio_padre_id,
            nombre: item.nombre + ' / ' + local.nombre,
            alias_unidad_negocio: local.alias_unidad_negocio
          });
        }
      } else {
        subUnidades.push({
          unidad_negocio_id: item.unidad_negocio_id,
          unidad_negocio_padre_id: item.unidad_negocio_padre_id,
          nombre: item.nombre,
          alias_unidad_negocio: item.alias_unidad_negocio
        });
      }
    }

    return subUnidades;
  }

  getUnidades() {
    if (this.data.user.profiles) {
      let _un = [];
      for (var i in this.data.user.profiles) {
        _un.push({
          unidad_negocio_id: this.data.user.profiles[i].id,
          nombre: this.data.user.profiles[i].businessName,
          sub_unidades: this.data.user.profiles[i].sub_unidades,
        });
      }
      return _un;
    } else {
      return [];
    }
  }

  getSubUnidades() {
    if (this.data.user.profile) {
      return this.getSubUnidadesLista();
    }
    return [];
  }

  getSubUnits = this.getSubUnidades

  public getCatalogos(mycatalogo: any) {
    if (this.myStorage.has('APM_DATA_CATALOGO')) {
      this.catalogos = this.myStorage.get('APM_DATA_CATALOGO');
    }

    for (var i in mycatalogo) {
      if (!this.catalogos.catalogo[mycatalogo[i].nombre]) {
        this.cargarCatalogo(mycatalogo[i]);
      }
    }
  }

  public cargarCatalogo(catalogo: any) {
    this.adm_catalogo
      .getCatalogoByPadre(catalogo.id)
      .subscribe((elementos: any) => {
        this.catalogos.catalogo[catalogo.nombre] = elementos;
        this.myStorage.set('APM_DATA_CATALOGO', this.catalogos);
        if (catalogo.enableStates) {
          this.parsearEstados(catalogo.nombre);
        }
      });
  }

  public parsearEstados(estado_nombre: string) {
    var estado_objetos = {};
    for (var i in this.catalogos.catalogo[estado_nombre]) {
      let estado = this.catalogos.catalogo[estado_nombre][i];
      estado_objetos[estado.catalogo_id] = Object.assign(
        { descripcion: estado.descripcion },
        JSON.parse(estado.variable_2)
      );
    }
    this.catalogos.referencia[estado_nombre] = estado_objetos;
    this.myStorage.set('APM_DATA_CATALOGO', this.catalogos);
  }

  public getRule(rule_id: string) {
    let user = this.data.user;
    if (user.rules) {
      let rule = user.rules.find((item) => item.id == rule_id);
      if (rule && rule.parametros) {
        return rule.parametros;
      }
    }
    return null;
  }

  mapFormatDates() {
    let _prof = this.data.user.profiles.find(
      (it) => it.id == this.data.user.profile
    );

    if (_prof && _prof.configuraciones && _prof.configuraciones.length > 0) {
      let _fd = _prof.configuraciones.find(
        (it) => it.tipo_config_id == 'format_date'
      );
      if (_fd && _fd.tipo_config_valor && _fd.tipo_config_valor.length > 0) {
        this.config.formatDate = _fd.tipo_config_valor;
      }

      let _fdt = _prof.configuraciones.find(
        (it) => it.tipo_config_id == 'format_date_time'
      );
      if (_fdt && _fdt.tipo_config_valor && _fdt.tipo_config_valor.length > 0) {
        this.config.formatDateTime = _fdt.tipo_config_valor;
      }

      let _fsdt = _prof.configuraciones.find(
        (it) => it.tipo_config_id == 'format_short_date_time'
      );
      if (
        _fsdt &&
        _fsdt.tipo_config_valor &&
        _fsdt.tipo_config_valor.length > 0
      ) {
        this.config.formatShortDateTime = _fsdt.tipo_config_valor;
      }
    }

    ohObjDateFormatCustom.format = this.config.formatDate;
    console.log("ohObjDateFormatCustom.formatTime = this.config.formatShortDateTime");
    console.log(this.config.formatShortDateTime);
    ohObjDateFormatCustom.formatTime = this.config.formatShortDateTime;
  }

  validLang() {
    if (this.data.user.idiomas && this.data.user.idiomas.length > 0) {
      let langs = []
      for (var i in this.data.user.idiomas) {
        langs.push(this.data.user.idiomas[i].codigo)
      }
      this.translocoService.setAvailableLangs(langs);

      let lang_default = this.data.user.idiomas.find(it => it.defecto == true)
      if (this.data.user.languaje_selected) {
        lang_default = {
          codigo: this.data.user.languaje_selected
        }
      } else {
        if (!lang_default) {
          let _finded = this.data.user.idiomas.find(it => it.codigo == environment.default_languaje)
          lang_default = {
            codigo: _finded ? environment.default_languaje : this.data.user.idiomas[0].codigo
          }
        }
      }

      this.translocoService.setActiveLang(lang_default.codigo)

      if (this.data.user.languaje_selected != lang_default.codigo) {
        this.data.user.languaje_selected = lang_default.codigo
        let _lang = this.data.user.idiomas.find(it => it.codigo == lang_default.codigo)
        if (_lang) {
          this.data.user.language_id = _lang.idioma_id
        }
      }

    } else {
      this.translocoService.setAvailableLangs([environment.default_languaje])
      this.translocoService.setActiveLang(environment.default_languaje)
    }
  }

  openLink(item: any, call?: any) {
    let _rol = this.data.roles.find(it => it.id == item.plantilla);
    if (_rol) {
      this.toLoadModule(_rol, () => {
        this.buildMenu(this.data.user.profile, item.plantilla);
        console.log('llega');

        this.router.navigate(['/' + shared.baseBackEnd + '/' + item.url_referencia], { state: { data: item } })
      })
      if (call) {
        call()
      }
    } else {
      this.ohService.getOH().getAd().warning(this.translocoService.translate('messages.wrongAccess'))
    }
  }

  getParentSubUnit() {
    let subunit = this.getSubUnidades().find(it => it.unidad_negocio_id == this.data.user.profile_sub_unit_default)
    if (subunit) {
      return subunit.unidad_negocio_padre_id
    }
    return null
  }

  getCatalogValueByField(element: any, field: any, fieldSearch: any, fieldFind: string) {
    if (element) {
      let finded = element.find(it => it[fieldSearch] == field)
      if (finded) {
        return finded[fieldFind]
      }
    }
  }

}