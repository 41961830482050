<!-- <div cdkDropListGroup id="timeline" class="d-flex flex-row flex-wrap border rounded w-100 inpt-select text-left example-list" (click)="setFocus()">
    <div cdkDropList [cdkDropListEnterPredicate]="enter" (cdkDropListDropped)="drop()" #placeholder></div>
    <div cdkDropList [cdkDropListEnterPredicate]="enter" (cdkDropListDropped)="drop($event)" *ngFor="let val of array">
        <div cdkDrag class="border px-1 rounded text-muted mr-1 mb-1 d-flex flex-row example-box">
            <ng-template [ngIf]="type == 0">
                <a class="px-1 mr-1" href="#" (click)="$event.preventDefault(); del(val)">
                    <i class="fas fa-times fa-xs"></i>
                </a>
                <span [title]="val" [innerHTML]="val"></span>
            </ng-template>
            <ng-template [ngIf]="type == 1">
                <a class="px-1 mr-1" href="#" (click)="$event.preventDefault(); del(val.value)">
                    <i class="fas fa-times fa-xs"></i>
                </a>
                <span [title]="val.value" [innerHTML]="val.value"></span>
            </ng-template>
        </div>
    </div>
    <textarea [ngClass]="this.array.length != this.max ? 'visible' : 'hidden'" #inp_value class="form-control flex-grow-1 border-0" [name]="name" [attr.placeholder]="getPlaceholder()" rows="1" cols="1" (keyup.enter)="add(inp_value, c)" (keydown.backspace)="del_last(inp_value)"
        (paste)="pasteValues($event, inp_value)" [(ngModel)]="newValue"></textarea>
</div> -->


<!-- <ng-template ngFor let-val [ngForOf]="array" let-i="index"></ng-template> -->

<!-- <button (click)="print()">--</button> -->

<!-- DRAG & DROP -->
<!-- <div id="timeline" cdkDropList cdkDropListOrientation="horizontal" (cdkDropListDropped)="drop($event)" class="d-flex flex-row flex-wrap border rounded w-100 inpt-select text-left example-list" (click)="setFocus()">
    <div *ngFor="let val of array" class="border px-1 rounded text-muted mr-1 mb-1 d-flex flex-row example-box" cdkDrag>
        <ng-template [ngIf]="type == 0">
            <a class="px-1 mr-1" href="#" (click)="$event.preventDefault(); del(val)">
                <i class="fas fa-times fa-xs"></i>
            </a>
            <span [title]="val" [innerHTML]="val"></span>
        </ng-template>
        <ng-template [ngIf]="type == 1">
            <a class="px-1 mr-1" href="#" (click)="$event.preventDefault(); del(val.value)">
                <i class="fas fa-times fa-xs"></i>
            </a>
            <span [title]="val.value" [innerHTML]="val.value"></span>
        </ng-template>
    </div>
    <textarea [ngClass]="this.array.length != this.max ? 'visible' : 'hidden'" #inp_value class="form-control flex-grow-1 border-0" [name]="name" [attr.placeholder]="getPlaceholder()" rows="1" cols="1" (keyup.enter)="add(inp_value, c)" (keydown.backspace)="del_last(inp_value)"
        (paste)="pasteValues($event, inp_value)" [(ngModel)]="newValue"></textarea>
</div> -->

<!-- DRAGULA -->
<!-- [dragula]="nameDrag" [(dragulaModel)]="array" -->
<div id="timeline" class="d-flex flex-row flex-wrap border rounded w-100 inpt-select text-left list" (click)="setFocus()">
    <div *ngFor="let val of array; let i = index" class="border px-1 rounded text-muted mr-1 mb-1 d-flex flex-row box">
        <ng-template [ngIf]="type == 0">
            <a class="px-1 mr-1" href="#" (click)="$event.preventDefault(); del(val)">
                <i class="fas fa-times fa-xs"></i>
            </a>
            <strong [innerHTML]="getMask(i)" *ngIf="mask.length != 0"></strong>
            <span [title]="val" [innerHTML]="val"></span>
        </ng-template>
        <ng-template [ngIf]="type == 1">
            <a class="px-1 mr-1" href="#" (click)="$event.preventDefault(); del(val.value)">
                <i class="fas fa-times fa-xs"></i>
            </a>
            <span [title]="val.value" [innerHTML]="val.value"></span>
        </ng-template>
    </div>
    <textarea [ngClass]="array.length != max ? 'visible' : 'hidden'" #inp_value class="form-control flex-grow-1 border-0" [name]="name" [attr.placeholder]="getPlaceholder()" rows="1" cols="1" (keyup.enter)="add(inp_value, true)" (keydown.backspace)="del_last(inp_value)"
        (paste)="pasteValues($event, inp_value)" [(ngModel)]="newValue" (blur)="add(inp_value, false)"></textarea>
</div>
<!-- <br>
<br>
<br>
<div class="example-container" cdkDropListGroup #list_group>
    <div cdkDropList [cdkDropListEnterPredicate]="enter" (cdkDropListDropped)="drop()" #placeholder_list></div>
    <div cdkDropList *ngFor="let item of items" [cdkDropListEnterPredicate]="enter" (cdkDropListDropped)="drop()">
        <div cdkDrag class="example-box">{{item}}</div>
    </div>
</div> -->

<!-- [ngIfElse]="else_current" -->