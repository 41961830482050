<label *ngIf="formGroup" [formGroup]="formGroup">
    <input type="hidden" class="form-control" [formControlName]="fControlName">
</label>
<input *ngIf="!formGroup" #inp_hd="ngModel" type="hidden" class="form-control" [(ngModel)]="_value"
    [required]="required">
<div *ngIf="!_value" class="input-group typeahead-input">
    <input type="text" class="form-control" *ngIf="!isMobile"
        [ngClass]="{'oh_prtx_invalid': required, 'input-lg' : (size && size == 'lg')}" [name]="name"
        [required]="required" [(ngModel)]="_ptValue" (ngModelChange)="onSelect($event)"
        #inp_predictiveText="ngbTypeahead" [ngbTypeahead]="onSearchEvent" [resultFormatter]="customFormatter"
        [resultTemplate]="resultTemplate" [inputFormatter]="templateFormatter" placeholder="{{placeholder}}"
        [disabled]="isMobile || disabled || readonly" [readonly]="readonly" container="body"
        (focus)="focus$.next($any($event).target.value); onClick($event)"
        (click)="click$.next($any($event).target.value)" />
    <div *ngIf="isSearching" class="inpt-onSearching">
        <span class="badge badge-light p-1">Searching... <i class="fas fa-spinner fa-w-16 fa-spin fa-lg"></i></span>
    </div>
    <input type="text" class="form-control" *ngIf="isMobile" [disabled]="true" [required]="required"
        [ngClass]="{'oh_prtx_invalid': required, 'input-lg' : (size && size == 'lg')}" placeholder="{{placeholder}}">
    <div class="input-group-append" *ngIf="isMobile">
        <button class="btn btn-outline-secondary" type="button" (click)="openModalSearch(modalSearch)"
            [disabled]="disabled"><i class="fas fa-search"></i></button>
    </div>
</div>
<div *ngIf="_value" class="w-100 oh_prtx_selected text-left"
    [ngClass]="{'inpt-disabled': disabled || readonly, 'oh_prtx_valid' : (required || isValid)}">
    <div class="text-muted" [ngClass]="{'content-readonly' : readonly}">
        <a class="px-1 mr-1" href="#" (click)="$event.preventDefault(); clearSelection()" *ngIf="!readonly">
            <i class="fas fa-times fa-xs"></i>
        </a>
        <span *ngIf="!isObserver" [ngbTooltip]="getMappedValues(_ptValue)"
            container="body">{{getMappedValues(_ptValue)}}</span>
        <span *ngIf="isObserver" [ngbTooltip]="getMappedValues(_ptObsValue)"
            container="body">{{getMappedValues(_ptObsValue)}}</span>
    </div>
</div>
<ng-template #modalSearch let-c="close" let-d="dismiss">
    <div class="modal-header">
        <h5 class="modal-title">Search</h5>
        <a href="#" class="close text-dark" (click)="$event.preventDefault(); d('Cross click')"><i
                class="fas fa-times fa-xs"></i></a>
    </div>
    <div class="modal-body">
        <div class="typeahead-input">
            <input type="text" id="inp_predictiveText" class="form-control"
                [ngClass]="{'ng-invalid': (required && (_value == null || _value == undefined)), 'ng-valid': required && _value}"
                [name]="name" [required]="required" [(ngModel)]="_ptValue" (ngModelChange)="onSelect($event, c)"
                #inp_predictiveText="ngbTypeahead" [ngbTypeahead]="onSearchEvent" [resultFormatter]="customFormatter"
                [resultTemplate]="resultTemplate" [inputFormatter]="templateFormatter" placeholder="{{placeholder}}"
                [disabled]="disabled" [readonly]="readonly" container="body"
                (focus)="focus$.next($any($event).target.value); onClick($event)"
                (click)="click$.next($any($event).target.value)" />
            <div *ngIf="isSearching" class="inpt-onSearching">
                <span class="badge badge-light p-1">Searching... <i
                        class="fas fa-spinner fa-w-16 fa-spin fa-lg"></i></span>
            </div>
        </div>
    </div>
</ng-template>