import { Component, OnInit, Injectable, Input } from '@angular/core';
import { NgbModal, NgbActiveModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { TranslocoService } from '@ngneat/transloco';

import * as XLSX from 'xlsx';

/*
	this.ohService.getOH().getUtil().confirm("Desea ver el ejemplo?",() => {
		alert("se cerro");
	});

	this.ohService.getOH().getUtil().confirm("Desea ver el ejemplo?",() => {
		alert("se cerro");
	},(tipomessage) => {
		alert(tipomessage); // ESC | BACK
	});

	this.ohService.getOH().getUtil().confirm("Desea ver el ejemplo?",() => {
		//alert("se cerro");
	},(tipomessage) => {
		//alert(tipomessage);  ESC | BACK | CLOSE | CANCEL
	}, {
		title : "CLOSE sessión",
		btnAccept : "CLOSE",
		btnAcceptIcon : "fa fa-sign-out",
		btnAcceptBack : "btn btn-outline-danger",
		btnCancel : "Return",
		btnCancelIcon : "fa fa-mail-reply",
		btnCancelBack : "btn btn-outline-dark",
	});
*/
@Component({
	selector: 'oh-confirmationModal',
	templateUrl: './oh.confirmationModal.html'
})
export class ConfirmationModal implements OnInit {

	@Input() options: any;
	_options: any;
	@Input() message: string;

	langSubs: any

	constructor(public activeModal: NgbActiveModal, private translocoService: TranslocoService) {

		this._options = {
			title: "Confirmación",
			btnAccept: "Aceptar",
			btnAcceptIcon: "far fa-hand-point-up",
			btnAcceptBack: "btn btn-success",
			btnCancel: "Retornar",
			btnCancelIcon: "fas fa-reply",
			btnCancelBack: 'btn btn-outline-info'
		}


	}

	ngOnInit() {

		if (!this.options) {
			this.options = {};
		}

		this.langSubs = this.translocoService.selectTranslateObject('component.modalConfirm').subscribe((valor) => {
			this._options.title = valor.title
			this._options.btnAccept = valor.btnAccept
			this._options.btnCancel = valor.btnCancel
			Object.assign(this._options, this.options);
		});

	}

	ngOnDestroy() {
		if (this.langSubs) {
			this.langSubs.unsubscribe()
		}
	}

	ngAfterViewInit() {
		document.getElementById("btn_confirm").focus();
	}

}

@Injectable()
export class Util {

	CONFIRM_ESC: string = "ESC";
	CONFIRM_BACK: string = "BACK";
	CONFIRM_CLOSE: string = "CLOSE";
	CONFIRM_CANCEL: string = "CANCEL";

	constructor(private servicioModal: NgbModal) { };

	confirm(message: string, callOk: Function, callReturn?: Function, options?: any) {

		var scrollActual = document.documentElement.scrollTop;

		const modalRef = this.servicioModal.open(ConfirmationModal, options);
		modalRef.componentInstance.options = options;
		modalRef.componentInstance.message = message;
		modalRef.result.then((result) => {
			document.documentElement.scrollTop = scrollActual;
			if (result == "ACCEPT") {
				callOk(true);
			} else {
				if (callReturn) {
					callReturn(result);
				}
			}
		}, (reason) => {
			document.documentElement.scrollTop = scrollActual;
			if (callReturn) {
				callReturn(this.getDismissReason(reason));
			}
		});
	}

	private getDismissReason(reason: any): string {
		if (reason === ModalDismissReasons.ESC) {
			return 'ESC';
		} else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
			return 'BACK';
		} else {
			return reason;
		}
	}

	// ["A","B","C"] => {0 : "A",1 : "B",1 : "C"}
	private listToObject(fields: any) {
		let fieldsInd = {};
		if (fields) {
			for (var ind in fields) {
				fieldsInd[ind] = fields[ind];
			}
		}
		return fieldsInd;
	}

	// 1er. [[123,"ABC"],[456,"DEF"]] , ["codigo","nombre"] => [{codigo : 123,nombre : "ABC"},{codigo : 456,nombre : "DEF"}]
	// 2do. [[123,"abc",true],[[4,"a"],[5,"b"],[6,"c"]]] , [["codigo","nombre","estado"],["codigo","valor"]] => 
	// [{codigo : 123,nombre : "ABC",estado : true},[{codigo : 4,valor : "a"},{codigo : 5,valor : "b"},{codigo : 6,valor : "c"}]]
	// 3ro. ["YG4409", "201", "S.A."], ["plate", "companyId", "name"] => {plate : YG4409, companyId : "201", name : "S.A."}
	public getObjet(list: any, fields: any) {
		let entity: any, result: any;
		if (list) {
			if (typeof (list.length) == "number" && list.length == 0) {
				return null;
			}
			if (!this._hasChildList(fields)) {
				if (this._hasChildList(list)) { // Fixed for arrays
					result = this._getlistsToObjets(list, fields);
				} else {
					result = this._getlistToObjet(list, fields);
				}
			} else {
				result = [];
				for (var i in fields) {
					if (list[i]) {
						if (this._hasChildList(list[i])) {
							result[i] = this._getlistsToObjets(list[i], fields[i]);
						} else {
							result[i] = this._getlistToObjet(list[i], fields[i]);
						}
					} else {
						result[i] = {};
					}
				}
			}
		}
		return result;
	}

	// [1,"inlandnet","Portal"] // false
	// [[1,"b"],[2,"c"]] // true
	private _hasChildList(list: any) { // Si algun atributo es lista retorna true
		return (typeof (list[0]) == "object") ? true : false;
	}

	// [123,"ABC"] , ["codigo","nombre"] => {codigo : 123,nombre : "ABC"}
	private _getlistToObjet(list: any, fields: any) {
		let entity: any = {};
		for (var e = 0; e < list.length; e++) {
			entity[fields[e]] = list[e];
		}
		return entity;
	}

	// [[123,"ABC"],[456,"DEF"]] , ["codigo","nombre"] => [{codigo : 123,nombre : "ABC"},{codigo : 456,nombre : "DEF"}]
	private _getlistsToObjets(list: any, fields: any) {
		let result: any = [];
		for (var i = 0; i < list.length; i++) {
			result.push(this._getlistToObjet(list[i], fields));
		}
		return result;
	}

	// [{codigo : 123,nombre : "ABC"},{codigo : 456,nombre : "DEF"}] => [[123,"ABC"],[456,"DEF"]] , ["codigo","nombre"]
	public getlist(objetos: any) {
		let items: any, result: any;
		if (objetos) {
			result = [];
			for (let objeto of objetos) {
				items = [];
				for (let item in objeto) {
					items.push(objeto[item]);
				}
				result.push(items);
			}
		}
		return result;
	}

	// (firstDate, AnotherDate) => Return difference in text description like 2 minutes, 10 Hours
	public getDateDesc(serverDate: Date, itemDate: Date, languageDescription?: any) {

		if (serverDate != null && itemDate != null) {

			if (typeof (serverDate) == "number") {
				serverDate = new Date(serverDate);
			}
			if (typeof (itemDate) == "number") {
				itemDate = new Date(itemDate);
			}

			let diff = (serverDate.getTime() - itemDate.getTime()) / 1000;

			if (diff > 60) {
				if (diff < 3600) {
					return Math.round(Math.floor((diff / 60))) + " " + (languageDescription ? languageDescription.minutes : "Minute(s)")
				} else if (diff < 86400) {
					return Math.round(Math.floor((diff / (60 * 60)))) + " " + (languageDescription ? languageDescription.hours : "Hour(s)")
				} else if (diff < 86400 * 30) {
					return Math.round(Math.floor((diff / (60 * 60 * 24)))) + " " + (languageDescription ? languageDescription.days : "Day(s)")
				} else if (diff < 86400 * 30 * 12) {
					return Math.round(Math.floor((diff / (60 * 60 * 24 * 30)))) + " " + (languageDescription ? languageDescription.months : "Month(s)")
				} else {
					return Math.round(Math.floor((diff / (60 * 60 * 24 * 30 * 12)))) + " " + (languageDescription ? languageDescription.years : "Year(s)")
				}
			} else {
				return "Now";
			}

		}
	}

	// (firstDate, AnotherDate, limitLightInfo, limitLightWarning) 
	// => Return icon css style if difference of dates is between the limits values
	// like Fri Feb 16 2018 09:27:46, Fri Feb 16 2018 09:30:46 difference 3 minutos 
	// limitInfo 10 minutes, limitWarning 15 minutes, 3 is between 0 an 10 so is Info
	public getLight(serverDate: Date, itemDate: Date, milW: number, milD: number) {
		if (serverDate != null && itemDate != null) {

			if (typeof (serverDate) == "number") {
				serverDate = new Date(serverDate);
			}
			if (typeof (itemDate) == "number") {
				itemDate = new Date(itemDate);
			}

			let diff = (serverDate.getTime() - itemDate.getTime()) / 1000;

			if (diff < milW) {
				return "badge badge-info"; // badge-success
			} else if (diff > milW && diff < milD) {
				return "badge badge-warning";
			} else {
				return "badge badge-danger";
			}
		}
	}

	public containExact(text: string, searches: any) {
		if (typeof (searches) == "string") {
			return (text.indexOf(text) !== -1) ? true : false;
		} else if (typeof (searches) == "object") {
			let indic = 0;
			for (var i in searches) {
				if (text.indexOf(searches[i]) !== -1) {
					indic++;
				}
			}
			return (indic > 0) ? true : false;
		} else {
			return false;
		}
	}

	public contain(text: string, searches: any) {
		if (typeof (searches) == "string") {
			searches = searches.toLowerCase();
		} else if (typeof (searches) == "object") {
			for (var i in searches) {
				searches[i] = searches[i].toLowerCase();
			}
		}
		return this.containExact(text.toLowerCase(), searches);
	}

	public isDate(date: any) {
		return Object.prototype.toString.call(date) === '[object Date]' ? true : false;
	}

	base64strToBlog(b64Data, contentType?, sliceSize?) {
		contentType = contentType || '';
		sliceSize = sliceSize || 512;

		var byteCharacters = atob(b64Data);
		var byteArrays = [];

		for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
			var slice = byteCharacters.slice(offset, offset + sliceSize);

			var byteNumbers = new Array(slice.length);
			for (var i = 0; i < slice.length; i++) {
				byteNumbers[i] = slice.charCodeAt(i);
			}

			var byteArray = new Uint8Array(byteNumbers);

			byteArrays.push(byteArray);
		}
		return new Blob(byteArrays, { type: contentType });
	}

	public pad(s) { return (s < 10) ? '0' + s : s; }

	public dateToString(inputFormat) {
		if (inputFormat) {
			var d = (typeof (inputFormat) == "string") ? new Date(inputFormat) : inputFormat;
			return [this.pad(d.getDate()), this.pad(d.getMonth() + 1), d.getFullYear()].join('/');
		} else {
			return null;
		}
	}

	public dateToDocumentFormat(inputFormat) {
		if (inputFormat) {
			var d = (typeof (inputFormat) == "string") ? new Date(inputFormat) : inputFormat;
			let month: string = ""
			let weekDay: string = ""
			switch (d.getMonth() + 1) {
				case (1):
					month = 'enero'
					break;
				case (2):
					month = 'febrero'
					break;
				case (3):
					month = 'marzo'
					break;
				case (4):
					month = 'abril'
					break;
				case (5):
					month = 'mayo'
					break;
				case (6):
					month = 'junio'
					break;
				case (7):
					month = 'julio'
					break;
				case (8):
					month = 'agosto'
					break;
				case (9):
					month = 'setiembre'
					break;
				case (10):
					month = 'octubre'
					break;
				case (11):
					month = 'noviembre'
					break;
				case (12):
					month = 'diciembre'
					break;

				default:
					month = 'invalid month'
					break;
			}
			switch (d.getDay()) {
				case (1):
					weekDay = 'domingo'
					break;
				case (2):
					weekDay = 'lunes'
					break;
				case (3):
					weekDay = 'martes'
					break;
				case (4):
					weekDay = 'miercoles'
					break;
				case (5):
					weekDay = 'jueves'
					break;
				case (6):
					weekDay = 'viernes'
					break;
				case (7):
					weekDay = 'sabado'
					break;


				default:
					weekDay = 'invalid weekDay'
					break;
			}
			let text: string = weekDay + ' , ' + d.getDate() + ' de ' + month + ' de ' + d.getFullYear()

			return text;
		} else {
			return null;
		}
	}

	public dateToStringTwo(inputFormat) {
		if (inputFormat) {
			var d = (typeof (inputFormat) == "string") ? new Date(inputFormat) : inputFormat;
			return [d.getFullYear(), this.pad(d.getMonth() + 1), this.pad(d.getDate())].join('/');
		} else {
			return null;
		}
	}

	public dateToStringDayMonthYear(inputFormat) {
		if (inputFormat) {
			var d = (typeof (inputFormat) == "string") ? new Date(inputFormat) : inputFormat;
			return [
				this.pad(d.getDate()),
				this.pad(d.getMonth() + 1),
				d.getFullYear(),
			].join('/');
		} else {
			return null;
		}
	}

	public dateTimeToString(inputFormat, format?: string) {
		if (inputFormat) {
			var d = (typeof (inputFormat) == "string") ? new Date(inputFormat) : inputFormat;
			var fecha;
			if (!format || format == 'ISO') {
				fecha = [this.pad(d.getDate()), this.pad(d.getMonth() + 1), d.getFullYear()].join('/');
			} else if (format == "SQL") {
				fecha = [
					d.getFullYear(),
					this.pad(d.getMonth() + 1),
					this.pad(d.getDate())
				].join('/');
			}
			return fecha + " " + [this.pad(d.getHours()), this.pad(d.getMinutes()), this.pad(d.getSeconds())].join(':');
		} else {
			return null;
		}
	}


	public dateNgbToString(d: any) {
		if (d) {
			return [this.pad(d.day), this.pad(d.month), d.year].join('/');
		} else {
			return null;
		}
	}

	public dateNgbManifiestoToString(d: any) {
		if (d) {
			var fecha = d.year + this.pad(d.month) + this.pad(d.day)
			return fecha;
		} else {
			return null;
		}
	}

	public dateToNgb(d: any) {
		if (d) {
			d = (typeof (d) == "string") ? new Date(d) : d;
			return {
				year: d.getFullYear(),
				month: d.getMonth() + 1,
				day: d.getDate()
			};
		} else {
			return null;
		}
	}

	public dateToNgbTime(d: any) {
		if (d) {
			d = (typeof (d) == "string") ? new Date(d) : d;
			return {
				hour: d.getHours(),
				minute: d.getMinutes()
			};
		} else {
			return null;
		}
	}

	// ("dd/mm/yyyy" or "dd-mm-yyyy") to {year:,month:,day:}
	public dateToNgbTwo(d: any) {
		if (d && typeof (d) == "string") {
			if (d.indexOf('-') != -1) {
				d = new Date(d);
			} else {
				let dateParts: any = d.split("/");
				d = new Date(+dateParts[2], dateParts[1] - 1, +dateParts[0]);
			}
			return {
				year: d.getFullYear(),
				month: d.getMonth() + 1,
				day: d.getDate()
			};
		} else {
			return null;
		}
	}

	// ("dd/mm/yyyy hh:mm:ss") to new Date()
	public dateToNgbThree(d: any) {
		let new_date = null;
		if (d && typeof (d) == "string") {
			if (d.indexOf('-') != -1) {
				// d = new Date(d);
			} else {
				let datetimeParts: any = d.split(" ");
				let dateParts: any = datetimeParts[0].split("/");
				let timeParts: any = [];
				if (datetimeParts[1]) {
					timeParts = datetimeParts[1].split(":");
				}
				new_date = new Date(
					(+dateParts[2]),
					(dateParts[1] - 1),
					(+dateParts[0]),
					(isNaN(+timeParts[0]) ? 0 : +timeParts[0]),
					(isNaN(+timeParts[1]) ? 0 : +timeParts[1]),
					(isNaN(+timeParts[2]) ? 0 : +timeParts[2]),
				);
			}
			return new_date
		} else {
			return new_date
		}
	}

	// ("dd/mm/yyyy hh:mm:ss") to new Date()
	public dateToNgbThree2(d: any) {
		let new_date = null;
		if (d && typeof (d) == "string") {
			if (d.indexOf('-') != -1) {
				// d = new Date(d);
			} else {
				let datetimeParts: any = d.split(" ");
				let dateParts: any = datetimeParts[0].split("-");
				let timeParts: any = [];
				if (datetimeParts[1]) {
					timeParts = datetimeParts[1].split(":");
				}
				new_date = new Date(
					(+dateParts[2]),
					(dateParts[1] - 1),
					(+dateParts[0]),
					(isNaN(+timeParts[0]) ? 0 : +timeParts[0]),
					(isNaN(+timeParts[1]) ? 0 : +timeParts[1]),
					(isNaN(+timeParts[2]) ? 0 : +timeParts[2]),
				);
			}
			return new_date
		} else {
			return new_date;
		}
	}

	public dateStringtoDate(_date: string) {

		if (_date) {

			if (typeof (_date) == "number") {

				return new Date(_date)

			}

			if (typeof (_date) == "string") {

				if (_date.length > 10) {

					return new Date(_date)

				} else {

					var _d = _date.split("-");

					return new Date(Number(_d[0]), Number(_d[1]) - 1, Number(_d[2]))

				}

			}

		}

		return null

	}

	public round(value: number, precition?: number) {
		if (value) {
			if (!precition) {
				precition = 2;
			}
			return Math.round(value * Math.pow(10, precition)) / Math.pow(10, precition);
		} else {
			return 0;
		}
	}

	public objectIdToStringJoin(cabeceras: any): string {
		var padres = [];
		for (var key in cabeceras) {
			padres.push(key);
		}
		return padres.join();
	}

	public getCatalog(catalogos: any, cabeceras: any): any {
		var catalogoFormat = {};
		for (var keyA in cabeceras) {
			var temp1 = [];
			for (var keyB in catalogos) {
				if (keyA == catalogos[keyB].catalogo_padre_id) {
					temp1.push({
						id: catalogos[keyB].catalogo_id,
						value: catalogos[keyB].descripcion
					});
				}
				catalogoFormat[cabeceras[keyA]] = temp1;
			}
		}
		return catalogoFormat;
	}

	// [{id: 1, idUN: 1}, {id: 2, idUN: 2}, {id: 3, idUN: 2}] ->
	public getJoinFielByFind(items: any, joinField: string, searchField: string, search: any): string {
		var filtered = items.filter(item => item[searchField] == search);
		var keys = [];
		for (var key in filtered) {
			keys.push(filtered[key][joinField]);
		}
		return keys.join();
	}

	// name : string (title) , obj =  {}

	public getXMLString(elements: any, name: string) {
		var xmls = [];
		elements.forEach(element => {
			xmls.push(this.titleXML(name, element));
		});
		return xmls.join("");
	}

	public titleXML(name: any, obj: any) {
		var xml = '';
		xml += "<" + name + ">";
		xml += this.objectToXml(obj);
		xml += "</" + name + ">";
		return xml;
	}

	public objectToXml(obj: any) {
		var xml = '';

		for (var prop in obj) {
			if (!obj.hasOwnProperty(prop)) {
				continue;
			}

			if (obj[prop] == undefined)
				continue;

			xml += "<" + prop + ">";
			if (typeof obj[prop] == "object")
				xml += this.objectToXml(new Object(obj[prop]));
			else
				xml += obj[prop];

			xml += "</" + prop + ">";
		}
		return xml;
	}

	public dateToFormatParticular(d: any) {
		var months = ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre']

		return d.day + " " + months[d.month - 1] + " " + d.year

	}

	public listToIndex(list: any, by: string, field: string) {
		var newObject = {};
		for (var i in list) {
			newObject[list[i][by]] = list[i][field];
		}
		return newObject;
	}

	public paginateArray(array, page_size, page_number) {
		--page_number;
		return array.slice(page_number * page_size, (page_number + 1) * page_size);
	}

	public StringXMLtoJSON(xml: string) {
		var getxml = new DOMParser();
		var xmlDoc = getxml.parseFromString(xml, "text/xml");
		var json_str = this.jsontoStr(this.setJsonObj(xmlDoc));
		return JSON.parse(json_str);
	}

	public StringXMLtoJSONList(xml: string) {
		var lista = [];
		if (xml) {
			let base = "data";
			var objeto = this.StringXMLtoJSON("<" + base + ">" + xml + "</" + base + ">");
			if (!(objeto[base].row.length >= 0)) {
				objeto[base].row = [objeto[base].row];
			}
			for (var item of objeto[base].row) {
				var lista_elemento = {};
				for (var element in item) {
					lista_elemento[element] = item[element]["#text"];
				}
				lista.push(lista_elemento);
			}
		}
		return lista;
	}



	// converts JSON object to string (human readablle).
	// Removes '\t\r\n', rows with multiples '""', multiple empty rows, '  "",', and "  ",; replace empty [] with ""
	private jsontoStr(js_obj) {
		var rejsn = JSON.stringify(js_obj, undefined, 2).replace(/(\\t|\\r|\\n)/g, '').replace(/"",[\n\t\r\s]+""[,]*/g, '').replace(/(\n[\t\s\r]*\n)/g, '').replace(/[\s\t]{2,}""[,]{0,1}/g, '').replace(/"[\s\t]{1,}"[,]{0,1}/g, '').replace(/\[[\t\s]*\]/g, '""');
		return (rejsn.indexOf('"parsererror": {') == -1) ? rejsn : 'Invalid XML format';
	}

	// receives XML DOM object, returns converted JSON object
	private setJsonObj(xml) {
		var js_obj = {};
		if (xml.nodeType == 1) {
			if (xml.attributes.length > 0) {
				js_obj["@attributes"] = {};
				for (var j = 0; j < xml.attributes.length; j++) {
					var attribute = xml.attributes.item(j);
					js_obj["@attributes"][attribute.nodeName] = attribute.value;
				}
			}
		} else if (xml.nodeType == 3) {
			js_obj = xml.nodeValue;
		}
		if (xml.hasChildNodes()) {
			for (var i = 0; i < xml.childNodes.length; i++) {
				var item = xml.childNodes.item(i);
				var nodeName = item.nodeName;
				if (typeof (js_obj[nodeName]) == "undefined") {
					js_obj[nodeName] = this.setJsonObj(item);
				} else {
					if (typeof (js_obj[nodeName].push) == "undefined") {
						var old = js_obj[nodeName];
						js_obj[nodeName] = [];
						js_obj[nodeName].push(old);
					}
					js_obj[nodeName].push(this.setJsonObj(item));
				}
			}
		}
		return js_obj;
	}

	public getUID() {
		return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
			var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
			return v.toString(16);
		});
	}

	public getJSONtoFile(json_texto: any, nombre_archivo?: string) {
		var f_blob = new Blob([JSON.stringify(json_texto)], { type: "application/json" });
		return new File([f_blob], ((nombre_archivo) ? nombre_archivo : "archivo"));
	}

	public getDistance(p1_lat, p1_lng, p2_lat, p2_lng) {
		var R = 6378137;
		var dLat = this.getRadio(p2_lat - p1_lat);
		var dLong = this.getRadio(p2_lng - p1_lng);
		var a = Math.sin(dLat / 2) * Math.sin(dLat / 2) + Math.cos(this.getRadio(p1_lat)) * Math.cos(this.getRadio(p2_lat)) * Math.sin(dLong / 2) * Math.sin(dLong / 2);
		var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
		var d = R * c;
		return d;
	}

	private getRadio(x: number) {
		return x * Math.PI / 180;
	};

	public keyEvent($event: any, key: string, call: any) {
		if (key == "ctrl+s") {
			if (navigator.platform.match('Mac')) {
				this.handleMacKeyEvents($event, call);
			} else {
				this.handleWindowsKeyEvents($event, call);
			}
		}
	}

	private handleMacKeyEvents($event, call: any) {
		if ($event.metaKey && String.fromCharCode($event.which).toLowerCase() === 's') {
			$event.preventDefault();
			call();
		}
	}

	private handleWindowsKeyEvents($event, call: any) {
		if ($event.ctrlKey && String.fromCharCode($event.which).toLowerCase() === 's') {
			$event.preventDefault();
			call();
		}
	}

	toExcel(data: any, fields: any, name: string) {

		const format = [];

		for (var item of data) {

			let _obj = {}

			/*
				_field : string
				_field : object {title : string, format : string}
				_field : object {title : string, function : callback}
			*/

			for (var _field in fields) {

				if (typeof fields[_field] == "string") {
					_obj[fields[_field]] = item[_field]
				}

				if (typeof fields[_field] == "object") {
					if (fields[_field].format && fields[_field].format == "Date") {
						_obj[fields[_field].title] = this.dateToString(item[_field])
					}

					if (fields[_field].format && fields[_field].format == "DateTime") {
						_obj[fields[_field].title] = this.dateTimeToString(item[_field])
					}

					if (fields[_field].function) {
						_obj[fields[_field].title] = fields[_field].function(item[_field], item)
					}

				}

			}

			format.push(_obj)

		}

		this.exportAsExcelFile(format, name)

	}

	private exportAsExcelFile(json: any[], fileName: string): void {
		const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json, { dateNF: "YYYY-MM-DD HH:mm:ss" });
		const workbook: XLSX.WorkBook = { Sheets: { 'Data': worksheet }, SheetNames: ['Data'] };
		XLSX.writeFile(workbook, fileName + '.xlsx', { compression: true });
	}

	encodeHTML(text: string) {
		return text.replace(/&/g, '&amp;')
			.replace(/</g, '&lt;')
			.replace(/>/g, '&gt;')
			.replace(/"/g, '&quot;')
			.replace(/'/g, '&apos;');
	}

	public loadScript(script: string) {
		let _script = script.split("/")
		let _id = _script[_script.length - 1]
		new Promise<void>((resolve, reject) => {
			if (document.getElementById(_id)) {
				resolve()
			} else {
				let node = document.createElement('script')
				node.src = script
				node.id = _id
				node.type = 'text/javascript'
				node.async = false
				node.onload = function () {
					resolve()
				}
				//node.charset = 'utf-8';
				document.getElementsByTagName('head')[0].appendChild(node)
			}
		})
	}

	public loadCSS(file: string) {
		let _script = file.split("/")
		let _id = _script[_script.length - 1]
		new Promise<void>((resolve, reject) => {
			if (document.getElementById(_id)) {
				resolve()
			} else {
				let node = document.createElement('link')
				node.href = file
				node.rel = 'stylesheet'
				node.id = _id
				node.type = 'text/css'
				node.onload = function () {
					resolve()
				}
				document.getElementsByTagName('head')[0].appendChild(node)
			}
		})
	}

	/*
	stringToDate("30/08/2020","dd/MM/yyyy","/");
	stringToDate("08/30/2020","mm/dd/yyyy","/")
	stringToDate("08-30-2020","mm-dd-yyyy","-")
		*/
	public stringToDate(_date, _format, _delimiter) {
		var formatLowerCase = _format.toLowerCase();
		var formatItems = formatLowerCase.split(_delimiter);
		var dateItems = _date.split(_delimiter);
		var monthIndex = formatItems.indexOf("mm");
		var dayIndex = formatItems.indexOf("dd");
		var yearIndex = formatItems.indexOf("yyyy");
		var month = parseInt(dateItems[monthIndex]);
		month -= 1;
		var formatedDate = new Date(dateItems[yearIndex], month, dateItems[dayIndex]);
		return formatedDate;
	}

	//f1 y f2 = 10/02/2021 10:30:00
	public restaFechas(f1, f2) {
		if (f1 == null || f2 == null) {
			return null;
		}

		var aFecha1 = f1.substring(0, 10).split('/');
		var aFecha2 = f2.substring(0, 10).split('/');
		var fFecha1 = Date.UTC(aFecha1[2], aFecha1[1] - 1, aFecha1[0]);
		var fFecha2 = Date.UTC(aFecha2[2], aFecha2[1] - 1, aFecha2[0]);
		var dif = fFecha2 - fFecha1;
		var dias = Math.floor(dif / (1000 * 60 * 60 * 24));
		return dias;
	}

	public isNumeric(n) {
		return !isNaN(parseFloat(n)) && isFinite(n);
	}

	public isInteger(expression) {
		return (String(expression).search(/^\d+$/) != -1);
	}

	public IsDecimal(expression) {
		return (String(expression).search(/^\d+(\.\d+)?$/) != -1);
	}

	public getFechaHora(): string {
		var currentdate = new Date();
		var datetime = currentdate.getDate() + "/"
			+ (currentdate.getMonth() + 1) + "/"
			+ currentdate.getFullYear() + " "
			+ currentdate.getHours() + ":"
			+ currentdate.getMinutes() + ":"
			+ currentdate.getSeconds();

		return datetime;
	}

	// Input :
	// /MAERSK_TC360_DESA/AGP/
	// /MAERSK_TC360_DESA/AGP
	// MAERSK_TC360_DESA/AGP/
	// Output :
	// MAERSK_TC360_DESA/AGP/6125f8dc-3f2a-4a6c-989c-a6f86fb62de4
	public mapRealRoute(env_collection: string, route) {

		var _ruta: any = {
			folder: ""
		}
		var _routes = ""
		if (route && route.length > 0) {
			_routes = route.split("/").filter(it => it != '').join("/")
		}

		if (_routes.indexOf(env_collection) == 0) {
			var rutas = _routes.split("/")
			rutas.splice(0, 1)
			_ruta.container = rutas[0].toLowerCase()
			if (rutas.length >= 2) {
				rutas.splice(0, 1)
				_ruta.folder = rutas.join("/") + "/"
			}

		} else {
			var rutas = _routes.split("/")
			_ruta.container = rutas[0].toLowerCase()
			if (rutas.length >= 2) {
				rutas.splice(0, 1)
				_ruta.folder = rutas.join("/") + "/"
			}
		}

		return _ruta

	}

}

export class JpoError {
	error: any
	message: string
	rnd: number
};