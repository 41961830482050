import { Directive, Input } from '@angular/core';
import { CoreService } from 'src/app/ind.coreService';

// <input type="text" ... ohOnBeforeUnloadAlert ...>

@Directive({
    selector: '[ohOnBeforeUnloadAlert]'
})

export class OnBeforeUnloadAlert {

    @Input()
    set onRemoveBeforeUnload(isRemoved: boolean) {
        if (isRemoved) {
            this.removeBeforeUnload()
        }
    }

    constructor(public cse: CoreService) {
    }

    promptIfSureToRedirect(event) {
        event.preventDefault();
        event.returnValue = 'Changes you made may not be saved.';
        return event;
    }

    ngAfterViewInit() {
        this.cse.data.onBeforeUnload = true
        window.addEventListener('beforeunload', this.promptIfSureToRedirect);
    }

    ngOnDestroy() {
        this.removeBeforeUnload()
    }

    private removeBeforeUnload() {
        this.cse.data.onBeforeUnload = false
        window.removeEventListener('beforeunload', this.promptIfSureToRedirect);
    }

}