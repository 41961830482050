import { Component, OnInit, Input, SimpleChanges } from '@angular/core';

@Component({
	selector: 'oh-circleProgress',
	templateUrl: './oh.circleProgress.html',
	styleUrls: ['./oh.circleProgress.scss']
})
export class CircleProgress implements OnInit {

	@Input() isBig: boolean = false;
	@Input() value: number = 0;
	@Input() color: string = 'blue';
	//a tiempo = inTime, sobre e ltiempo = onTime , fuera de tiempo = outTime
	@Input() config: any = { inTime: 50, onTime: 100 }

	value_rounded: any
	value_rounded_css : string
	classes = [];

	constructor() { }

	ngOnInit() {

		if (this.value < this.config.inTime) {
			this.color = 'red'
		} else if (this.value > this.config.inTime && this.value < this.config.onTime) {
			this.color = 'orange'
		} else {
			this.color = 'green'
		}

		this.value_rounded = Math.round(this.value)
		this.value_rounded_css = "progress-"+this.value_rounded
	}

	ngOnChanges(changes: SimpleChanges) {
		if (changes.value) {
			this.value_rounded = Math.round(this.value)
			if (this.value < this.config.inTime) {
				this.color = 'orange'
			} else if (this.value > this.config.inTime && this.value < this.config.onTime) {
				this.color = 'green'
			} else {
				this.color = 'red'
			}
		}
	}

}