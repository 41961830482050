import { Component, Input, Output, EventEmitter } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
// ejemplo <oh-link [link]="'../eir'" [text]="'EIR'" [icon]="'fa fa-life-buoy'"></oh-link>
@Component({
  selector: 'oh-link',
  templateUrl: './oh.linkBox.html',
  styleUrls: ['./oh.linkBox.css']
})
export class LinkBox {

	@Input() link: string;
	@Input() text: string;
	@Input() icon: string;
	@Input() id: any;

	@Output() onChange = new EventEmitter();

    constructor(private router: Router, private route: ActivatedRoute){
    }

    onLink(e){
      e.preventDefault();
      if(this.link){
        this.router.navigate([this.link], { relativeTo: this.route });
      }
      this.onChange.emit(this.id);
    }

}