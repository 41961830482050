import { Component, Input, OnDestroy } from '@angular/core';
import { AbstractControl, ControlValueAccessor, NG_VALIDATORS, NG_VALUE_ACCESSOR, ValidationErrors, Validator } from '@angular/forms';
import { Subscription } from 'rxjs';

@Component({
    selector: 'oh-quantity-selector',
    templateUrl: './oh.quantitySelector.html',
    styleUrls: ['./oh.quantitySelector.css'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            multi: true,
            useExisting: QuantitySelector
        },
        {
            provide: NG_VALIDATORS,
            multi: true,
            useExisting: QuantitySelector
        }
    ]
})
export class QuantitySelector implements ControlValueAccessor, Validator, OnDestroy {

    isValid: boolean = true

    quantity = 0;

    @Input() increment: number;
    @Input() min: number;
    @Input() max: number;

    myChange: Subscription

    ngOnDestroy() {
        if (this.myChange) {
            this.myChange.unsubscribe()
        }
    }

    onChange = (quantity) => { };

    onTouched = () => { };

    touched = false;
    disabled = false;

    writeValue(quantity: number) {
        this.quantity = quantity;
    }

    registerOnChange(onChange: any) {
        this.onChange = onChange;
    }

    registerOnTouched(onTouched: any) {
        this.onTouched = onTouched;
    }

    markAsTouched() {
        if (!this.touched) {
            this.onTouched();
            this.touched = true;
        }
    }

    setDisabledState(disabled: boolean) {
        this.disabled = disabled;
    }

    validate(control: AbstractControl): ValidationErrors | null {
        if (!this.myChange) {
            this.myChange = control.statusChanges.subscribe(c => {
                if (c != "DISABLED") {
                    this.isValid = (c == "VALID") ? true : false
                }
            })
        }
        return null;
    }

    onRemove() {
        this.markAsTouched();
        if (!this.disabled) {
            if (this.min == null || (this.min != null && this.quantity - this.increment >= this.min)) {
                this.quantity -= this.increment;
                this.onChange(this.quantity);
            }
        }
    }

    onAdd() {
        this.markAsTouched();
        if (!this.disabled && this.max) {
            if (this.max == null || (this.max && this.quantity + this.increment <= this.max)) {
                this.quantity += this.increment;
                this.onChange(this.quantity);
            }
        }
    }

}