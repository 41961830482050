<div *ngIf="ohAd.show" class="oh_ad_A">
	<div class="oh_ad_AA">
		<div *ngIf="ohAd.view=='S' || ohAd.view=='W' || ohAd.view=='E'" [@ohTopAd] class="alert alert-dismissible"
			[ngClass]="{'alert-success' : ohAd.view=='S', 'alert-warning' : ohAd.view=='W', 'alert-danger' : ohAd.view=='E'}"
			role="alert">
			<span [hidden]="ohAd.mensaje.length>0">{{lang.defaultMessage}}</span>
			<span [hidden]="ohAd.mensaje.length==0">{{ohAd.mensaje}}</span>
			<button *ngIf="showClose" (click)="closeAd($event)" type="button" class="close" data-dismiss="alert"
				aria-label="Close">
				<span aria-hidden="true">&times;</span>
			</button>
		</div>
		<div *ngIf="ohAd.view=='C'" [@ohTopAd] class="alert alert-primary" role="alert">
			<i class="fa fa-spinner fa-spin fa-fw"></i> {{lang.processing}}... <span
				class="oh_ad_AAA">{{lang.wait}}</span>
		</div>
	</div>
</div>