import { Component, Input } from '@angular/core';

/*
<oh-previewCode [codeText]="vistaPreviaSQL_insert"></oh-previewCode>
 */
@Component({
	selector: 'oh-previewCode',
	templateUrl: './oh.previewCode.html'
})
export class PreviewCode {

	@Input() lang: string = 'sql' // html | typescript | css | java |sql
	@Input()
	set codeText(valor: any) {
		if (this.lang == 'html') {
			this._codeText = this.escapeHtml(valor)
		} else {
			this._codeText = valor
		}
	}

	_codeText: string;

	constructor() {
	}

	private escapeHtml(unsafe: string) {
		return unsafe
			.replace(/&/g, "&amp;")
			.replace(/</g, "&lt;")
			.replace(/>/g, "&gt;")
			.replace(/"/g, "&quot;")
			.replace(/'/g, "&#039;");
	}

}