import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms"; // [Disabled]
import { CommonModule, CurrencyPipe, DecimalPipe } from "@angular/common"; // *ngIf
import { RouterModule } from "@angular/router";
import { HttpClientModule } from "@angular/common/http";

import { NgbDateParserFormatter, NgbDatepickerI18n, NgbModule } from "@ng-bootstrap/ng-bootstrap";

import {
  ListObject,
  FilterField,
  FilterCount,
  Reverse,
  FilterList,
  SafeHtml,
  LocaleCurrency,
  OhDatePipe,
  locationFilter,
  OHCurrencyMask,
} from "./oh.pipes";
import {
  FocusOnInit,
  DragDropDirective,
  Highlight,
  InputFormat,
  MarkText,
  ScrollMove,
  ValidateEqual,
  ValidateEqualNc,
  ValidateEqualNcStr,
  ValidateEqualObj,
  ValidateObject,
  ValidateObjectType,
  ValidateMaxValue,
  ValidateMinValue,
  ZxingReader,
  CopyText,
  AutoSize,
} from "./directives/oh.core";
import {
  Ad,
  Camera,
  DateRange,
  DateRanges,
  Filter,
  FilterTab,
  LinkBox,
  Loader,
  Option,
  Resizer,
  ResizerSide,
  SearchModal,
  predictiveTextOld,
  PredictiveText,
  multiInput,
  searchSensitive,
  Steps,
  StepsTrip,
  ImagePreview,
  ImageUpload,
  LinkMenu,
  FileUpload,
  DateTime,
  Pagin,
  CircleProgress,
  PreviewCode,
  PreviewObject,
  Thumbnail,
  DatetimeModal,
  DragDrop,
  PasteTable,
  ElipsisText,
  OhDate,
  QuantitySelector
} from "./components/oh.core";

import { Jpo, ohStorage, Util, ConfirmationModal } from "./services/oh.core";
import { MainService } from "./services/oh.mainService";
import { WindowRef } from "./services/oh.window";
import { AutocompleteComponent } from "../ohCore/components/googlemaps/google-places.component";

import { NgxMaskModule } from "ngx-mask";
import { DragulaModule } from "ng2-dragula";
import { DragDropModule } from "@angular/cdk/drag-drop";
import { NgbDateFRLanguajeFormatter, NgbDateFRParserFormatter } from "./components/dates/oh.date.provides";
import { OhDateRange } from "./components/dates/oh.dateRange";
import { QrScanner } from './components/qrScanner/oh.qrScanner';
import { ZXingScannerModule } from '@zxing/ngx-scanner';
import { OnBeforeUnloadAlert } from "./directives/oh.onBeforeUnloadAlert";

@NgModule({
  imports: [
    FormsModule,
    CommonModule,
    RouterModule,
    HttpClientModule,
    ReactiveFormsModule,
    NgbModule,
    NgxMaskModule.forRoot(),
    DragulaModule.forRoot(),
    DragDropModule,
    ZXingScannerModule
  ],
  declarations: [
    ListObject,
    FilterField,
    FilterCount,
    Reverse,
    locationFilter,
    FilterList,
    SafeHtml,
    LocaleCurrency,
    OhDatePipe,
    FocusOnInit,
    Highlight,
    InputFormat,
    MarkText,
    ScrollMove,
    ValidateEqual,
    ValidateEqualNc,
    ValidateEqualNcStr,
    ValidateEqualObj,
    ValidateObject,
    ValidateObjectType,
    ValidateMaxValue,
    ValidateMinValue,
    ZxingReader,
    CopyText,
    AutoSize,
    ElipsisText,
    Ad,
    Camera,
    DateRange,
    DateRanges,
    Filter,
    FilterTab,
    LinkBox,
    Loader,
    Option,
    Resizer,
    ResizerSide,
    predictiveTextOld,
    PredictiveText,
    multiInput,
    searchSensitive,
    DateTime,
    Pagin,
    CircleProgress,
    PreviewCode,
    PreviewObject,
    Steps,
    StepsTrip,
    ImagePreview,
    ImageUpload,
    LinkMenu,
    FileUpload,
    DragDrop,
    Thumbnail,
    DatetimeModal,
    PasteTable,
    ConfirmationModal,
    SearchModal,
    AutocompleteComponent,
    DragDropDirective,
    AutoSize,
    ElipsisText,
    OhDate,
    OhDateRange,
    QrScanner,
    OHCurrencyMask,
    QuantitySelector,
    OnBeforeUnloadAlert
  ],
  entryComponents: [ConfirmationModal, SearchModal, DatetimeModal],
  providers: [
    MainService, Jpo, ohStorage, Util, WindowRef, NgbDateFRParserFormatter, NgbDateFRLanguajeFormatter, DecimalPipe, CurrencyPipe,
    { provide: NgbDateParserFormatter, useClass: NgbDateFRParserFormatter },
    { provide: NgbDatepickerI18n, useClass: NgbDateFRLanguajeFormatter }
  ],
  exports: [
    ListObject,
    FilterField,
    FilterCount,
    Reverse,
    locationFilter,
    FilterList,
    SafeHtml,
    LocaleCurrency,
    OhDatePipe,
    FocusOnInit,
    Highlight,
    InputFormat,
    MarkText,
    ScrollMove,
    ValidateEqual,
    ValidateEqualNc,
    ValidateEqualNcStr,
    ValidateEqualObj,
    ValidateObject,
    ValidateObjectType,
    ValidateMaxValue,
    ValidateMinValue,
    ZxingReader,
    CopyText,
    AutoSize,
    ElipsisText,
    OhDate,
    Ad,
    Camera,
    DateRange,
    DateRanges,
    Filter,
    FilterTab,
    LinkBox,
    Loader,
    Option,
    Resizer,
    ResizerSide,
    predictiveTextOld,
    PredictiveText,
    multiInput,
    searchSensitive,
    DateTime,
    Pagin,
    CircleProgress,
    PreviewCode,
    PreviewObject,
    SearchModal,
    Steps,
    StepsTrip,
    ImagePreview,
    ImageUpload,
    LinkMenu,
    AutocompleteComponent,
    FileUpload,
    DragDrop,
    Thumbnail,
    DragDropDirective,
    PasteTable,
    OhDateRange,
    QrScanner,
    OHCurrencyMask,
    QuantitySelector,
    OnBeforeUnloadAlert
  ]
})
export class OHCore { }