import { CosmosClient } from "@azure/cosmos";
import { Subject } from "rxjs";
import { environment } from "src/environments/environment";

export class AzureCosmos {

    client: CosmosClient = null
    database: any = {}

    constructor() {
        this.instance()
    }

    instance() {

        if (environment && environment['azurecosmos'] && environment['azurecosmos'].endpoint) {

            const endpoint = environment['azurecosmos'].endpoint;
            const key = environment['azurecosmos'].apiKey;
            this.client = new CosmosClient({ endpoint, key });

        }

    }

    async getContainer(_database: string, _container: string) {

        if (!this.database[_database]) {
            //console.log("create o busca database "+_database)
            const { database } = await this.client.databases.createIfNotExists({ id: _database });
            this.database[_database] = {
                _db: database,
                containers: {}
            }
        }

        if (!(this.database[_database] && this.database[_database].containers[_container])) {
            //console.log("create o busca contenedor "+_container)
            const { container } = await this.database[_database]._db.containers.createIfNotExists({ id: _container });
            this.database[_database].containers[_container] = container
        }

    }

    async set(_database: string, _container: string, element: any, id?: string) {

        return new Promise<void>(async (resolve, reject) => {

            await this.getContainer(_database, _container)

            if (id) {
                if (element.id) {
                    element._id = "" + element.id
                }
                element.id = id
            }

            await this.database[_database].containers[_container].items.create(element);

            resolve()

        })

    }

    async setItems(_database: string, _container: string, elements: any, field_id?: string) {

        return new Promise<void>(async (resolve, reject) => {

            await this.getContainer(_database, _container)

            for (var i in elements) {

                if (field_id) {
                    if (elements[i].id) {
                        elements[i]._id = "" + elements[i].id
                    }
                    elements[i].id = "" + elements[i][field_id]
                }

                console.log(elements[i]);

                await this.database[_database].containers[_container].items.create(elements[i]);


            }

            resolve()

        })

    }

    async update(_database: string, _container: string, element: any, id: string, partitionKey?: string) {

        return new Promise<void>(async (resolve, reject) => {

            await this.getContainer(_database, _container)

            try {
                await this.database[_database].containers[_container].item(id, partitionKey).replace(element)
                resolve()
            } catch (e) {
                reject()
            }
        })

    }

    getItem(_database: string, _container: string, searchFields: string) {

        let _subs = new Subject()

        let _serv = new Promise<any>(async (resolve, reject) => {

            this.getItems(_database, _container, searchFields).subscribe((elements: any) => {
                if (elements && elements.length > 0) {
                    resolve(elements[0])
                } else {
                    resolve(null)
                }
            })

        })

        _serv.then((elements: any) => {
            _subs.next(elements)
        })

        return _subs
    }

    getItems(_database: string, _container: string, searchFields?: string, selectFields?: string) {

        let _subs = new Subject()

        let _serv = new Promise<any>(async (resolve, reject) => {

            await this.getContainer(_database, _container)

            let _query = "SELECT " + (selectFields ? selectFields : '*') + " FROM c" + (searchFields ? ' WHERE ' + searchFields : '')

            const { resources } = await this.database[_database].containers[_container].items.query(_query, { maxItemCount: 5000 }).fetchAll();

            resolve((resources && resources.length > 0) ? resources : [])

        })

        _serv.then((elements: any) => {
            _subs.next(elements)
        })

        return _subs
    }

    async delete(_database: string, _container, id: string) {

        return new Promise<void>(async (resolve, reject) => {

            await this.getContainer(_database, _container)

            await this.database[_database].containers[_container].item(id).delete()

            resolve()

        })
    }

    async deleteByWhere(_database: string, _container, searchFields?: string) {
        return new Promise<void>(async (resolve, reject) => {
            this.getItems(_database, _container, searchFields).subscribe(async (elements: any) => {
                if (elements && elements.length > 0) {
                    for (var i in elements) {
                        await this.delete(_database, _container, elements[i].id)
                    }
                }
                resolve()
            })

        })
    }

}