import { Component } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { Title } from '@angular/platform-browser';

import { environment } from 'src/environments/environment';
import { shared } from 'src/environments/environmentShared';
import { OHService } from 'src/app/tis.ohService';
import { ohStorage } from 'src/app/ohCore/services/storage/oh.storage';
import { UserServiceJPO, pSeguserRegister } from 'src/app/service/ind.userService';
import { APSBusinessUnitServiceJPO, pApsbusinessUnitAppointmentList } from 'src/app/module/APS/service/aps.aPSBusinessUnitService';

declare var AesUtil: any;

@Component({
	templateUrl: './inlo.register.html'
})
export class INLORegister {

	private aPSBusinessUnitService: APSBusinessUnitServiceJPO
	private userService: UserServiceJPO;
	private myStore: ohStorage;

	dateRegister: any = {};

	business_units: pApsbusinessUnitAppointmentList[]

	constructor(private router: Router, private ohService: OHService, private title: Title) {

		this.aPSBusinessUnitService = new APSBusinessUnitServiceJPO(ohService)
		this.userService = new UserServiceJPO(ohService);
		this.myStore = new ohStorage();

		this.apsbusinessUnitAppointmentList()

		if (this.myStore.has("APM_DATA")) {
			this.router.navigate(['/Inlandnet']);
		}

	}

	ngOnInit() {
		this.title.setTitle(shared.proyect + " - Registrar usuario");
	}

	register(frm: NgForm) {
		if (frm.valid) {

			this.ohService.getOH().getUtil().confirm("Confirma registrar nuevo usuario?", () => {

				var passwordEncripted = new AesUtil(shared.key_send).encrypt(this.dateRegister.password);

				this.userService.seguserRegister({
					name: this.dateRegister.name.toUpperCase(),
					parents_name: this.dateRegister.lastName.toUpperCase(),
					mothers_name: this.dateRegister.middleName.toUpperCase(),
					email: this.dateRegister.email.toUpperCase(),
					password: passwordEncripted,
					company_document: this.dateRegister.RUC.toUpperCase(),
					company: this.dateRegister.company.toUpperCase(),
					link: environment.protocol + "://" + environment.hostLocal + "/#/PasswordConfirm",
					business_unit_id: this.dateRegister.business_unit_id,
					origin: "COP"
				}, (resp: pSeguserRegister) => {

					if (resp.resp_new_id == 1) {
						frm.resetForm({});
						this.myStore.set("APM_LOGIN_SUCCESS", {
							mensaje: resp.resp_message,
							timeSeconds: 12,
							showClose: false
						});
						this.router.navigate(['/Login']);
					} else {
						if (resp.resp_new_id == 0) {
							this.ohService.getOH().getLoader().showError(resp.resp_message);
						} else {
							this.ohService.getOH().getAd().warning(resp.resp_message);
						}
					}

				})

			})

		}
	}

	private apsbusinessUnitAppointmentList() {
		this.aPSBusinessUnitService.apsbusinessUnitAppointmentList((resp: pApsbusinessUnitAppointmentList[]) => {
			this.business_units = resp
		})
	}

}