import { CurrencyPipe, DecimalPipe } from '@angular/common';
import { PipeTransform, Pipe } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

import * as moment from 'moment';
import { CurrencyMaskConfig } from 'ngx-currency';

@Pipe({ name: 'ohListObject' })
export class ListObject implements PipeTransform {
  transform(objeto, args: string[]): any {
    let lista = [];
    for (let llave in objeto) {
      lista.push({ key: llave, object: objeto[llave] });
    }
    return lista;
  }
}

@Pipe({ name: 'ohFilterField' })
export class FilterField implements PipeTransform {
  transform(items: any[], field: any, value: any): any[] {
    if (!items) return [];
    if (typeof (field) != "string") {
      var mapId = {};
      for (var i in value[1]) {
        mapId[value[1][i]] = true;
      }
      return items.filter((elemento) => {
        return elemento[field[0]] == value[0] && mapId[elemento[field[1]]];
      });
    } else {
      return items.filter(it => it[field] == value);
    }

  }
}

@Pipe({ name: 'ohFilterCount' })
export class FilterCount implements PipeTransform {
  transform(items: any[], field: string, value: string): number {
    if (!items) return 0;
    return items.filter(it => it[field] == value).length;
  }
}

@Pipe({ name: 'ohReverse' })
export class Reverse {
  transform(value) {
    if (value && value.slice) {
      return value.slice().reverse();
    }
  }
}

//| ohLocationFilter : 2" filtra por locacion_id
@Pipe({ name: 'ohLocationFilter' })
export class locationFilter implements PipeTransform {
  transform(items: any[], value: number) {
    console.log("pipe_item:", items)
    console.log("pipe_value:", value)
    if (value) {
      return items.filter(it => it.planificacion_locacion_id == value);
    } else {
      return items
    }
  }
}

@Pipe({ name: 'ohFilterList', pure: false })
export class FilterList implements PipeTransform {
  transform(items: any, filter: any): any {
    if (!filter.getVisibleItems) {
      filter.getVisibleItems = function () {
        return (!items || !filter) ? items : items.filter((item: any) => this.applyFilter(item, filter));
      }.bind(this);
    }
    if (!filter.getHideItems) {
      filter.getHideItems = function () {
        return (!items || !filter) ? [] : items.filter((item: any) => !this.applyFilter(item, filter));
      }.bind(this);
    }

    return (!items || !filter) ? items : items.filter((item: any) => this.applyFilter(item, filter));
  }

  applyFilter(book: any, filter: any): boolean {
    if (book.object) {
      book = book.object;
    }
    for (let field in filter) {
      if (filter[field]) {
        if (typeof filter[field] === 'string') {
          let campo1 = book[field] ? String(book[field]).toLowerCase() : '';
          let campo2 = filter[field] ? String(filter[field]).toLowerCase() : '';
          if (this.removeSpecial(campo1).indexOf(this.removeSpecial(campo2)) === -1) {
            return false;
          }
        } else if (typeof filter[field] === 'number') {
          if (book[field] !== filter[field]) {
            return false;
          }
        }
      }
    }
    return true;
  }

  removeSpecial(s: string) {
    var r = s.toLowerCase();
    r = r.replace(new RegExp(/\s/g), "");
    r = r.replace(new RegExp(/[àáâãäå]/g), "a");
    r = r.replace(new RegExp(/æ/g), "ae");
    r = r.replace(new RegExp(/ç/g), "c");
    r = r.replace(new RegExp(/[èéêë]/g), "e");
    r = r.replace(new RegExp(/[ìíîï]/g), "i");
    r = r.replace(new RegExp(/ñ/g), "n");
    r = r.replace(new RegExp(/[òóôõö]/g), "o");
    r = r.replace(new RegExp(/œ/g), "oe");
    r = r.replace(new RegExp(/[ùúûü]/g), "u");
    r = r.replace(new RegExp(/[ýÿ]/g), "y");
    r = r.replace(new RegExp(/\W/g), "");
    return r;
  }

}

@Pipe({ name: 'ohSafeHtml' })
export class SafeHtml implements PipeTransform {
  constructor(private sanitized: DomSanitizer) { }
  transform(value) {
    if (value && value.length > 0) {
      return this.sanitized.bypassSecurityTrustHtml(value);
    } else {
      return value;
    }
  }
}

@Pipe({
  name: 'ohCurrencyFormat'
})
export class LocaleCurrency implements PipeTransform {
  transform(
    val: number,
    currencySign: string = 'S/',
    localeString: string = 'es-PE'): string {
    if (val !== undefined && val !== null) {
      return currencySign + ' ' + val.toLocaleString(localeString, { minimumFractionDigits: 2, maximumFractionDigits: 2 });
    } else {
      return '';
    }
  }
}

@Pipe({
  name: 'ohCurrencyMask'
})
export class OHCurrencyMask implements PipeTransform {
  constructor(private _decimalPipe: DecimalPipe, private _currencyPipe: CurrencyPipe) {
  }
  transform(val: number, currencyMask: CurrencyMaskConfig): string {
    return currencyMask ? (currencyMask.prefix.length == 0 ? this._decimalPipe.transform(val, "1.0-" + currencyMask.precision) : this._currencyPipe.transform(val, currencyMask.prefix, "symbol", "1.0-" + currencyMask.precision)) : "" + val
  }
}

//| ohDate : 'DD/MM/YYYY'"
@Pipe({ name: 'ohDate' })
export class OhDatePipe implements PipeTransform {
  transform(date: any, format: string) {
    if (date) {
      let _date = moment(date)
      if (!_date.isValid()) {
        //console.log("Invalid date")
      }
      return _date.isValid() ? _date.format(format) : ""
    } else {
      //console.log("Invalid date")
      return ""
    }
  }
}
