import { map } from "rxjs/operators";
import { INLANDusuarios_conectados } from "./usuarios_conectados";
import { AngularFirestore } from "@angular/fire/firestore";
import { INLANDLog } from "./log";
import { AzureCosmos } from "../azure/cosmos";

export interface Inland {
  habilitar_log: boolean;
  version?: string;
  version_actualizacion?: number;
}

export class INLANDmain {
  private firestore: AngularFirestore;
  private enviroment: string;
  private document: string;

  public usuarios_conectados: INLANDusuarios_conectados;
  sub_usuario_conectado: any;
  public logs: INLANDLog;
  private azureCosmos: AzureCosmos

  constructor(firestore: AngularFirestore, enviroment: string, document: string, azureCosmos: AzureCosmos) {

    this.firestore = firestore;
    this.enviroment = enviroment;
    this.document = document;
    this.azureCosmos = azureCosmos
    this.usuarios_conectados = new INLANDusuarios_conectados(firestore, enviroment, document, azureCosmos);
    this.logs = new INLANDLog(firestore, enviroment, document, azureCosmos);

  }

  enlazar(sistema_id: number) {
    if (this.azureCosmos.client) {
      return this.azureCosmos.getItem(this.document, "SISTEMA", "c.id = '" + sistema_id + "'")
    } else {
      return this.firestore.doc<any>('/' + this.enviroment + '/' + this.document + "/SISTEMA/" + sistema_id).snapshotChanges().pipe( // nuevo /SISTEMA/1
        map(({ payload }) => ({ ...payload.data(), id: payload.id }))
      );
    }
  }

  editar(sistema_id: number, usuario: Inland, call: any) {
    if (this.azureCosmos.client) {
      return this.azureCosmos.update(this.document, "SISTEMA", usuario, "" + sistema_id)
    } else {
      this.firestore.doc<Inland>('/' + this.enviroment + '/' + this.document + "/SISTEMA/" + sistema_id).update(usuario)
    }
  }

}