<div class="text-centeralign" style="min-width: max-content">
  <svg
    *ngIf="!photo && _name && _lastName"
    class="bd-placeholder-img rounded-circle"
    [attr.width]="_size"
    [attr.height]="_size"
    xmlns=" http://www.w3.org/2000/svg"
    focusable="false"
    role="img"
  >
    <title>{{_name}} {{_lastName}}</title>
    <rect
      width="100%"
      height="100%"
      [attr.fill]="monocromatic ? '#949a9e' : colors[index_color][0]"
    ></rect>
    <text
      x="50%"
      y="50%"
      dominant-baseline="middle "
      [attr.fill]="monocromatic ? '#FFFFFF' : colors[index_color][1]"
      style="letter-spacing: 0.1rem"
      text-anchor="middle "
    >
      {{_nameFirst+_lastNameShort}}
    </text>
  </svg>
  <div
    *ngIf="photo"
    class="rounded-circle oht_photo_frame text-center"
    [attr.width]="_size"
    [attr.height]="_size"
  >
    <img
      [src]="photo"
      class="oht_photo_picture"
      [attr.width]="_size"
      [attr.height]="_size"
    />
  </div>
  <ng-template [ngIf]="textInDown">
    <br />
  </ng-template>
  <span
    *ngIf="showName && shortName"
    style="white-space: nowrap"
    class="ml-1"
    [ngClass]="getClass()"
    >{{_nameShort}} {{_lastNameShort}}.</span
  >
  <span
    *ngIf="showName && !shortName"
    style="white-space: nowrap"
    class="ml-1"
    [ngClass]="getClass()"
    >{{_nameShort}} {{_lastName}}.</span
  >
</div>
