import { Component, Input, Output, EventEmitter, ViewChild, Inject, ViewEncapsulation, OnDestroy, Renderer2, ElementRef } from '@angular/core';
import { AbstractControl, ControlValueAccessor, NgForm, NgModel, NG_VALIDATORS, NG_VALUE_ACCESSOR, ValidationErrors, Validator } from '@angular/forms';
import { NgbCalendar, NgbDate, NgbDateAdapter, NgbDateNativeAdapter, NgbDatepicker, NgbDateStruct, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslocoService } from '@ngneat/transloco';

import * as moment from 'moment';
import { Subscription } from 'rxjs';
import { OHService } from 'src/app/tis.ohService';
import { ohObjDateFormat } from './oh.date.provides';

@Component({
  selector: 'oh-date',
  templateUrl: './oh.date.html',
  styleUrls: ['./oh.date.css'],
  encapsulation: ViewEncapsulation.None,
  providers: [
    {
      provide: NgbDateAdapter,
      useClass: NgbDateNativeAdapter
    },
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: OhDate
    },
    {
      provide: NG_VALIDATORS,
      multi: true,
      useExisting: OhDate
    }
  ]
})
export class OhDate implements ControlValueAccessor, Validator, OnDestroy {

  @ViewChild('inp_date', { static: true }) inp_date: NgModel
  @ViewChild("inp_datepicker", { static: true }) inp_datepicker: NgbDatepicker;

  @Input()
  set dateValue(dateValue: Date) {
    this.__dateValue = dateValue
    //if(dateValue){
    this.mapHour()
    this.selectDate()
    //}
  }
  @Output() dateValueChange = new EventEmitter(); // llama a un evento
  __dateValue: Date

  @Input() type: string = "lineal" // lineal - modal

  @Input()
  set currentDate(currentDate: Date) {
    this._currentDate = currentDate
    this.mapRange()
  }
  _currentDate: Date

  @Input()
  set minRange(minRange: number) {
    this._minRange = minRange
    this.mapRange()
  }
  _minRange: number

  @Input()
  set maxRange(maxRange: number) {
    this._maxRange = maxRange
    this.mapRange()
  }
  _maxRange: number

  @Input()
  set minDate(minDate: Date) {
    this._minDate = minDate
    this.mapRange()
  }
  _minDate: Date

  @Input()
  set maxDate(maxDate: Date) {
    this._maxDate = maxDate
    this.mapRange()
  }
  _maxDate: Date

  _minFinalDate: NgbDateStruct
  _maxFinalDate: NgbDateStruct

  @Input() form: NgForm
  @Input() name: any
  @Input() required: boolean
  @Input() disabled: boolean

  @Input()
  set hour(hour: boolean) {
    this._hour = hour
    if (this.__dateValue) {
      this.mapHour()
      this.selectDate()
    }
  }

  _hour: boolean

  @Input() hour_minute: string
  @Output() hour_minuteChange = new EventEmitter()

  objDate: any

  langSubs: any
  lang: any = {}

  @Input() markDisabled: any

  @Input() clean_option: boolean = true

  myChange: Subscription
  isValid: boolean = true

  @Input() hourStep: number
  @Input() minuteStep: number
  @Input() secondStep: number

  constructor(@Inject(ohObjDateFormat) objDate: any, private modalService: NgbModal, private translocoService: TranslocoService, private calendar: NgbCalendar, private ohCore: OHService, private _renderer: Renderer2, private _elRef: ElementRef) {
    this.objDate = objDate
    this.langSubs = this.translocoService.selectTranslateObject('component.date').subscribe((valor) => {
      this.lang = valor
    })
  }

  ngOnInit() {
    this._renderer.setAttribute(this._elRef.nativeElement, 'ohdate', '');
  }

  ngAfterViewInit() {
    if (this.required && this.form && this.inp_date) {
      this.inp_date.name = this.name || "inp_date";
      this.form.addControl(this.inp_date);
    }
  }

  ngOnDestroy() {
    if (this.myChange) {
      this.myChange.unsubscribe()
    }
  }

  mapRange() {

    let __currentDate = this._currentDate ? this._currentDate : new Date()

    if (__currentDate && (this._minRange || this._minDate)) {
      var dateMin = new Date()
      if (this._minDate) {
        dateMin = this._minDate
      } else {
        dateMin.setDate(__currentDate.getDate() - this._minRange)
      }
      if (dateMin.getUTCDate) {
        this._minFinalDate = { day: dateMin.getUTCDate(), month: dateMin.getUTCMonth() + 1, year: dateMin.getUTCFullYear() }
      }
    }

    if (__currentDate && (this._maxRange || this._maxDate)) {
      var dateMax = new Date();
      if (this._maxDate) {
        dateMax = this._maxDate
      } else {
        dateMax.setDate(__currentDate.getDate() + this._maxRange)
      }
      if (dateMax.getUTCDate) {
        this._maxFinalDate = { day: dateMax.getUTCDate(), month: dateMax.getUTCMonth() + 1, year: dateMax.getUTCFullYear() }
      }
    }

  }

  dateValueFinal: any
  selectDate() {

    if (this.__dateValue) {
      let result = moment(this.__dateValue, this.objDate.format, true).isValid()
      if (result) {
        if (this._hour) {
          this.hourChange()
        } else {
          this.dateValueFinal = this.__dateValue
          this.dateValueChange.emit(this.__dateValue)
          this.onChange(this.__dateValue)
        }
      } else {
        this.dateValueChange.emit(null)
        this.onChange(null)
      }
    } else {
      this.dateValueChange.emit(null)
      this.dateValueFinal = null
      this.onChange(null)
    }
  }

  clean() {
    this.__dateValue = null
    this.time = null
    this._dateValue = null
    this._time = null
    this.dateValueFinal = null
    this.dateValueFormat = null
    this.dateValueChange.emit(null)
    this.onChange(null)
  }

  time: any
  hourChange() {
    let _date = moment(this.__dateValue)

    if (this._hour && this.time && this.__dateValue && _date.isValid()) {

      this.__dateValue.setHours(this.time.hour)
      this.__dateValue.setMinutes(this.time.minute)

      if (this._minDate && this.__dateValue < this._minDate) {
        this.ohCore.getOH().getAd().warning("Ingrese un valor correcto de fecha mínima")

        this.dateValueFinal = null
        this.dateValueChange.emit(null)
        this.onChange(null)

        return
      }

      if (this._maxDate && this.__dateValue > this._maxDate) {
        this.ohCore.getOH().getAd().warning("Ingrese un valor correcto de fecha máxima")

        this.dateValueFinal = null
        this.dateValueChange.emit(null)
        this.onChange(null)

        return
      }

      this.dateValueFinal = this.__dateValue
      this.dateValueChange.emit(this.__dateValue)
      this.hour_minuteChange.emit(this.time.hour * 60 + this.time.minute)

      //console.log("this.__dateValue")
      //console.log(this.__dateValue)

      this.onChange(this.__dateValue)

    } else {
      this.dateValueFinal = null
      this.dateValueChange.emit(null)
      this.onChange(null)
    }
  }

  mapHour() {
    if (this.__dateValue && this._hour) {
      this.time = {
        hour: this.__dateValue.getHours(),
        minute: this.__dateValue.getMinutes()
      }
      this.dateValueFormat = moment(this.__dateValue).format(this.objDate.formatTime)
      console.log("mapHour")
      console.log(this.__dateValue)
      console.log(this.objDate.formatTime)
      console.log(this.dateValueFormat)
    } else {
      this.time = null
      this.dateValueFormat = null
    }
  }

  dateValueFormat: string

  openView(inp_datepicker: any, modalEditor: any) {

    if (this._hour) {
      this._dateValue = this.__dateValue ? new Date(JSON.parse(JSON.stringify(this.__dateValue))) : null
      this._time = this.time ? JSON.parse(JSON.stringify(this.time)) : null

      this.modalService.open(modalEditor, { size: 'sm', backdrop: "static" }).result.then((result) => {
        if (result == "save") {
          this.__dateValue = this._dateValue ? new Date(JSON.parse(JSON.stringify(this._dateValue))) : null
          this.time = this._time ? JSON.parse(JSON.stringify(this._time)) : null
          this.selectDate()
          this.dateValueFormat = moment(this.__dateValue).format(this.objDate.formatTime)

          console.log("openView")
          console.log(this.__dateValue)
          console.log(this.objDate.formatTime)
          console.log(this.dateValueFormat)
        }
      }, () => {
      });
    } else {
      inp_datepicker.toggle()
    }
  }

  _dateValue: any
  _time: any

  isToday = (date: NgbDate) => {
    return date.equals(this.calendar.getToday())
  }

  selectDateModal() {
    if (this._dateValue) {
      let result = moment(this._dateValue, this.objDate.format, true).isValid()
      if (result) {
        if (this._hour) {
          this.hourChangeModal()
        }
      }
    }
  }


  validSelectedDate: boolean
  hourChangeModal() {

    this.validSelectedDate = false

    let _date = moment(this._dateValue)

    if (this._hour && this._time && this._dateValue && _date.isValid()) {

      this._dateValue.setHours(this._time.hour)
      this._dateValue.setMinutes(this._time.minute)

      if (this._minDate && this._dateValue < this._minDate) {
        //this.ohCore.getOH().getAd().warning("Ingrese un valor correcto de fecha mínima")
        return
      }

      if (this._maxDate && this._dateValue > this._maxDate) {
        //this.ohCore.getOH().getAd().warning("Ingrese un valor correcto de fecha máxima")
        return
      }

      this.validSelectedDate = true

    }
  }


  onChange = (quantity) => { };

  onTouched = () => { };

  touched = false;
  //disabled = false;

  writeValue(quantity: Date) {
    this.dateValue = quantity;
  }

  registerOnChange(onChange: any) {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: any) {
    this.onTouched = onTouched;
  }

  markAsTouched() {
    if (!this.touched) {
      this.onTouched();
      this.touched = true;
    }
  }

  setDisabledState(disabled: boolean) {
    this.disabled = disabled;
  }


  validate(control: AbstractControl): ValidationErrors | null {

    if (control.status == "VALID" && control.errors && control.errors.required && !control.value) {
      this.required = true
      this.isValid = false
    }

    if (!this.myChange) {
      this.myChange = control.statusChanges.subscribe(c => {
        if (c != "DISABLED") {
          this.required = (c == "INVALID") ? true : false
          this.isValid = (c == "VALID") ? true : false
        }
      })
    }
    return null;
  }

}