<nav class="navbar fixed-bottom navbar-expand ind-footNav" style="height: 30px">
    <div class="navbar-text m-auto text-light vistaC ind-footText">
        <ng-container *ngIf="!cse.data.pie_vista || cse.data.pie_vista == 1">
            Copyright <i class="fa fa-copyright" aria-hidden="true"></i> {{currentDate | date:'yyyy'}} A.P. Moller Maersk, Todos los derechos reservados - Versión {{version}}
        </ng-container>
        <ng-container *ngIf="cse.data.pie_vista == 2">
            Copyright <i class="fa fa-copyright" aria-hidden="true"></i> {{currentDate | date:'yyyy'}} A.P. Moller Maersk, Todos los derechos reservados - Versión {{version}}
        </ng-container>
        <i *ngIf="cse.isOnline | async" class="ind-footConex fas fa-signal text-success" title="Conexión establecida"></i>
        <i *ngIf="!(cse.isOnline | async)" class="ind-footConex fas fa-signal text-danger" title="Sin conexión"></i>
    </div>
    <div class="navbar-text m-auto text-light vistaAB ind-footText ind-footSmall">
        <ng-container *ngIf="!cse.data.pie_vista || cse.data.pie_vista == 1">
            <img src="assets/img/logo-maersk-mini.png" class="ind-footImg">
            <i class="fa fa-copyright" aria-hidden="true"></i> {{currentDate | date:'yyyy'}} - v{{version}}
        </ng-container>
        <ng-container *ngIf="cse.data.pie_vista == 2">
            <img src="assets/img/logo-maersk-mini.png" class="ind-footImgv2">
        </ng-container>
        <i *ngIf="cse.isOnline | async" class="ind-footConex fas fa-signal text-success" title="Conexión establecida"></i>
        <i *ngIf="!(cse.isOnline | async)" class="ind-footConex fas fa-signal text-danger" title="Sin conexión"></i>
    </div>
</nav>