import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { OHCore } from './ohCore/oh.module';

import { INLORouting } from './inlo.route';
import { ohObjDateFormatCustom, OHService } from './tis.ohService';
import { CoreService } from './ind.coreService';

import { INLOLogin, INLORegister, INLOPasswordConfirm, INLOPasswordRestore } from './view/access/inlo.core';
import { Body } from './view/body/ind.body';
import { Foot } from './view/foot/ind.foot';
import { AngularFireModule } from '@angular/fire';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { AngularFireStorageModule } from '@angular/fire/storage';
import { AngularFireMessagingModule } from '@angular/fire/messaging';
import { environment } from 'src/environments/environment';
import { Messaging } from './ind.messaging';
import { AsyncPipe } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { TranslocoRootModule } from './transloco/transloco-root.module';
import { ohObjDateFormat } from './ohCore/components/dates/oh.date.provides';
import { INDAzureStorage } from './azure/storage';

@NgModule({
  declarations: [
    Body, Foot, INLOLogin, INLORegister, INLOPasswordConfirm, INLOPasswordRestore
  ],
  imports: [
    BrowserModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFirestoreModule,
    AngularFireStorageModule,
    AngularFireMessagingModule,
    BrowserAnimationsModule,
    FormsModule,
    NgbModule,
    INLORouting,
    OHCore,
    HttpClientModule,
    TranslocoRootModule
  ],
  providers: [
    OHService, CoreService, Messaging, AsyncPipe, INDAzureStorage,
    { provide: ohObjDateFormat, useValue: ohObjDateFormatCustom }
  ],
  bootstrap: [Body]
})
export class INLOModule { }