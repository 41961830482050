import { Component, HostListener } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Title } from '@angular/platform-browser';

import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { environment } from 'src/environments/environment';
import { shared } from 'src/environments/environmentShared';
import { OHService } from 'src/app/tis.ohService';
import { ohStorage } from 'src/app/ohCore/services/storage/oh.storage';
import { CoreService } from 'src/app/ind.coreService';
import { UserServiceJPO, pSegloginAcceder, pSegusuarioClaveRestaurar } from 'src/app/service/ind.userService';
import { TranslocoService } from '@ngneat/transloco';

declare var AesUtil: any;
declare var apmdata: any;
apmdata.host_or = environment.hostLocal;

@Component({
	templateUrl: './inlo.login_aps.html',
	styleUrls: ['./inlo.login.css']
})
export class INLOLogin {

	user: any;

	private myStore: ohStorage;
	private userService: UserServiceJPO;
	currentPosition: any;
	sizeW: number;
	sizeWidthImg: string;

	restoreObj: any;

	constructor(private router: Router, private ohService: OHService, private title: Title, private modalService: NgbModal, private route: ActivatedRoute, public cse: CoreService, private translocoService: TranslocoService) {

		this.myStore = new ohStorage();
		this.userService = new UserServiceJPO(ohService);

		this.user = {};
		this.currentPosition = {
			latitude: "",
			longitude: ""
		};

		this.sizeW = 2;
		this.sizeWidthImg = "100%";

		this.restoreObj = {
			emailRestore: "",
			alertMsj: ""
		};

		this.cse.onUpdate();

		translocoService.setDefaultLang(environment.default_languaje);
		translocoService.setActiveLang(environment.default_languaje);
	}

	ngOnInit() {

		this.ohService.getOH().getLoader().close();

		/*if(this.myStore.has("JPO_VERSION")){
			var myVer = this.myStore.get("JPO_VERSION");
			this.myStore.remove("JPO_VERSION");
			this.doEnter(myVer);
			return;
		} */

		this.title.setTitle(shared.proyect + " - Iniciar sesión");

		var geo_options = {
			enableHighAccuracy: true,
			maximumAge: 4000,
			timeout: 3000
		};

		if (navigator.geolocation) {
			var wpid = navigator.geolocation.watchPosition((position) => {
				this.currentPosition = position.coords;
			}, () => {
				//console.log("error");
			}, geo_options);
		}

		apmdata.render(this, window.innerHeight, window.innerWidth);

		var regId = "APM_LOGIN_SUCCESS";
		if (this.myStore.has(regId)) {
			this.ohService.getOH().getAd().success(this.myStore.get(regId));
			this.myStore.remove(regId);
		}

		regId = "APM_CONFIRM_SUCCESS";
		if (this.myStore.has(regId)) {
			this.ohService.getOH().getAd().success(this.myStore.get(regId));
			this.myStore.remove(regId);
		}

		regId = "APM_CONFIRM_ALERT";
		if (this.myStore.has(regId)) {
			this.ohService.getOH().getAd().warning(this.myStore.get(regId));
			this.myStore.remove(regId);
		}

		regId = "APM_RESTORE_SUCCESS";
		if (this.myStore.has(regId)) {
			this.ohService.getOH().getAd().success(this.myStore.get(regId));
			this.myStore.remove(regId);
		}


	}

	ngAfterViewInit() {
		apmdata.background(2, "background_cop");
	}

	login() {
		var passwordEncripted = new AesUtil(shared.key_send).encrypt(this.user.password);
		var sistema_id = environment.systemId || shared.systemId;
		this.userService.segloginAcceder({
			Usuario: this.user.user,
			ClaveMD5: passwordEncripted,
			Sistema_id: sistema_id
		}, {
			AUT: {
				F: {
					clientId: "API_APM_INLANDNET",
					clientSecret: "33caa750333af31d49d39e9251ecb592",
					latitude: this.currentPosition.latitude,
					longitude: this.currentPosition.longitude,
					so: window["browserInfo"]["os"] + " " + window["browserInfo"]["osVersion"],
					browser: window["browserInfo"]["browser"] + " " + window["browserInfo"]["browserVersion"]
				}
			}
		}, (resp: pSegloginAcceder) => {
			if (resp.data) {
				this.ohService.getOH().getLoader().show();
				resp['profile'] = "" + apmdata.getProfile(resp.profiles);
				resp['profile_sub_unit_default'] = this.getDefaultSubProfile(resp.profiles, resp['profile']);
				resp['password'] = passwordEncripted;
				resp.data.id = resp.data.id.trim();
				resp.data.userid = resp.data.userid.trim();
				resp['sistema_id'] = sistema_id;
				resp['origin'] = environment.hostLocal;

				// New

				resp['business_unit_id'] = "" + resp['profile']
				resp['business_unit_alias'] = resp.profiles.find((it) => it.id == resp['profile']).alias_unidad_negocio

				resp['sub_business_unit_id'] = resp['profile_sub_unit_default']
				if (resp['profile_sub_unit_default']) {
					resp['sub_business_unit_alias'] = resp.sub_unidades_sistema.find(it => it.unidad_negocio_id == resp['profile_sub_unit_default']).alias_unidad_negocio
				}

				this.doEnter(resp);
			} else {
				this.ohService.getOH().getAd().warning("Usuario o contraseña incorrecto");
			}
		})

	}

	doEnter(resp: any) {
		this.cse.data.user = resp;
		this.myStore.set("APM_DATA", resp);
		this.router.navigate(["/" + shared.baseBackEnd], { relativeTo: this.route });
	}

	@HostListener('window:resize', ['$event'])
	onResize(event) {
		apmdata.render(this, event.target.innerHeight, event.target.innerWidth);
	}

	openRestorePassword(content) {
		this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
			if (result == "restore") {

			}
		});
	}

	restore(eventClose: any) {
		this.userService.segusuarioClaveRestaurar({
			Correo: this.restoreObj.emailRestore,
			Enlace: environment.protocol + "://" + environment.hostLocal + "/#/PasswordRestore"
		}, (resp: pSegusuarioClaveRestaurar) => {
			if (resp.estado == 1) {
				this.ohService.getOH().getAd().success({
					mensaje: resp.mensaje,
					timeSeconds: 7,
					showClose: false
				});
				this.restoreObj = {
					emailRestore: "",
					alertMsj: ""
				};
				eventClose();
			} else {
				if (resp.estado == 0) {
					this.ohService.getOH().getLoader().showError(resp.mensaje);
				} else {
					this.restoreObj.alertMsj = resp.mensaje;
				}
			}
		})
	}

	/* == METODOS PRIVADOS == */
	private getDefaultSubProfile(profiles: any, profile: any) {
		let un = profiles.find(it => it.id == profile);
		if (un && un.un_hijo_predefinido_id) {
			return un.un_hijo_predefinido_id
		}
		return null;
	}

}