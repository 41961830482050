import { map } from "rxjs/operators";
import { AngularFirestore } from "@angular/fire/firestore";
import { AzureCosmos } from "../azure/cosmos";
import { Observable, Subject } from "rxjs";

export interface Usuario {
	changePassword?: string,
	company?: string,
	companyId?: string,
	daysChangekey?: number,
	id?: string,
	lastMiddle?: string,
	lastName?: string,
	name?: string,
	source?: string,
	token?: string,
	userid?: string
}

export class INLANDusuarios_conectados {

	private firestore: AngularFirestore;
	private coleccion: string = "usuarios_conectados"; // '/APM_DESA/INLAND/usuarios_conectados/'
	private enviroment: string;
	private document: string;
	private azureCosmos: AzureCosmos

	constructor(firestore: AngularFirestore, enviroment: string, document: string, azureCosmos: AzureCosmos) {
		this.firestore = firestore;
		this.enviroment = enviroment;
		this.document = document;
		this.azureCosmos = azureCosmos;
	}

	listar() {
		if (this.azureCosmos.client) {
			return this.azureCosmos.getItems(this.document, this.coleccion)
		} else {
			return this.firestore.collection<any>('/' + this.enviroment + '/' + this.document + '/' + this.coleccion).snapshotChanges().pipe(
				map(actions => {
					return actions.map((a: any) => {
						const data = a.payload.doc.data();
						const id = a.payload.doc.id;
						return { id, ...data };
					});
				})
			)
		}
	}

	enlazar(token: string): Observable<any> {
		if (this.azureCosmos.client) {
			return this.azureCosmos.getItem(this.document, this.coleccion, "c.token = '" + token + "'")
		} else {
			return this.firestore.doc<Usuario>('/' + this.enviroment + '/' + this.document + '/' + this.coleccion + '/' + token).snapshotChanges().pipe(
				map(({ payload }) => ({ ...payload.data(), id: payload.id }))
			)
		}
	}

	guardar(usuario: Usuario) {
		if (this.azureCosmos.client) {
			return this.azureCosmos.set(this.document, this.coleccion, usuario, usuario.token)
		} else {
			return this.firestore.doc<Usuario>('/' + this.enviroment + '/' + this.document + '/' + this.coleccion + '/' + usuario.token).set(usuario)
		}
	}

	editar(usuario: Usuario) {
		if (this.azureCosmos.client) {
			return this.azureCosmos.update(this.document, this.coleccion, usuario, usuario.token)
		} else {
			return this.firestore.doc<Usuario>('/' + this.enviroment + '/' + this.document + '/' + this.coleccion + '/' + usuario.token).update(usuario)
		}
	}

	eliminar(token: string) {
		if (this.azureCosmos.client) {
			this.azureCosmos.delete(this.document, this.coleccion, token)
		} else {
			this.firestore.collection<Usuario>('/' + this.enviroment + '/' + this.document + '/' + this.coleccion + '').doc('' + token).delete()
		}
	}

}
