<input type="text" #inp_hd="ngModel" class="form-control" [name]="'inp_hd'+name" [(ngModel)]="item_check"
    [required]="required" hidden="true">
<div [ngClass]="validItem(item) ? 'visible' : 'hidden'">
    <div class="border rounded w-100 inpt-select text-left" [ngClass]="getClassInpt()">
        <div class="rounded text-muted" [ngClass]="getClassContent()">
            <a *ngIf="disabled && !readonly" class="px-1 mr-1">
                <i class="fas fa-times fa-xs"></i>
            </a>
            <a *ngIf="!disabled && !readonly" class="px-1 mr-1" href="#"
                (click)="$event.preventDefault(); clean(instnc)" (keydown.backspace)="clean(instnc)">
                <i class="fas fa-times fa-xs"></i>
            </a>
            <span [title]="getSelectedTemplate(item)" [innerHTML]="getSelectedTemplate(item)"
                (dblclick)="clean(instnc)"></span>
        </div>
    </div>
</div>
<div class="input-group typeahead-input" [ngClass]="validItem(item) ? 'hidden' : 'visible'">
    <ng-template [ngIf]="openOnClick" [ngIfElse]="notOpen">
        <input *ngIf="search" type="text" style="border-radius: .25rem;" class="form-control w-100"
            [ngClass]="required && item_check != 'valid' ? 'required-on' : ''" [name]="name" [(ngModel)]="item"
            (ngModelChange)="changeItem($event, onChange, false)" [ngbTypeahead]="search"
            [resultTemplate]="template ? template : templateDefault" [resultFormatter]="formatter"
            [inputFormatter]="selected" #instnc="ngbTypeahead" placeholder="{{placeholder}}"
            (focus)="focus$.next($any($event).target.value)" (click)="click$.next($any($event).target.value)"
            [disabled]="disabled" [readonly]="readonly" [required]="required" />
        <i *ngIf="searching" class="fas fa-spinner fa-w-16 fa-spin fa-lg inpt-loader"></i>
    </ng-template>
    <ng-template #notOpen>
        <small *ngIf="searching" class="form-text text-muted">searching...</small>
        <input *ngIf="search" type="text" style="border-radius: .25rem;" class="form-control w-100"
            [ngClass]="required && item_check != 'valid' ? 'required-on' : ''" [name]="name" [(ngModel)]="item"
            (ngModelChange)="changeItem($event, onChange, false)" [ngbTypeahead]="search"
            [resultTemplate]="template ? template : templateDefault" [resultFormatter]="formatter"
            [inputFormatter]="selected" #instnc="ngbTypeahead" placeholder="{{placeholder}}" [disabled]="disabled"
            [readonly]="readonly" />
        <i *ngIf="searching" class="fas fa-spinner fa-w-16 fa-spin fa-lg inpt-loader"></i>
    </ng-template>
</div>
<ng-template #templateDefault let-r="result" let-t="term">
    <ngb-highlight [result]="getFormatterTemplate(r)" [term]="t"></ngb-highlight>
</ng-template>