import { Inject, Injectable } from "@angular/core";
import { NgbDateParserFormatter, NgbDatepickerI18n, NgbDateStruct } from "@ng-bootstrap/ng-bootstrap";

import * as moment from 'moment';

export const ohObjDateFormat = {
  format: "DD/MM/YYYY",
  formatTime: "DD/MM/YYYY HH:mm",
  I18n: {
    weekdays: ['Lu', 'Ma', 'Mi', 'Ju', 'Vi', 'Sa', 'Do'],
    mounts: ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'],
    smallMounts: ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul', 'Ago', 'Sep', 'Oct', 'Nov', 'Dic'],
    weekLabel: 'Sem'
  }
};

@Injectable()
export class NgbDateFRLanguajeFormatter extends NgbDatepickerI18n {

  objDate: any
  constructor(@Inject(ohObjDateFormat) objDate: any) {
    super();
    if (typeof objDate.I18n != 'string') {
      this.objDate = objDate;
    } else {
      this.objDate = ohObjDateFormat;
    }
  }

  getWeekdayLabel(weekday: number): string { return this.objDate.I18n['weekdays'][weekday - 1]; }
  getWeekLabel(): string { return this.objDate.I18n['weekLabel']; }
  getMonthShortName(month: number): string { return this.objDate.I18n['smallMounts'][month - 1]; }
  getMonthFullName(month: number): string { return this.objDate.I18n['mounts'][month - 1]; }
  getDayAriaLabel(date: NgbDateStruct): string { return `${date.day}-${date.month}-${date.year}`; }
  getWeekdayShortName(weekday: number): string { return this.objDate.I18n['weekdays'][weekday - 1]; }

}

@Injectable()
export class NgbDateFRParserFormatter extends NgbDateParserFormatter {

  objDate: any

  constructor(@Inject(ohObjDateFormat) objDate: any) {
    super();
    this.objDate = objDate
  }

  parse(value: string): NgbDateStruct {
    if (value) {
      let result = moment(value, this.objDate.format, true).isValid()
      if (result) {
        let _date = moment(value, this.objDate.format, true)
        let _tas = { year: parseInt(_date.format('YYYY'), 10), month: parseInt(_date.format('M'), 10), day: parseInt(_date.format('D'), 10) }
        return _tas
      }
    }
    return null
  }

  format(date: NgbDateStruct): string {
    let stringDate: string = ""
    if (date) {
      const jsDate = new Date(date.year, date.month - 1, date.day)
      stringDate = moment(jsDate).format(this.objDate.format)
    }
    return stringDate
  }
}