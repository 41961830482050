<div class="d-flex flex-wrap justify-content-between">
    <div class="pb-2 ml-3 mr-2 d-flex flex-wrap justify-content-start flex-grow-1">
        <div class="btn-group" style="display: inline-block">
            <button *ngIf="showFilter" class="btn btn-outline-info btn-sm oh_filter_contentAsterisk"
                [disabled]="disabled" type="button" (click)="openFilter()">
                <i aria-hidden="true" class="fa fa-filter"></i>
                <span class="oh_filter_viewA oh_filter_asterisk">
                    <ng-template [ngIf]="currentSize > 0">
                        <i aria-hidden="true" class="fas fa-asterisk"></i>
                    </ng-template>
                </span>
            </button>
            <button class="btn btn-outline-info btn-sm" type="button" (click)="refresh()" [disabled]="disabled"><i
                    aria-hidden="true" class="fas fa-redo"></i></button>
            <ng-template [ngIf]="currentSize > 0">
                <button class="btn btn-outline-danger btn-sm" type="button" (click)="filterEraseAll()"><i
                        aria-hidden="true" class="fas fa-eraser"></i></button>
            </ng-template>
        </div>
        <div *ngIf="filter" class="btn-group">
            <div class="oh_filter_viewBC" *ngIf="alertAtLeast">
                <span class="alert alert-warning p-1 ml-2 pl-2 pr-2" style="position: fixed;">
                    <i class="fas fa-exclamation-circle text-danger"></i> {{lang.atLeast1}} {{filter.requiredAtLeast}}
                    {{lang.atLeast2}}
                </span>
            </div>
            <div class="oh_filter_viewBC" *ngFor="let item of filter.fields | ohListObject">
                <ng-template [ngIf]="isVisible(item.object)">
                    <div class="btn btn-outline-info btn-sm"
                        [ngClass]="{'oh_filter_btnContent' : !item.object.closeFilter, 'oh_filter_btnContentClose' : item.object.closeFilter}"
                        [attr.disabled]="item.object.disabledFilter == true ? 'disabled' : null">
                        <span>{{item.object.label?(item.object.label+': '):''}}</span>
                        <span
                            [hidden]="!(!item.object.type || (item.object.type && item.object.type==''))">{{(item.object.descValue)?item.object.descValue:item.object.value}}</span>
                        <ng-template #popTitle>{{lang.list}}:</ng-template>
                        <ng-template #popDetail>
                            <div style="overflow: auto;max-height: 330px; padding: 0px;">
                                <table class="table table-striped table-bordered table-hover bg-white">
                                    <thead>
                                        <tr>
                                            <th class="p-1">
                                                <input *ngIf="item.object.value_desc" type="text"
                                                    class="form-control form-control-sm form-list-filter p-1"
                                                    name="valor_" placeholder="Valor"
                                                    [(ngModel)]="filtro_cab[item.object.value_desc]">
                                                <input *ngIf="!item.object.value_desc" type="text"
                                                    class="form-control form-control-sm form-list-filter p-1"
                                                    name="valor_" placeholder="Valor" [(ngModel)]="filtro_cab.value">
                                            </th>
                                            <th class="px-2 py-1" style="width: 1rem"><i
                                                    class="text-muted fas fa-eraser text-danger"></i></th>
                                        </tr>
                                    </thead>
                                    <tbody>

                                        <ng-template [ngIf]="item.object.isValueList">
                                            <tr *ngFor="let element of item.object.value; let i = index">
                                                <td class="px-2 py-1">{{element}}</td>
                                                <td class="px-2 py-1"><a href="#"
                                                        (click)="$event.preventDefault(); filterEraseItem(item.object, i)"><i
                                                            class="fas fa-eraser c-pointer text-danger"></i></a></td>
                                            </tr>
                                        </ng-template>

                                        <ng-template [ngIf]="!item.object.isValueList">
                                            <tr
                                                *ngFor="let element of item.object.value | ohFilterList: filtro_cab; let i = index">
                                                <td class="px-2 py-1">
                                                    <span
                                                        *ngIf="item.object.value_desc">{{element[item.object.value_desc]}}</span>
                                                    <span *ngIf="!item.object.value_desc">{{element.value}}</span>
                                                </td>
                                                <td class="px-2 py-1"><a href="#"
                                                        (click)="$event.preventDefault(); filterEraseItem(item.object, i)"><i
                                                            class="fas fa-eraser c-pointer text-danger"></i></a></td>
                                            </tr>
                                        </ng-template>

                                    </tbody>
                                </table>
                            </div>
                        </ng-template>
                        <span *ngIf="item.object.type=='list'" class="mr-1">
                            <!--[hidden]="item.object.type!='list'"-->
                            <i class="fas fa-list-ol" placement="bottom" [ngbPopover]="popDetail" #p="ngbPopover"
                                [popoverTitle]="popTitle" [autoClose]="'outside'"></i>
                        </span>
                        <span *ngIf="item.object.type=='date'">{{item.object.value | ohDate: formatDate}}</span>
                        <span *ngIf="item.object.type=='daterange'">[{{item.object.initValue | ohDate: formatDate}} -
                            {{item.object.endValue | ohDate: formatDate}}]</span>
                        <span *ngIf="item.object.type=='fechaRango'">[{{(item.object.initValue | date: 'dd/MM/yyyy') +
                            (checkDate(item) ? '' : ' - ' + (item.object.endValue | date: 'dd/MM/yyyy'))}}]</span>
                        <span
                            [hidden]="item.object.type!='decimalRango'">[{{item.object.initValue}}-{{item.object.endValue}}]</span>
                        <span [hidden]="item.object.type!='fecha_tiempo'">[{{item.object.value}}]</span>
                        <ng-template [ngIf]="item.object.closeFilter && !item.object.disabledFilter"><i
                                (click)="$event.preventDefault(); filterErase(item.key)" aria-hidden="true"
                                class="fas fa-eraser c-pointer text-danger ml-1"></i></ng-template>
                    </div>
                </ng-template>
            </div>
        </div>
    </div>
    <pagin *ngIf="obj_pagin != null" class="d-flex flex-wrap justify-content-end ml-auto" [length]="length"
        [obj_pagin]="obj_pagin" (list)="list.emit(); listPagin()" (listPagin)="listPagin()"></pagin>
</div>