import { Component } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Title } from '@angular/platform-browser';

import { NgForm } from '@angular/forms';
import { OHService } from 'src/app/tis.ohService';
import { shared } from 'src/environments/environmentShared';
import { ohStorage } from 'src/app/ohCore/services/storage/oh.storage';
import { UserServiceJPO, pSegusuarioClaveValidar, pSegusuarioClaveReestablecer, pSegusuarioPreguntasCatalogopadreObtener, pSegusuarioPreguntasValidar } from 'src/app/service/ind.userService';
import { environment } from 'src/environments/environment';

declare var AesUtil: any;

@Component({
	templateUrl: './inlo.passwordRestore.html'
})
export class INLOPasswordRestore {

	private userService: UserServiceJPO;
	private myStore: ohStorage;

	keyEmail: string;
	showRestore: boolean;
	userName: string;

	dateRestore: any;

	alerted: boolean = false;
	alertedMsj: string;

	questions: any = [];
	respuestas: any = {};
	catalogo_padre_preguntas_id: number;
	validarConRespuesta: string;
	existePreguntas: boolean = false;

	sizeW: number;
	sizeWidthImg: string;

	constructor(private router: Router, private ohService: OHService, private activatedRoute: ActivatedRoute, private title: Title) {

		this.userService = new UserServiceJPO(ohService);
		this.myStore = new ohStorage();

		this.dateRestore = {};

		this.respuestas = {
			respuesta1: null,
			respuesta2: null,
			respuesta3: null,
			respuesta4: null,
			respuesta5: null,
		}

		if (this.myStore.has("APM_DATA")) {
			this.router.navigate(['/Inlandnet']);
		}

		var key = this.activatedRoute.snapshot.queryParams.keyRestore;
		if (key) {
			this.catalogo_padre_preguntas_id = 47817;
			//this.segusuarioPreguntasCatalogopadreObtener(key);
			this.validRestore(key);
		}

	}

	ngOnInit() {
		this.title.setTitle(shared.proyect + " - Restablecer contraseña");
	}

	validRestore(key: string) {
		this.userService.segusuarioClaveValidar({
			Correo: key
		}, (resp: pSegusuarioClaveValidar) => {
			this.keyEmail = key;
			if (resp.estado == 1) {

				this.userName = resp.mensaje;

				if (environment.systemId == 4) {
					this.segusuarioPreguntasCatalogopadreObtener();
				} else {
					this.showRestore = true;
				}
			} else {
				if (resp.estado == 0) {
					this.ohService.getOH().getLoader().showError(resp.mensaje);
				} else {
					this.alerted = true;
					this.alertedMsj = resp.mensaje;
				}
			}
		})
	}

	segusuarioPreguntasCatalogopadreObtener() {
		this.userService.segusuarioPreguntasCatalogopadreObtener({
			Correo: this.keyEmail,
			catalogo_padre_id: this.catalogo_padre_preguntas_id
		}, (resp: pSegusuarioPreguntasCatalogopadreObtener[]) => {
			this.questions = resp;

			if (this.questions.length > 0) this.existePreguntas = true;
			if (this.questions.length == 0) {
				this.existePreguntas = false;
				this.showRestore = true;
			}
		});
	}

	validarPreguntas(frm: NgForm) {
		for (var r = 0; r < this.questions.length; r++) {
			if (r == 0) this.respuestas.respuesta1 = (this.questions[r].respuesta == undefined || this.questions[r].respuesta == '') ? null : this.questions[r].respuesta;
			if (r == 1) this.respuestas.respuesta2 = (this.questions[r].respuesta == undefined || this.questions[r].respuesta == '') ? null : this.questions[r].respuesta;
			if (r == 2) this.respuestas.respuesta3 = (this.questions[r].respuesta == undefined || this.questions[r].respuesta == '') ? null : this.questions[r].respuesta;
			if (r == 3) this.respuestas.respuesta4 = (this.questions[r].respuesta == undefined || this.questions[r].respuesta == '') ? null : this.questions[r].respuesta;
		}
		this.userService.segusuarioPreguntasValidar({
			Correo: this.keyEmail,
			respuesta1: this.respuestas.respuesta1,
			respuesta2: this.respuestas.respuesta2,
			respuesta3: this.respuestas.respuesta3,
			respuesta4: this.respuestas.respuesta4,
			respuesta5: this.respuestas.respuesta5,
		}, (resp: pSegusuarioPreguntasValidar) => {
			if (resp.estado == 1) {
				this.ohService.getOH().getAd().success(resp.mensaje);
				this.existePreguntas = false;
				this.showRestore = true;
			}
			else {
				this.ohService.getOH().getAd().warning(resp.mensaje);
				this.existePreguntas = true;
				this.showRestore = false;
			}
		});
	}

	change(frm: NgForm) {
		var passwordEncripted = new AesUtil(shared.key_send).encrypt(this.dateRestore.password);
		this.userService.segusuarioClaveReestablecer({
			Correo: this.keyEmail,
			Clave: passwordEncripted,
		}, (resp: pSegusuarioClaveReestablecer) => {
			if (resp.estado == 1) {
				frm.resetForm({});
				this.myStore.set("APM_RESTORE_SUCCESS", {
					mensaje: resp.mensaje,
					timeSeconds: 5,
					showClose: false
				});
				this.router.navigate(['/Login']);
			} else {
				if (resp.estado == 0) {
					this.ohService.getOH().getLoader().showError(resp.mensaje);
				} else {
					this.alerted = true;
					this.alertedMsj = resp.mensaje;
				}
			}
		});
	}

}