import { ModuleWithProviders } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { INLOLogin, INLORegister, INLOPasswordConfirm, INLOPasswordRestore } from './view/access/inlo.core';
import { Route } from '@angular/compiler/src/core';

const INLORoutes: Routes = [
  { path: '', redirectTo: 'Login', pathMatch: 'full' },
  { path: 'Login', component: INLOLogin },
  { path: 'Register', component: INLORegister },
  { path: 'PasswordConfirm', component: INLOPasswordConfirm },
  { path: 'PasswordRestore', component: INLOPasswordRestore },
  { path: 'Be', loadChildren: () => import('./ind.module').then(m => m.Inland) }
];

export const INLORouting: ModuleWithProviders<Route> = RouterModule.forRoot(INLORoutes, { useHash: true });