<div class="form-inline">
  <div class="form-group oh_daterange_formgroup hidden">
    <div class="input-group">
      <input name="datepicker"
             class="form-control"
             ngbDatepicker
             #datepicker="ngbDatepicker"
             [autoClose]="'outside'"
             (dateSelect)="onDateSelection($event, datepicker)"
             [displayMonths]="2"
             [dayTemplate]="t"
             outsideDays="hidden"
             [startDate]="fromDate!"
             tabindex="-1">
      <ng-template #t let-date let-focused="focused">
        <span class="oh_daterange_customday"
              [class.focused]="focused"
              [class.range]="isRange(date)"
              [class.faded]="isHovered(date) || isInside(date)"
              (mouseenter)="hoveredDate = date"
              (mouseleave)="hoveredDate = null">
          {{ date.day }}
        </span>
      </ng-template>
    </div>
  </div>
  <div class="form-group">
    <div class="input-group">
      <input #dpFromDate
             class="form-control oh_daterange_input" placeholder="yyyy-mm-dd"
             name="dpFromDate"
             [value]="formatter.format(fromDate)"
             (input)="fromDate = validateInput(fromDate, dpFromDate.value)">
      <div class="input-group-append">
        <button class="btn btn-outline-secondary" (click)="datepicker.toggle()" type="button"><i class="far fa-calendar-alt" aria-hidden="true"></i></button>
      </div>
    </div>
  </div>
  <div class="form-group ml-2">
    <div class="input-group">
      <input #dpToDate
             class="form-control oh_daterange_input" placeholder="yyyy-mm-dd"
             name="dpToDate"
             [value]="formatter.format(toDate)"
             (input)="toDate = validateInput(toDate, dpToDate.value)">
      <div class="input-group-append">
        <button class="btn btn-outline-secondary" (click)="datepicker.toggle()" type="button"><i class="far fa-calendar-alt" aria-hidden="true"></i></button>
      </div>
    </div>
  </div>
  </div>