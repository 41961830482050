import { Component, Input, Output, EventEmitter, ViewChild, Inject, Renderer2, ElementRef } from '@angular/core';
import { NgForm, NgModel } from '@angular/forms';
import { NgbDate, NgbCalendar, NgbDateParserFormatter, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { ohObjDateFormat } from './oh.date.provides';

@Component({
  selector: 'oh-daterange',
  templateUrl: './oh.dateRange.html',
  styleUrls: ['./oh.dateRange.css']
})
export class OhDateRange {

  @ViewChild('inp_date', { static: true }) inp_date: NgModel

  @Input()
  set from(valor: any) {
    if (valor) {
      let fecha = new Date(valor)
      this.fromDate = new NgbDate(fecha.getFullYear(), fecha.getMonth() + 1, fecha.getDate())
      this.mapInput()
      if (this.fromDate) {
        this.fromDateImp = this.fromDate
      }
    } else {
      this.fromDate = null
      this.dateValue = null
      if (!this.pendingChange) {
        this.fromDateImp = null
        this.pendingChange = false
      }
    }
  }
  @Output() fromChange: EventEmitter<Date>; // hace binding al padre.

  @Input()
  set to(valor: any) {
    if (valor) {
      let fecha = new Date(valor);
      this.toDate = new NgbDate(fecha.getFullYear(), fecha.getMonth() + 1, fecha.getDate())
      this.mapInput()
      if (this.toDate) {
        this.toDateImp = this.toDate
      }
    } else {
      this.toDate = null
      this.dateValue = null
      if (!this.pendingChange) {
        this.toDateImp = null
        this.pendingChange = false
      }
    }
  }
  @Output() toChange: EventEmitter<Date>; // hace binding al padre.

  @Output() onChange = new EventEmitter(); // llama a un evento

  @Input() type: string = "lineal" // lineal - modal

  @Input()
  set currentDate(currentDate: Date) {
    this._currentDate = currentDate
    this.mapRange()
  }
  _currentDate: Date

  @Input()
  set minRange(minRange: number) {
    this._minRange = minRange
    this.mapRange()
  }
  _minRange: number

  @Input()
  set maxRange(maxRange: number) {
    this._maxRange = maxRange
    this.mapRange()
  }
  _maxRange: number

  @Input()
  set minDate(minDate: Date) {
    this._minDate = minDate
    this.mapRange()
  }
  _minDate: Date

  @Input()
  set maxDate(maxDate: Date) {
    this._maxDate = maxDate
    this.mapRange()
  }
  _maxDate: Date

  _minFinalDate: NgbDateStruct
  _maxFinalDate: NgbDateStruct

  @Input()
  set range(range: number) {
    this._range = range
    this.mapRange()
  }
  _range: number

  @Input() form: NgForm
  @Input() name: any
  @Input() required: boolean
  @Input() disabled: boolean

  hoveredDate: NgbDate | null = null;
  fromDate: NgbDate | null;
  toDate: NgbDate | null;

  objDate: any

  @Input() markDisabled: any

  constructor(@Inject(ohObjDateFormat) objDate: any, private calendar: NgbCalendar, public formatter: NgbDateParserFormatter, private _renderer: Renderer2, private _elRef: ElementRef) {
    this.objDate = objDate
    this.fromChange = new EventEmitter<Date>()
    this.toChange = new EventEmitter<Date>()
  }

  ngOnInit() {
    this._renderer.setAttribute(this._elRef.nativeElement, 'daterange', '');
  }

  ngAfterViewInit() {
    if (this.required && this.form && this.inp_date) {
      this.inp_date.name = this.name || "inp_date";
      this.form.addControl(this.inp_date);
    }
  }

  mapRange() {

    let __currentDate = this._currentDate ? this._currentDate : new Date()

    if (__currentDate && (this._minRange || this._minDate)) {
      var dateMin = new Date()
      if (this._minDate) {
        dateMin = this._minDate
      } else {
        dateMin.setDate(__currentDate.getDate() - this._minRange)
      }
      if (dateMin.getUTCDate) {
        this._minFinalDate = { day: dateMin.getUTCDate(), month: dateMin.getUTCMonth() + 1, year: dateMin.getUTCFullYear() }
      }
    }

    if (__currentDate && (this._maxRange || this._maxDate)) {
      var dateMax = new Date();
      if (this._maxDate) {
        dateMax = this._maxDate
      } else {
        dateMax.setDate(__currentDate.getDate() + this._maxRange)
      }
      if (dateMax.getUTCDate) {
        this._maxFinalDate = { day: dateMax.getUTCDate(), month: dateMax.getUTCMonth() + 1, year: dateMax.getUTCFullYear() }
      }
    }

    if (this._range && this.fromDate) {

      let _fromDate = new Date(this.fromDate.year, this.fromDate.month - 1, this.fromDate.day)

      var dateMax = new Date();
      dateMax.setDate(_fromDate.getDate() + this._range)

      this._maxFinalDate = { day: dateMax.getUTCDate(), month: dateMax.getUTCMonth() + 1, year: dateMax.getUTCFullYear() }

    }

  }

  mapInput() {
    this.dateValue = null
    if (this.fromDate && this.toDate) {
      this.dateValue = this.fromDate + " " + this.toDate
    }
  }

  dateValue: any

  onDateSelection(date: NgbDate, datepicker: any) {
    console.log(date)
    console.log(this.fromDate)
    console.log(this.toDate)
    if (!this.disabled) {
      if (!this.fromDate && !this.toDate) {
        this.fromDate = date;
      } else if (this.fromDate && !this.toDate && date && (date.equals(this.fromDate) || date.after(this.fromDate))) {
        this.toDate = date;
      } else {
        this.toDate = null;
        this.fromDate = date;
      }

      if (this.fromDate && this.toDate && datepicker && datepicker.toggle) {
        datepicker.toggle()
      }

      this.selectDate()

      this.fromDateImp = JSON.parse(JSON.stringify(this.fromDate))
      this.toDateImp = JSON.parse(JSON.stringify(this.toDate))

    }

  }

  getFinalDate(__fromDate: NgbDate) {

    if (this._range && __fromDate) {

      let _fromDate = new Date(__fromDate.year, __fromDate.month - 1, __fromDate.day)

      var dateMax = new Date(_fromDate);
      dateMax.setDate(_fromDate.getDate() + this._range - 1)

      return { day: dateMax.getUTCDate(), month: dateMax.getUTCMonth() + 1, year: dateMax.getUTCFullYear() }

    }

  }

  selectDate() {
    this.dateValue = null
    if (this.fromDate && this.toDate) {
      this.dateValue = this.fromDate + " " + this.toDate
    }

    if (this._range && this.fromDate) {
      this._maxFinalDate = this.getFinalDate(this.fromDate)
    }

    this.fromChange.emit(this.fromDate ? new Date(this.fromDate.year, this.fromDate.month - 1, this.fromDate.day) : null)
    this.toChange.emit(this.toDate ? new Date(this.toDate.year, this.toDate.month - 1, this.toDate.day) : null)

    this.onChange.emit()
  }

  isHovered(date: NgbDate) {
    return this.fromDate && !this.toDate && this.hoveredDate && date.after(this.fromDate) && date.before(this.hoveredDate);
  }

  isInside(date: NgbDate) {
    return this.toDate && date.after(this.fromDate) && date.before(this.toDate);
  }

  isRange(date: NgbDate) {
    return date.equals(this.fromDate) || (this.toDate && date.equals(this.toDate)) || this.isInside(date) || this.isHovered(date);
  }

  validateInput(currentValue: NgbDate | null, input: string): NgbDate | null { // : NgbDate | null 
    const parsed = this.formatter.parse(input);
    return parsed && this.calendar.isValid(NgbDate.from(parsed)) ? NgbDate.from(parsed) : currentValue;
  }

  fromDateImp: any
  toDateImp: any
  pendingChange: boolean

  validateInputFrom(currentValue: NgbDate | null, input: string): NgbDate | null { // : NgbDate | null 
    const parsed = this.formatter.parse(input)

    if (parsed && this.calendar.isValid(NgbDate.from(parsed))) {

      let __from = NgbDate.from(parsed)

      if (!this.toDate || __from.before(this.toDate)) {

        if (this.toDate && this._range) {

          var __maxFinalDate = this.getFinalDate(__from)

          if (this.toDate.after(__maxFinalDate)) {
            this.toDate = null
            this.toDateImp = null
          }

        }

        this.fromDate = __from

      } else {
        this.fromDate = null
      }

      this.selectDate()
      this.pendingChange = true
      return __from

    } else {

      this.fromDate = null
      return currentValue

    }

  }

  validateInputTo(currentValue: NgbDate | null, input: string): NgbDate | null { // : NgbDate | null 
    const parsed = this.formatter.parse(input);

    if (parsed && this.calendar.isValid(NgbDate.from(parsed))) {
      let __to = NgbDate.from(parsed)

      if (!this.fromDate || __to.after(this.fromDate)) {

        if (this._range && this._maxFinalDate) {

          if (__to.before(this._maxFinalDate) || __to.equals(this._maxFinalDate)) {
            this.toDate = __to
          } else {
            this.toDate = null
          }

        } else {
          this.toDate = __to
        }

      } else {
        this.toDate = null
      }

      this.selectDate()
      this.pendingChange = true

      return __to
    } else {

      this.toDate = null

      return currentValue
    }

  }

  clean() {
    this.dateValue = null
    this.fromDate = null
    this.toDate = null
    this.fromChange.emit(null)
    this.toChange.emit(null)
    this.fromDateImp = null
    this.toDateImp = null
    this._maxFinalDate = null
  }

  isToday = (date: NgbDate) => {
    return date.equals(this.calendar.getToday())
  }

}