// node --max_old_space_size=5048 ./node_modules/@angular/cli/bin/ng build --prod --configuration=aps_qa
// npm run build -- --prod --configuration=aps_qa
// Reemplazar variable "version" y worker.js CURREMT = 'runtime-v1' por la nueva version
export const environment = {
  env: "production",
  production: true,
  protocol: "https",
  hostLocal: "appointmentqa.inlandservices.com/",
  host: {
    Inlandnet: "appointmentqa.inlandservices.com/api/inlandnet/",
    ModuleADM: "appointmentqa.inlandservices.com/api/moduleadm/",
    ModuleAPS: "appointmentqa.inlandservices.com/api/moduleaps/"
  },
  hostType: 2,
  hostAfter: "",
  rest: "/apmapi",
  service: "Business",
  host_local_port: "http://10.10.10.29:9997",
  firebase: {
    apiKey: "AIzaSyD_yWoiZzX9OrdHA_usxCRnpp1VYr_ZFGk",
    authDomain: "maersk-tc360.firebaseapp.com",
    databaseURL: "https://maersk-tc360.firebaseio.com",
    projectId: "maersk-tc360",
    storageBucket: "maersk-tc360.appspot.com",
    messagingSenderId: "1044890983067",
    appId: "1:1044890983067:web:de31420daaab56a94e443e",
    measurementId: "G-9J4SSBHGTQ"
  },
  firebase_coleccion_base: "MAERSK_TC360_QA",
  maps: {
    key: "AIzaSyDn4DYMQQDikiLOMgP1DJegHIFxUcUwAIY",
  },
  version: "1.0.0",
  systemId: 11,
  default_languaje: "en",
  azurecosmos: {
    endpoint: "https://maersk-apmtis-cosmosdb-aps-qa.documents.azure.com:443/",
    apiKey: "ibb5kFedoELyJx0Vb5wPFACI2ViuAcpVY4p7VC88LGj20b1ZsMYsTPjDeqt8p9IDnp4q0WiTgZtkyM19KlBbvg=="
  },
  azurestorage: {
    blobsasurl: "https://maerskapmtisstginlanddev.blob.core.windows.net/?sv=2020-08-04&ss=bfqt&srt=sco&sp=rwdlacupitfx&se=2026-12-31T08:26:09Z&st=2021-10-26T00:26:09Z&spr=https&sig=QbUQt2sq2I5tE92R2CyBNT7ssnCKE3XgTnodCkNyoSQ%3D"
  }
};