import { Component, Input, Output, EventEmitter, ElementRef, ViewChild, HostListener, SimpleChanges } from '@angular/core';
import { NgForm, NgModel } from '@angular/forms';

/*
<date-range [(field_date)]="ohfiltro.field.fecha_registro"></date-range>

<date-range [(start)]="fechafrom" [(finish)]="fechato" [changeMode]="true" (onChange)="algunaFuncion()"></date-range>
*/

@Component({
    selector: 'date-range',
    templateUrl: './dateRange.html',
    styleUrls: ['./dateRange.css']
})

export class DateRange {

    @Input() name: any = '_';
    @Input() form: NgForm;// form to append control when if required
    @Input() readonly: boolean;// determines if control is readonly
    @Input() disabled: boolean;// determines if control is disabled
    @Input() required: boolean;// determines if control is required
    @Input() start: any;
    @Input() finish: any;
    @Input() oneDay: boolean = false;
    @Input() mode: Number = 0;
    @Input() changeMode: boolean = true;
    @Input() field_date: any = {};

    @Output() startChange: EventEmitter<any>; // hace binding al padre.
    @Output() finishChange: EventEmitter<any>; // hace binding al padre.
    @Output() oneDayChange: EventEmitter<boolean>; // hace binding al padre.
    @Output() field_dateChange: EventEmitter<any>; // hace binding al padre.
    @Output() onChange = new EventEmitter(); // llama a un evento

    text_: any = '';
    initValue: any;
    endValue: any;
    mountsCount: number;
    collapsed: boolean = true;
    top: any = 'auto';
    left: any = 'auto';

    @ViewChild('inputFecha', { static: true }) inputFecha: ElementRef;
    @ViewChild('inputFecha', { static: false }) inp_hd: NgModel;
    //@ViewChild("ViewDetail", { static: true }) public view_detail: NgbModal;

    constructor() {
        this.startChange = new EventEmitter<any>();
        this.finishChange = new EventEmitter<any>();
        this.onChange = new EventEmitter();
        this.disabled = false;
        //this.changeMode = true;
        this.field_dateChange = new EventEmitter<boolean>();
    }

    ngOnInit() {
        if (!this.field_date) {
            this.field_date = {}
        }
        if (this.start) {
            this.initValue = this.start
        }
        if (this.finish) {
            this.endValue = this.finish
        }
        this.mode = this.mode == undefined || this.mode == null ? 0 : this.mode;
        this.changeMode = this.changeMode == undefined || this.changeMode == null ? true : this.changeMode;
        this.initValue = this.field_date.initValue;
        this.endValue = this.field_date.endValue;
        //this.oneDay = this.field_date.oneDay == undefined || this.field_date.oneDay == null ? false : this.field_date.oneDay;
        this.oneDay = (this.field_date && this.field_date.oneDay) ? this.field_date.oneDay : this.oneDay;
        this.setValues();
    }

    ngOnChanges(changes: SimpleChanges) {
        // console.log('changes:', changes)
        if (changes.required) {
            if (changes.required.previousValue == false && changes.required.currentValue == true && this.form) {
                this.inp_hd.name = this.name;
                this.form.addControl(this.inp_hd);
            }
            if (changes.required.previousValue == true && changes.required.currentValue == false && this.form) {
                this.inp_hd.name = this.name;
                this.form.removeControl(this.inp_hd);
            }
        }
        if (changes.start && changes.start.currentValue !== undefined && changes.start.currentValue !== null) {
            this.initValue = this.start
        }
        if (changes.finish && changes.finish.currentValue !== undefined && changes.finish.currentValue !== null) {
            this.endValue = this.finish
        }
        /*
        if (changes.finish && changes.start) {
            if (changes.finish.currentValue == null && changes.start.currentValue == null) {

            } else {

            }
        }*/
    }

    toogleDate() {
        this.setPositionCollapse();
        this.collapsed = !this.collapsed;
    }

    getClassInpt() {
        let _class = '';
        // if (this.disabled || this.readonly) _class += 'inpt-disabled ';
        if (Object.prototype.toString.call(this.required) == '[object Boolean]') {
            if (this.required && ((this.oneDay && this.initValue) || (!this.oneDay && this.initValue && this.endValue))) _class += 'required-off ';
            else _class += 'required-on ';
        }
        return _class;
    }

    setPositionCollapse() {
        this.top = 'auto';
        this.left = 'auto';
        let size_collapse = {
            x: 474,
            y: 267
        }
        // console.log('size_collapse:', size_collapse)
        // User screen size
        const screenHeight = window.screen.height;
        const screenWidth = window.screen.width;
        // window
        var windowHeight_ = window.innerHeight;
        var windowWidth_ = window.innerWidth;
        // Actual space available in navigator
        const actualHeight = window.innerHeight;
        const actualWidth = window.innerWidth;
        // console.log('screen:', screenHeight)
        // console.log('windowHeight_:', windowHeight_)
        // console.log('actualHeight:', actualHeight)

        // console.log('screen:', screenWidth)
        // console.log('windowWidth_:', windowWidth_)
        // console.log('actualWidth:', actualWidth)

        const parentModalBody = this.inputFecha.nativeElement.closest('.modal-body');
        // console.log('parentModalBody:', parentModalBody)
        const div_parent = this.inputFecha.nativeElement.closest('div');
        // console.log('div_parent:', div_parent)
        const date_range = this.inputFecha.nativeElement.closest('date-range');
        // console.log('date_range:', date_range);                
        // Get reference element position.
        let rect = this.inputFecha.nativeElement.getBoundingClientRect();
        let rect_date = date_range.getBoundingClientRect();

        // console.log('rect:', rect)
        // console.log('rect_date:', rect_date)
        if (parentModalBody) {
            this.left = rect.x + 'px';
            if (windowHeight_ - (rect.y + 34) > size_collapse.y) {
                this.top = (rect.y + 34) + 'px';
            } else {
                this.top = (rect.y - size_collapse.y) + 'px';
            }
        }
    }

    focusOut() {
        this.collapsed = true;
    }

    @HostListener('window:resize', ['$event'])
    onResize(event) {
        this.setPositionCollapse();
    }

    @HostListener('window:scroll', ['$event']) // for window scroll events
    onWScroll(event) {
        this.setPositionCollapse();
    }

    @HostListener('scroll', ['$event']) // for window scroll events
    onScroll(event) {
        this.setPositionCollapse();
    }

    // @HostListener('div.modal-body:scroll', ['$event']) // for window scroll events
    // onScrollModal(event) {
    //     this.setPositionCollapse();
    // }

    setValues() {
        this.field_date.initValue = this.initValue;
        this.field_date.endValue = this.endValue;
        this.field_date.oneDay = this.oneDay;
        this.start = this.initValue;
        this.finish = this.endValue;
        this.startChange.emit(this.start);
        this.finishChange.emit(this.finish);
        this.field_dateChange.emit(this.field_date);
        this.onChange.emit();
    }

    changeRange() {
        if (this.initValue && this.endValue) {
            this.collapsed = !this.collapsed;
            this.setValues();
        }
    }

    checkInput(event) {
        if (event.target.value == '') {
            this.initValue = null;
            this.endValue = null;
            this.setValues();
        }
    }

    changingMode() {
        this.oneDay = !this.oneDay;
        if (this.oneDay) {
            this.endValue = this.initValue;
            this.setValues();
        }
    }
}
