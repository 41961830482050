import { Directive, forwardRef, Attribute, Input } from '@angular/core';
import { Validator, AbstractControl, NG_VALIDATORS } from '@angular/forms';
import { shared } from 'src/environments/environmentShared';

declare var AesUtil: any;
//import '../../js/CryptoJS/aes.js';
//import '../../js/CryptoJS/pbkdf2.js';
//import '../../js/CryptoJS/aesUtil.js';

@Directive({
    selector: '[ohValidateEqual]',
    providers: [
        { provide: NG_VALIDATORS, useExisting: forwardRef(() => ValidateEqual), multi: true }
    ]
})
export class ValidateEqual implements Validator {

    constructor( @Attribute('ohValidateEqual') public validateEqual: string, @Attribute('reverse') public reverse: string) {
    }

    private get isReverse() {
        if (!this.reverse) return false;
        return this.reverse === 'true' ? true: false;
    }

    validate(c: AbstractControl): { [key: string]: any } {
        
        let v = c.value;

        let e = c.root.get(this.validateEqual);
        // value not equal
        if (e && v !== e.value && !this.isReverse) {
          return {
            validateEqual: false
          }
        }

        // value equal and reverse
        if (e && v === e.value && this.isReverse) {
            delete e.errors['validateEqual'];
            if (!Object.keys(e.errors).length) e.setErrors(null);
        }

        // value not equal and reverse
        if (e && v !== e.value && this.isReverse) {
            e.setErrors({
                validateEqual: false
            })
        }

        return null;
    }
}

@Directive({
    selector: '[ohValidateEqualObj]',
    providers: [
        { provide: NG_VALIDATORS, useExisting: forwardRef(() => ValidateEqualObj), multi: true }
    ]
})
export class ValidateEqualObj implements Validator {

    constructor( @Attribute('ohValidateEqualObj') public validateEqualObj: string, @Attribute('reverse') public reverse: string) {
    }

    private get isReverse() {
        if (!this.reverse) return false;
        return this.reverse === 'true' ? true: false;
    }

    validate(c: AbstractControl): { [key: string]: any } {
        
        let v = c.value;

        let e = c.root.get(this.validateEqualObj);
        // value not equal
        if (e && v !== e.value && !this.isReverse) {
            return {
                validateEqualObj: true
            }
        }

        // value equal and reverse
        if (e && v === e.value && this.isReverse) {
            return {
                validateEqualObj: true
            }
        }

        return null;
    }
}


@Directive({
    selector: '[ohValidateEqualStr]',
    providers: [
        { provide: NG_VALIDATORS, useExisting: forwardRef(() => ValidateEqualNcStr), multi: true }
    ]
})
export class ValidateEqualNcStr implements Validator {

    @Input('ohValidateEqualStr') validateEqualStr: string;

    constructor(@Attribute('reverse') public reverse: string) {}

    private get isReverse() {
        if (!this.reverse) return false;
        return this.reverse === 'true' ? true: false;
    }

    validate(c: AbstractControl): { [key: string]: any } {
        let v = c.value;
        if(v){
            if (!this.isReverse && this.validateEqualStr && v !== this.validateEqualStr) return {
                validateEqualStr: false
            }
            if (this.isReverse && this.validateEqualStr && v === this.validateEqualStr) return {
                validateEqualStr: false
            }
        }

        return null;
    }
    
}

@Directive({
    selector: '[ohValidateEqualNc]',
    providers: [
        { provide: NG_VALIDATORS, useExisting: forwardRef(() => ValidateEqualNc), multi: true }
    ]
})
export class ValidateEqualNc implements Validator {

    @Input('ohValidateEqualNc') validateEqualNc: string;

    constructor(@Attribute('reverseNc') public reverseNc: string) {}

    private get isReverseNc() {
        if (!this.reverseNc) return false;
        return this.reverseNc === 'true' ? true: false;
    }

    validate(c: AbstractControl): { [key: string]: any } {
        let v = new AesUtil(shared.key_send).encrypt(c.value);
        if (!this.isReverseNc && this.validateEqualNc && v !== this.validateEqualNc) return {
            validateEqualNc: false
        }
        if (this.isReverseNc && this.validateEqualNc && v === this.validateEqualNc) return {
            validateEqualNc: false
        }
        return null;
    }
    
}

@Directive({
    selector: '[ohValidateObjectType]',
    providers: [
        { provide: NG_VALIDATORS, useExisting: forwardRef(() => ValidateObjectType), multi: true }
    ]
})
export class ValidateObjectType implements Validator {

    @Input('ohValidateObjectType') validateObjectType: any;

    validate(c: AbstractControl): { [key: string]: any } {

        let obj = c.value;

        if(obj && this.validateObjectType){

            //let format = JSON.parse(this.validateObjectType);
            var format : any = this.validateObjectType;

            try {
                let total = 0;
                let correcto = 0;

                for(var elem in format){
                    total++;
                    if(obj[elem] && typeof(obj[elem]) == format[elem]){
                        correcto++;
                    }
                }

                if(total == correcto){
                    return null;
                } else {
                    return {
                        validateObjectType: true
                    }
                }

            } catch(e){
                return {
                    validateObjectType: true
                }
            }

        } else {

            return null;

        }

    }
    
}

@Directive({
    selector: '[ohValidateMaxValue]',
    providers: [
        { provide: NG_VALIDATORS, useExisting: forwardRef(() => ValidateMaxValue), multi: true }
    ]
})
export class ValidateMaxValue implements Validator {


    @Input('ohValidateMaxValue') validateMaxValue: number;

    constructor() {
    }

    validate(c: AbstractControl): { [key: string]: any } {
        if (this.validateMaxValue && c && c.value > this.validateMaxValue) {
          return {
            validateEqual: false
          }
        }
        return null;
    }
    
}

@Directive({
    selector: '[ohValidateMinValue]',
    providers: [
        { provide: NG_VALIDATORS, useExisting: forwardRef(() => ValidateMinValue), multi: true }
    ]
})
export class ValidateMinValue implements Validator {


    @Input('ohValidateMinValue') validateMinValue: number;

    constructor() {
    }

    validate(c: AbstractControl): { [key: string]: any } {
        if (this.validateMinValue && c && c.value < this.validateMinValue) {
          return {
            validateEqual: false
          }
        }
        return null;
    }
    
}