export * from './oh.leftMenu';
export * from './oh.right';
export * from './oh.topAd';
export * from './oh.transition';
export * from './oh.fadeShow';

// import { ohTransition, ohTopAd } from './../ohCore/animaciones/oh.core';

/*
@Component({
  animations: [ohTransition, ohTopAd],
})
*/

/*
  <div *ngIf="abrirMenu" class="menFon" [@ohTransition]></div>
  <div class="collapse menuIzquierda" *ngIf="abrirMenu" [@ohTopAd]></div>
*/