import { trigger, animate, transition, style, state } from '@angular/animations';

export const ohFadeShow =
  trigger('ohFadeShow', [
    state('in', style({ opacity: 1 })),
    transition(':enter', [
        style({ opacity: 0 }),
        animate(600)
    ]),
    transition(':leave',
        animate(500, style({ opacity: 0 })))
  ])