<div #tisContent class="ind-body-maxContent">
	<div class="container mb-5">
		<div class="row justify-content-md-center">
			<div class="col">
				<div class="text-center">
					<div class="img_logo_01_svg mt-2 mb-4"></div>
				</div>
				<h5 class="card-title text-white mb-2">Confirmación de usuario</h5>
				<div class="mt-3">
					<button type="button" class="btn btn-secondary" [routerLink]="['/Login']"><i class="fas fa-reply"></i> Regresar al login</button>
				</div>
			</div>
		</div>
	</div>
</div>