import { Component } from '@angular/core';
import { CoreService } from 'src/app/ind.coreService';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'ind-foot',
  templateUrl: './ind.foot_aps.html'
})
export class Foot {

  version: string;
  currentDate = new Date();
  title: string;
  constructor(public cse: CoreService) {
    this.version = environment.version;
  }

}