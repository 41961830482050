import { Injectable } from "@angular/core"
import { environment } from "src/environments/environment"

declare var azureStorage: any

@Injectable({
    providedIn: 'root',
})
export class INDAzureStorage {

    private _azureStorage: any
    private _isEnabled: boolean

    constructor() {
        this._isEnabled = azureStorage && environment['azurestorage'] ? true : false
        if (this.isEnabled()) {
            this._azureStorage = new azureStorage()
            this._azureStorage.instance(environment['azurestorage'].blobsasurl)
        }
    }

    isEnabled() {
        return this._isEnabled
    }

    public createContainer(container: string) {
        return this._azureStorage.createContainer(container)
    }

    public deleteContainer(container: string) {
        return this._azureStorage.deleteContainer(container)
    }

    public listFiles(container: string) {
        return this._azureStorage.listFiles(container)
    }

    public uploadItems(container: string, files: any) {
        return this._azureStorage.uploadItems(container, files)
    }

    public getItem(container: string, file: string) {
        return this._azureStorage.getItem(container, file)
    }

    public download(container: string, file: string) {
        return this._azureStorage.download(container, file)
    }

    public deleteItems(container: string, files: any) {
        return this._azureStorage.deleteItems(container, files)
    }

}