<!-- <ng-template [ngIf]="show_var"> -->
<ng-template [ngIf]="show_var_aux">
  <div class="oh_loader_A">
    <div class="ohCore-body-content">
      <div class="ohCore-body-center">
        <div class="ohCore-body-font">
          <div class="ohCore-body-img"></div>
          <br />
          <br />
          <span>{{lang.loading}}</span>
        </div>
      </div>
    </div>
  </div>
</ng-template>
<div *ngIf="ohCarg.show && ohCarg.view!='C'" [@ohTransition] class="oh_loader_A">
  <div class="row content justify-content-center align-items-center">
    <div class="col align-self-center col-auto">
      <div class="oh_loader_AA">
        <div class="oh_loader_AAA">
          <div [hidden]="ohCarg.view!='C'" class="oh_loader_AAAA">
            <div class="ohCore-body-img"></div>
            <br />
            {{lang.loading}}
          </div>
          <div [hidden]="ohCarg.view!='E'">
            <a href="#" title="Cerrar" (click)="closeModal($event)" class="text-danger oh_loader_AAAB text-size-15i"><i
                class="fas fa-times-circle"></i></a>
            <i class="fas fa-tools fa-4x text-danger oh_carga_Bor"></i>
            <div style="text-align: left">
              {{lang.opserror}}:
              <br />
              <span class="text-muted font-italic">{{lang.errorNumber}} : {{ohCarg.errorCodigo}}</span>
              <div>
                <textarea class="form-control" rows="6">{{ohCarg.error}}</textarea>
                <span><a href="mailto:{{ohCarg.emailAdm}}?subject={{ohCarg.title}}&body={{getBodyError(ohCarg.error)}}"
                    data-rel="external">{{lang.sendMailIT}}</a>
                </span>
              </div>
            </div>
          </div>
          <div [hidden]="ohCarg.view!='F'">
            <a href="#" title="Cerrar" (click)="closeModal($event)" class="text-danger oh_loader_AAAB text-size-15i"><i
                class="fas fa-times-circle"></i></a>
            <i class="fas fa-hammer fa-4x text-primary oh_carga_Bor"></i>
            <div style="text-align: left">
              <div class="text-left mb-2">
                <strong>{{lang.errorTitle}}</strong>
              </div>
              <p class="text-justify">{{lang.errorDetail}}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>