import { Directive, ElementRef, Input, HostListener, Attribute } from '@angular/core';

/* Implementar en el encabezado de las listas

    <div class="tis-listContent mb-3 noImpresion" ohAutoSizeMap>
        ...
    </div>

   Implementar en las listas

  <div class="container-fluid">
      <div class="tableContent" [ohAutoSize]="-22">
          <table class="table table-striped table-hover bg-white">
          ...
      ...
  ...

*/

@Directive({
  selector: '[ohAutoSize]'
})

export class AutoSize {
    
  @Input('ohAutoSize') ohAutoSize: number = 0; // 

  constructor(private elemento: ElementRef, @Attribute('type') public typeFormat: string) {
  }
    
  ngAfterViewInit(){
    setTimeout(() => {
      this.binder()
    })
  }

	@HostListener('window:resize', ['$event'])
	onResize(event) {
    this.binder()
  }
  
  binder(){
    let maps = document.querySelectorAll('*[ohautosizemap]');

    if(!this.typeFormat || this.typeFormat == "height"){

      let monto_diferencial = 0
      if(maps && maps.length>0){
        
        for(var i in maps){
          monto_diferencial += maps[i]['offsetHeight'] ? maps[i]['offsetHeight'] : 0
        }
      }
      let ancho = window.innerHeight - monto_diferencial + this.ohAutoSize
      this.elemento.nativeElement.style.height = ancho+"px"

    } else if(this.typeFormat == "width"){

      // window.innerWidth
    }
  }

}