<div #tisContent class="ind-body-maxContent">
	<div class="container mb-5">
		<div class="row justify-content-md-center">
			<div class="col">
				<div class="text-center">
					<img class="px-3 pt-4 pb-3 mw-100" style="margin-bottom: -25px; margin-top:-15px;" width="300"
						src="assets/img/logo-maersk.png">
				</div>
				<h5 class="card-title text-white mb-2">Reestablecimiento de contraseña</h5>

				<form *ngIf="existePreguntas" #frmValidateQuestions="ngForm" class="form-enable"
					(ngSubmit)="validarPreguntas(frmValidateQuestions)">
					<h6 class="card-title text-white mb-2">Hola {{userName}}, responda las preguntas de seguridad, para
						poder continuar</h6>
					<div class="card">
						<div class="card-body">
							<div *ngFor="let item of questions; let i = index">
								<div class="form-group row">
									<label class="col-sm-4 col-form-label">{{item.descripcion}}<span style="color:red">
											*</span></label>
									<div class="col-sm-8">
										<input id="pregunta_{{i}}" name="pregunta_{{i}}" #pregunta="ngModel"
											[(ngModel)]="item.respuesta" class="form-control"
											placeholder="{{item.descripcion}}" minlength="1" pattern="[A-Za-z0-9]*"
											[ohInputFormat]="'upper'" required>
										<div [hidden]="pregunta.valid || pregunta.pristine" class="alert alert-danger">
											<div [hidden]="!pregunta.hasError('required')">Respuesta es requerido</div>
											<div [hidden]="!pregunta.hasError('pattern')">Respuesta debe contener solo
												letras y números</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="mt-3">
						<button type="submit" class="btn btn-primary mr-1" [disabled]="frmValidateQuestions.invalid"><i
								class="far fa-save"></i> Validar</button>
						<button type="button" class="btn btn-secondary mr-1"
							(click)="frmValidateQuestions.resetForm({});"><i class="fas fa-eraser"></i> Limpiar</button>
						<button type="button" class="btn btn-secondary" [routerLink]="['/Login']"><i
								class="fas fa-reply"></i> Regresar al login</button>
					</div>
				</form>

				<form *ngIf="showRestore && !existePreguntas" #frmRestore="ngForm" class="form-enable"
					(ngSubmit)="change(frmRestore)">
					<h6 class="card-title text-white mb-2">Hola {{userName}}, ingresa una nueva clave</h6>
					<div class="card">
						<div class="card-body">
							<div class="form-group row">
								<label class="col-form-label col-sm-4">
									<span>Clave</span>
								</label>
								<div class="col-sm-8">
									<input #inpPassword="ngModel" autocomplete="new-password" name="inpPassword"
										class="form-control" [(ngModel)]="dateRestore.password" type="password"
										required="true"
										pattern="^(?=.*[\d])(?=.*[A-Z])(?=.*[a-z])(?=.*[!\-_@#$%^&*])[\w!\-_@#$%^&*]{8,}$">
									<div *ngIf="inpPassword.invalid && (inpPassword.dirty || inpPassword.touched)"
										class="alert alert-danger alert-form">
										<ul>
											<li *ngIf="inpPassword.errors.required">El campo es requerido.</li>
											<li *ngIf="inpPassword.errors.pattern">La clave debe contener por lo menos
												<ul>
													<li>8 caracteres</li>
													<li>1 número</li>
													<li>1 caracter minúscula</li>
													<li>1 caracter mayúscula</li>
													<li>1 caracter especial (! - _ @ # $ % ^ & *)</li>
												</ul>
											</li>
										</ul>
									</div>
								</div>
							</div>
							<div class="form-group row">
								<label class="col-form-label col-sm-4">
									<span>Repetir clave</span>
								</label>
								<div class="col-sm-8">
									<input #inpRepeatPassword="ngModel" autocomplete="new-password"
										name="inpRepeatPassword" class="form-control"
										[(ngModel)]="dateRestore.repeatPassword" type="password" required="true"
										ohValidateEqual="inpPassword">
									<div *ngIf="inpRepeatPassword.invalid && (inpRepeatPassword.dirty || inpRepeatPassword.touched)"
										class="alert alert-danger alert-form">
										<ul>
											<li *ngIf="inpRepeatPassword.errors.required">El campo es requerido.</li>
											<li *ngIf="!inpRepeatPassword.errors.validateEqual">Debes repetir la misma
												clave.</li>
										</ul>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="mt-3">
						<button type="submit" class="btn btn-primary mr-1" [disabled]="frmRestore.invalid"><i
								class="far fa-save"></i> Cambiar</button>
						<button type="button" class="btn btn-secondary mr-1" (click)="frmRestore.resetForm({});"><i
								class="fas fa-eraser"></i> Limpiar</button>
						<button type="button" class="btn btn-secondary" [routerLink]="['/Login']"><i
								class="fas fa-reply"></i> Regresar al login</button>
					</div>
				</form>
				<p class="mt-2" *ngIf="alerted">
					<ngb-alert type="warning" [dismissible]="false">
						<strong><i class="fas fa-exclamation-triangle"></i></strong> {{alertedMsj}}
					</ngb-alert>
				</p>
				<div class="mt-3" *ngIf="!showRestore && !existePreguntas">
					<button type="button" class="btn btn-secondary" [routerLink]="['/Login']"><i
							class="fas fa-reply"></i> Regresar al login</button>
				</div>
			</div>
		</div>
	</div>
</div>