import { Component, Input } from '@angular/core';

declare var JSONEditor: any;

/*
<oh-previewObject [object]="{}" [modes]="['code']" width="100%" height="200px"></oh-previewObject>
 */
@Component({
  selector: 'oh-previewObject',
  templateUrl: './oh.previewObject.html'
})
export class PreviewObject {

	private precarga : Promise<any>;

	@Input() width : string = "100%"
	@Input() height : string = "400px"

	@Input()
    set object(object : any) {
		if(object){
			this._object = object;
			Promise.all([this.precarga]).then(values => {
				this.cargar();
			});
		}
	}
	private _object : string;

	@Input()
    set modes(modes : any) {
		if(modes){
			this._modes = modes;
			Promise.all([this.precarga]).then(values => {
				this.cargar();
			});
		}
	}
	private _modes : any = ["tree", "view", "code", "text"];

	constructor(){}

	ngAfterViewInit() {
		this.precarga = new Promise<void>((resolve, reject) => {
			resolve();
		});
	}

	private cargar(){
		let objEditor = document.getElementById("jsonEditor");
		if(objEditor){
			objEditor.remove();
		}
		let content : any = document.getElementById("previewContent");
		if(content){
			let editor = document.createElement('div');
				editor.id = "jsonEditor";
				editor.style.width = this.width
				editor.style.height = this.height
				content.appendChild(editor);
		}
		var jsonEdit = new JSONEditor(document.getElementById("jsonEditor"), { modes: this._modes });
			jsonEdit.set(this._object);
	}

}