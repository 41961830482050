import { Component, ViewChild, Input } from '@angular/core';
import { NgbDatepicker, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
	templateUrl: './oh.datetimeModal.html'
})
export class DatetimeModal {

	errorMin: boolean = false;
	errorMax: boolean = false;
	@Input() fecha: any;
	@Input() min_date: any;
	@Input() max_date: any;
	@Input() hora: any
	@Input() onlyDate: boolean;

	@ViewChild("dp", { static: false }) dp: NgbDatepicker;

	constructor(public activeModal: NgbActiveModal) {

	}

	ngAfterViewInit() {
		setTimeout(() => {
			this.dp.navigateTo(this.fecha);
		});
	}

	guardar() {
		this.activeModal.close(this.obtenerFecha())
	}

	cancelar() {
		this.activeModal.dismiss("SALIR")
	}

	seleccionarEnter(event) {
		if (event.key === "Enter") {
			this.guardar();
		}
	}

	validHour() {
		if (this.min_date && this.hora) {
			if (this.min_date.hour != undefined && this.min_date.minute != undefined) {
				let min_ = this.pad(this.min_date.year) + '' + this.pad(this.min_date.month) + '' + this.pad(this.min_date.day) + '' + this.pad(this.min_date.hour) + '' + this.pad(this.min_date.minute)
				let slc_ = this.pad(this.fecha.year) + '' + this.pad(this.fecha.month) + '' + this.pad(this.fecha.day) + '' + this.pad(this.hora.hour) + '' + this.pad(this.hora.minute)
				// console.log('min_:', parseInt(min_))
				// console.log('slc_:', parseInt(slc_))
				this.errorMin = parseInt(min_) >= parseInt(slc_);
				if (parseInt(min_) >= parseInt(slc_)) {
					this.hora = {};
					this.hora = null;
				}
			}
		}
		if (this.max_date && this.hora) {
			if (this.max_date.hour != undefined && this.max_date.minute != undefined) {
				let slc_ = this.pad(this.fecha.year) + '' + this.pad(this.fecha.month) + '' + this.pad(this.fecha.day) + '' + this.pad(this.hora.hour) + '' + this.pad(this.hora.minute)
				let max_ = this.pad(this.max_date.year) + '' + this.pad(this.max_date.month) + '' + this.pad(this.max_date.day) + '' + this.pad(this.max_date.hour) + '' + this.pad(this.max_date.minute)
				// console.log('slc_:', parseInt(slc_))
				// console.log('max_:', parseInt(max_))
				this.errorMax = parseInt(slc_) >= parseInt(max_);
				if (parseInt(slc_) >= parseInt(max_)) {
					this.hora = {};
					this.hora = null;
				}
			}
		}
	}

	pad(s) { return (s < 10) ? '0' + s : s; }

	obtenerFecha() {
		let fecha_hora: Date;
		if (this.onlyDate) {
			if (typeof this.fecha.getFullYear === "function") {
				fecha_hora = new Date(this.fecha.getFullYear(), this.fecha.getMonth(), this.fecha.getDate());
			} else {
				fecha_hora = new Date(this.fecha.year, this.fecha.month - 1, this.fecha.day);
			}
		} else {
			if (typeof this.fecha.getFullYear === "function") {
				fecha_hora = new Date(this.fecha.getFullYear(), this.fecha.getMonth(), this.fecha.getDate(), this.hora.c, this.hora.minute, 0);
			} else {
				fecha_hora = new Date(this.fecha.year, this.fecha.month - 1, this.fecha.day, this.hora.hour, this.hora.minute, 0);
			}
		}
		return fecha_hora;
	}

}
