import { Component, Input, SimpleChanges, EventEmitter, Output, ViewChild, ElementRef, ChangeDetectorRef } from '@angular/core';
// import { DragulaService } from 'ng2-dragula';
// import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';

/**
 * Implementation Examples
 * 
 * Uso normal:
 * <oh-multi-input name="inp_refs" placeholder="" [(array)]="solicitud.referencias"></oh-multi-input>
 * 
 * 
 * Para filtros usar tipo = 1, para devolver formato necesario (Array(Object))
 * <oh-multi-input name="imp_docs" [(array)]="filter.field.documentos.value" type="1" placeholder="BK / BL"></oh-multi-input>
 * 
 *  
 * Usando mascara:
 * <oh-multi-input name="inp_refs" placeholder="Ref. 1 y 2" [(array)]="solicitud.referencias" max="2" [mask]="['1º','2º']"></oh-multi-input> * 
 * 
 */

@Component({
    selector: 'oh-multi-input',
    styleUrls: ['./oh.multiInput.css'],
    templateUrl: './oh.multiInput.html',
})

export class multiInput {
    // @ViewChild(CdkDropListGroup, { static: false }) listGroup: CdkDropListGroup<CdkDropList>;
    // @ViewChild(CdkDropList, { static: false }) placeholder_list: CdkDropList;

    // public items: Array<number> = [1, 2, 3, 4, 5, 6, 7, 8, 9];
    // public target: CdkDropList;
    // public targetIndex: number;
    // public source: CdkDropListContainer;
    // public sourceIndex: number;


    @ViewChild('inp_value', { static: true }) inp_value: ElementRef;

    newValue: string = '';
    nameDrag: string = 'dgValue';
    // subs = new Subscription();

    @Input() min: number = -1;
    @Input() max: number = -1;
    @Input() name: any;
    @Input() readonly: boolean = false;// determines if control is readonly
    @Input() disabled: boolean = false;// determines if control is disabled
    @Input() required: boolean = false;// determines if control is required
    @Input() placeholder: string = '...';// placeholder for Input
    @Input() type: any = 0; // 1 filter, 0 array
    @Input() mask: any[] = [];// data for Input
    @Input() repeatmask: boolean = false;// determines if control is readonly
    @Input() array: any[] = [];// data for Input
    @Output() arrayChange: EventEmitter<any>;
    @Input() value: any;// value to return
    @Output() valueChange: EventEmitter<any>;
    @Output() onEnter: EventEmitter<any>;
    // @Output() onEnter: EventEmitter<any> = new EventEmitter();

    constructor(/*private dragulaService: DragulaService*/) {
        // this.target = null;
        // this.source = null;

        this.arrayChange = new EventEmitter<any>();
        this.valueChange = new EventEmitter<any>();
        this.onEnter = new EventEmitter();
        // dragulaService.createGroup(this.nameDrag, {
        //     // copy: (el) => {},
        //     // copyItem: (item: any) => {},
        //     // accepts: (el, target, source, sibling) => {},
        //     // moves: (el, container, handle) => {}
        // });

        // this.subs.add(this.dragulaService.dropModel(this.nameDrag).subscribe(({ el, target, source, sourceModel, targetModel, item }) => {
        //     // console.log('targetModel:', JSON.parse(JSON.stringify(targetModel)));
        //     // console.log('sourceModel:', JSON.parse(JSON.stringify(sourceModel)));
        //     this.array = sourceModel;
        //     this.emitValues();
        // }));
    }

    ngOnInit() {
        this.array = this.array ? this.array.length ? this.array : [] : [];
    }

    // ngAfterViewChecked() {
    //     console.log("! changement de la date du composant !");
    //     console.log('this.array:', this.array)
    //     this.emitValues();
    //     this.cdRef.detectChanges();
    // }

    ngAfterViewInit() {
        setTimeout(() => {
            this.emitValues();
        })
        // let phElement = this.placeholder_list.element.nativeElement;
        // phElement.style.display = 'none';
        // phElement.parentNode.removeChild(phElement);
    }

    ngOnDestroy() {
        // this.dragulaService.destroy(this.nameDrag);
        // this.subs.unsubscribe();
    }

    ngOnChanges(changes: SimpleChanges) {

    }

    // drop() {
    //     if (!this.target)
    //         return;

    //     let phElement = this.placeholder_list.element.nativeElement;
    //     let parent = phElement.parentNode;

    //     phElement.style.display = 'none';

    //     parent.removeChild(phElement);
    //     parent.appendChild(phElement);
    //     parent.insertBefore(this.source.element.nativeElement, parent.children[this.sourceIndex]);

    //     this.target = null;
    //     this.source = null;

    //     if (this.sourceIndex != this.targetIndex)
    //         moveItemInArray(this.items, this.sourceIndex, this.targetIndex);
    // }


    // enter = (drag: CdkDrag, drop: CdkDropList) => {
    //     // console.log(drag, drop)
    //     // if (drop == this.placeholder_list)
    //     //     return true;
    //     // console.log('drop:', drop)

    //     let phElement = this.placeholder_list.element.nativeElement;
    //     // console.log('phElement:', phElement)
    //     let dropElement = drop.element.nativeElement;
    //     // console.log('dropElement:', dropElement)

    //     if (dropElement.parentNode) {

    //         let dragIndex = this.__indexOf(dropElement.parentNode.children, drag.dropContainer.element.nativeElement);
    //         let dropIndex = this.__indexOf(dropElement.parentNode.children, dropElement);

    //         if (!this.source) {
    //             this.sourceIndex = dragIndex;
    //             this.source = drag.dropContainer;

    //             let sourceElement = this.source.element.nativeElement;
    //             phElement.style.width = sourceElement.clientWidth + 'px';
    //             phElement.style.height = sourceElement.clientHeight + 'px';

    //             sourceElement.parentNode.removeChild(sourceElement);
    //         }

    //         this.targetIndex = dropIndex;
    //         this.target = drop;

    //         phElement.style.display = '';
    //         dropElement.parentNode.insertBefore(phElement, (dragIndex < dropIndex)
    //             ? dropElement.nextSibling : dropElement);

    //         this.source.start();
    //         this.placeholder_list.enter(drag, drag.element.nativeElement.offsetLeft, drag.element.nativeElement.offsetTop);
    //     }
    //     return false;
    // }

    // __indexOf(collection, node) {
    //     return Array.prototype.indexOf.call(collection, node);
    // };

    // drop(event: CdkDragDrop<any[]>) {
    //     console.log('this.array:', JSON.parse(JSON.stringify(this.array)))
    //     console.log('event:', event)
    //     moveItemInArray(this.array, event.previousIndex, event.currentIndex);
    //     // moveItemInArray(this.item.parametros, event.previousIndex, event.currentIndex);
    //     // moveItemInArray();
    //     console.log('this.array:', JSON.parse(JSON.stringify(this.array)))
    // }

    getMask(idx) {
        idx = this.repeatmask ? idx % this.mask.length : idx;
        return this.mask ? this.mask[idx] ? this.mask[idx] + ': ' : '' : '';
    }

    getPlaceholder() {
        // return '';
        return this.array.length != this.max ? this.array.length !== 0 ? '...' : this.placeholder : '';
    }

    emitValues() {
        this.value = JSON.stringify(this.array);
        this.valueChange.emit(this.value);
        this.arrayChange.emit(this.array);
    }

    setFocus() {
        this.inp_value.nativeElement.focus();
    }

    del(value) {
        this.delValue(value);
        this.emitValues();
    }

    del_last(inp_value) {
        if (!inp_value.value) {
            this.array.pop();
        }
        this.emitValues();
    }

    add(inp_value, bool) {
        let value = this.newValue.replace(/[\r\n\t]/g, '');
        if (value != '') {
            this.addValue(inp_value.value);
            inp_value.value = '';
            this.inp_value.nativeElement.value = '';
            this.newValue = '';
            this.emitValues();
        } else {
            if (bool) {
                this.onEnter.emit();
            }
        }
    }

    addValue(value) {
        value = value.replace(/[\r\n\t]/g, '');
        if (value != null && value != '' && value.trim() != '') {
            let idx_
            if (this.type == 0) {
                idx_ = this.array.findIndex(function (val) { return val == value; });
            }
            if (this.type == 1) {
                idx_ = this.array.findIndex(function (val) { return val.value == value; });
            }
            if (idx_ == -1 && this.array.length != this.max) {
                if (this.type == 0) {
                    this.array.push(value);
                }
                if (this.type == 1) {
                    this.array.push({
                        id: value,
                        value: value
                    });
                }
            }
        }
    }

    delValue(value) {
        value = value.replace(/[\r\n\t]/g, '');
        if (value != null && value != '' && value.trim() != '') {
            if (this.type == 0) {
                var filtered = this.array.filter(function (val) { return val !== value; });
            }
            if (this.type == 1) {
                var filtered = this.array.filter(function (val) { return val.value !== value; });
            }
            this.array = filtered;
        }
    }

    pasteValues(event: ClipboardEvent, inp_value) {
        if (event.clipboardData) {
            // this.ohService.getOH().getLoader().show();
            var clipboardData = event.clipboardData; // || window.clipboardData
            const pastedText = clipboardData.getData('text');
            if (pastedText.trim() !== '') {
                var splitValues = pastedText.split("\n");
                splitValues.forEach(element => {
                    this.addValue(element);
                });
            }
            setTimeout(() => {
                inp_value.value = '';
                this.inp_value.nativeElement.value = '';
                this.newValue = '';
                this.emitValues();
            });
        }
    }

    onBlurMethod() {
        console.log('sdas', 'hello')
    }

    // print() {
    //     console.log('this.array:', this.array)
    //     console.log('this.inp_value:', this.inp_value)
    //     console.log('this.value:', this.value)
    //     console.log('this.newValue:', this.newValue)
    // }
}