<input [hidden]="true" #inp_adjunto type="file" (change)="abrirCarga($event, inp_adjunto)" accept="image/*"
    multiple="true">
<div class="btn-group mb-2" role="group">
    <ng-template #tipDetail>
        <b>{{lang.limitPictures}}</b> : {{cantidad}}<br>
        <b>{{lang.usePaste}}</b>
    </ng-template>
    <button type="button" id="{{_generatedId}}" class="btn btn-secondary" [ngbTooltip]="tipDetail" placement="right"
        *ngIf="!readonly"><i class="fas fa-info"></i></button>
    <button type="button" class="btn btn-primary" (click)="abrirCamara()"
        [disabled]="cargando || (_fotos_camara && cantidad == _fotos_camara.length)" *ngIf="!readonly"><i
            class="fas fa-camera"></i> {{lang.usePaste}}</button>
    <button type="button" class="btn btn-info" (click)="inp_adjunto.click()"
        [disabled]="cargando || (_fotos_camara && cantidad == _fotos_camara.length)" *ngIf="!readonly"><i
            class="fas fa-upload"></i> {{lang.attach}}</button>
</div>
<div class="oh_viewPhotos" ohDragDrop (onFileDropped)="abrirCarga($event)" *ngIf="!mode_preview">
    <div class="oh_viewLane flex-row" #lanePhoto>
        <ng-template ngFor let-foto [ngForOf]="_fotos_data" let-i="index">
            <div class="oh_viewContentPhoto" *ngIf="!foto.url; else inputCargado">
                <img src="{{_fotos_camara[i]}}" class="oh_viewPhoto oh_viewPhotoOnCharge" />
                <div class="oh_charning"></div>
                <span style="opacity: .5; cursor: auto;" class="text-danger oh_viewDelete"><i
                        class="fas fa-times-circle"></i></span>
            </div>
            <ng-template #inputCargado>
                <div class="oh_viewContentPhoto">
                    <a *ngIf="!show_in_mode_preview" href="#"
                        (click)="$event.preventDefault(); mostrarImagen(modalFoto, _fotos_camara[i]);">
                        <img src="{{_fotos_camara[i]}}" class="oh_viewPhoto" />
                    </a>
                    <a *ngIf="show_in_mode_preview" href="#"
                        (click)="$event.preventDefault(); vistaPrevia(_fotos_camara[i], i, true);">
                        <img src="{{_fotos_camara[i]}}" class="oh_viewPhoto" />
                    </a>
                    <span *ngIf="!readonly" class="text-danger oh_viewDelete" (click)="fotoEliminar(i)"><i
                            class="fas fa-times-circle"></i></span>
                </div>
            </ng-template>
        </ng-template>
    </div>
</div>
<ng-template [ngIf]="mode_preview && _fotos_data.length > 0">
    <a class="oh_text-size-10 text-info oh_btn-link-buttom mr-1" style="display: block;" href="#"
        (click)="vistaPrevia(_fotos_camara[0], 0, true); $event.preventDefault();">
        <span class="badge badge-outline-danger">{{_fotos_data.length}}</span><i class="fas fa-images"></i>
    </a>
</ng-template>
<!----------------------------------------------------------------->
<ng-template #modalFoto let-c="close" let-d="dismiss">
    <div class="modal-header">
        <span class="h5 m-0"><i class="fas fa-camera-retro mr-1"></i>{{lang.picture}}</span>
        <a href="#" class="close text-dark" aria-label="Close" (click)="$event.preventDefault(); d('Cross click')"><i
                class="fas fa-times fa-xs"></i></a>
    </div>
    <div class="modal-body">
        <img [src]="imagenSeleccionada" width="97%">
    </div>
</ng-template>
<!----------------------------------------------------------------->
<ng-template #modalDetail let-modal>
    <div class="modal-header d-flex align-items-center">
        <button type="button" class="btn mr-1" (click)="atras()"
            [ngClass]="indice > 0 ? 'btn-outline-primary' : 'btn-outline-secondary'" [disabled]="!(indice > 0)">
            <i class="fas fa-chevron-circle-left"></i>
        </button>
        <button type="button" class="btn mr-1" (click)="adelante()"
            [ngClass]="indice < _fotos_data.length - 1 ? 'btn-outline-primary' : 'btn-outline-secondary'"
            [disabled]="!(indice < _fotos_data.length - 1)">
            <i class="fas fa-chevron-circle-right"></i>
        </button>
        <button type="button" class="btn btn-outline-info mr-2" (click)="descargar(foto_vista)">
            <i class="fas fa-download"></i>
        </button>
        <span class="h5 vistaBC mb-0">{{lang.preview}} {{indice+1}}/{{_fotos_data.length}}</span>
        <button type="button" class="close p-0 px-3" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <a href="#" (click)="$event.preventDefault(); descargar(foto_vista)">
            <img src="{{foto_vista}}" class="mx-auto d-block img-fluid img-thumbnail" />
        </a>
    </div>
    <div class="modal-footer">
    </div>
</ng-template>