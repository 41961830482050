<div class="modal-header">
  <h4 class="modal-title">{{_options.title}}</h4>
  <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('CLOSE')">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <p>{{message}}</p>
</div>
<div class="modal-footer">
  <button type="button" [ngClass]="_options.btnCancelBack" (click)="activeModal.close('CANCEL')"><i [ngClass]="_options.btnCancelIcon" aria-hidden="true"></i> {{_options.btnCancel}}</button>
  <button type="button" id="btn_confirm" [ngClass]="_options.btnAcceptBack" (click)="activeModal.close('ACCEPT')"><i [ngClass]="_options.btnAcceptIcon" aria-hidden="true"></i> {{_options.btnAccept}}</button>
</div>