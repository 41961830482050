import { AngularFirestore } from "@angular/fire/firestore";
import { ohStorage } from "../ohCore/services/oh.core";
import { AzureCosmos } from "../azure/cosmos";
import { map } from "rxjs/operators";

export interface LogWeb {
	id?: string,
	uid: string,
	fecha: Date,
	rnd: string,
	tiempo: number,
	paquete: string,
	clase: string,
	metodo: string,
	log: string,
	pk?: string
}

export class INLANDLog {

	private firestore: AngularFirestore;
	private coleccion: string = "log"; // '/APM_DESA/INLAND/log/'
	private enviroment: string;
	private document: string;
	private azureCosmos: AzureCosmos

	private storage: ohStorage;

	constructor(firestore: AngularFirestore, enviroment: string, document: string, azureCosmos: AzureCosmos) {
		this.firestore = firestore;
		this.enviroment = enviroment;
		this.document = document;
		this.azureCosmos = azureCosmos
		this.storage = new ohStorage();
	}

	registrar(datos: LogWeb) {
		let log = this.storage.get("MAISL")
		if (log && log.enable) {
			datos.id = log.id;
			this.guardar(datos);
		}
	}

	guardar(usuario: LogWeb) {
		usuario.pk = usuario.rnd ? ("" + usuario.rnd).substring(2) : usuario.uid;
		if (this.azureCosmos.client) {
			return this.azureCosmos.set(this.document, this.coleccion, usuario, usuario.uid)
		} else {
			return this.firestore.doc<LogWeb>('/' + this.enviroment + '/' + this.document + '/' + this.coleccion + '/' + usuario.uid).set(usuario)
		}
	}

	listar() {
		if (this.azureCosmos.client) {
			return this.azureCosmos.getItems(this.document, this.coleccion, "1 = 1 ORDER BY c.fecha DESC OFFSET 0 LIMIT 1000")
		} else {
			return this.firestore.collection<any>('/' + this.enviroment + '/' + this.document + '/' + this.coleccion, ref => ref.orderBy("fecha", "desc").limit(1000)).snapshotChanges().pipe(
				map(actions => {
					return actions.map((a: any) => {
						const data = a.payload.doc.data();
						const id = a.payload.doc.id;
						return { id, ...data };
					});
				})
			);;
		}
	}

	/*


	enlazar(uid: string){
		return this.firestore.doc<LogWeb>('/'+this.enviroment+'/'+this.document+'/'+this.coleccion+'/' + uid).snapshotChanges().pipe(
			map(({ payload }) => ({ ...payload.data(), id: payload.id }))
		);
	}

	editar(usuario: LogWeb) {
		this.firestore.doc<LogWeb>('/'+this.enviroment+'/'+this.document+'/'+this.coleccion+'/' + usuario.uid).update(usuario).then(() => {
		});
	}

	eliminar(uid: string){
		this.firestore.collection<LogWeb>('/'+this.enviroment+'/'+this.document+'/'+this.coleccion+'').doc('' + uid).delete().then(() => {
		});
	}
*/
}
