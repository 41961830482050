<div *ngIf="_codeText && lang != 'text'" style="position: relative;">
    <button type="button" class="btn btn-sm btn-light mx-2" [ohCopyText]="text_copy"
        style="position: absolute; right: 0px; top: 7px;border: 1px solid gray;"><i class="far fa-copy"></i></button>
    <textarea #text_copy [innerHTML]="_codeText" style="height: 0px;position: absolute;z-index: -5;"></textarea>
    <textarea [ohHighlight]="_codeText" [options]="{}" [lang]="lang"></textarea>
</div>
<div *ngIf="_codeText && lang == 'text'" class="input-group mb-3">
    <input #text_copy type="text" class="form-control" value="{{_codeText}}" disabled="disabled">
    <button type="button" class="btn btn-outline-secondary" [ohCopyText]="text_copy"><i
            class="far fa-copy"></i></button>
</div>