import { Component, Input, Output, EventEmitter  } from '@angular/core';
import { NgbDatepickerI18n, NgbDate, NgbCalendar, NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';

import { I18n, CustomDatepickerI18n } from './oh.dateConfiguration';

// <oh-dateranges [(from)]="fechafrom" [(to)]="fechato" (onChange)="algunaFuncion()"></oh-dateranges>

@Component({
  selector: 'oh-dateranges',
  templateUrl: './oh.dateRanges.html',
  styleUrls: ['./oh.dateRanges.css'],
  providers: [I18n, {provide: NgbDatepickerI18n, useClass: CustomDatepickerI18n}]
})
export class DateRanges {

  @Input()
    set from(valor : any) {
      if(valor){
        let fecha = new Date(valor);
        this.fromDate = new NgbDate(fecha.getFullYear(), fecha.getMonth()+1, fecha.getDate());
      }
    }
  @Output() fromChange: EventEmitter<Date>; // hace binding al padre.

  @Input()
    set to(valor : any) {
      if(valor){
        let fecha = new Date(valor);
        this.toDate = new NgbDate(fecha.getFullYear(), fecha.getMonth()+1, fecha.getDate());
      }
    }
  @Output() toChange: EventEmitter<Date>; // hace binding al padre.

  @Output() onChange = new EventEmitter(); // llama a un evento

  hoveredDate: NgbDate | null = null;

  fromDate: NgbDate | null;
  toDate: NgbDate | null;

  constructor(private calendar: NgbCalendar, public formatter: NgbDateParserFormatter) {
    this.fromChange = new EventEmitter<Date>();
    this.toChange = new EventEmitter<Date>();
    //this.fromDate = calendar.getToday();
    //this.toDate = calendar.getNext(calendar.getToday(), 'd', 10);
  }

  onDateSelection(date: NgbDate, datepicker : any) {
    if (!this.fromDate && !this.toDate) {
      this.fromDate = date;
    } else if (this.fromDate && !this.toDate && date && date.after(this.fromDate)) {
      this.toDate = date;
    } else {
      this.toDate = null;
      this.fromDate = date;
    }

    console.log(datepicker)
    if(this.fromDate && this.toDate && datepicker){
      datepicker.toggle()
    }

    this.fromChange.emit(this.fromDate ? new Date(this.fromDate.year, this.fromDate.month-1, this.fromDate.day) : null)
    this.toChange.emit(this.toDate ? new Date(this.toDate.year, this.toDate.month-1, this.toDate.day) : null)
    this.onChange.emit()

  }

  isHovered(date: NgbDate) {
    return this.fromDate && !this.toDate && this.hoveredDate && date.after(this.fromDate) && date.before(this.hoveredDate);
  }

  isInside(date: NgbDate) {
    return this.toDate && date.after(this.fromDate) && date.before(this.toDate);
  }

  isRange(date: NgbDate) {
    return date.equals(this.fromDate) || (this.toDate && date.equals(this.toDate)) || this.isInside(date) || this.isHovered(date);
  }

  validateInput(currentValue: NgbDate | null, input: string): NgbDate | null {
    const parsed = this.formatter.parse(input);
    return parsed && this.calendar.isValid(NgbDate.from(parsed)) ? NgbDate.from(parsed) : currentValue;
  }
  
}

/*
@Component({
  selector: 'oh-dateranges',
  templateUrl: './oh.dateRanges.html',
  styleUrls: ['./oh.dateRanges.css'],
  providers: [I18n, {provide: NgbDatepickerI18n, useClass: CustomDatepickerI18n}]
})
export class DateRanges {

  @Input()
    get from(): any { 
      return this._from; 
    }
    set from(valor : any) {
      let fecha = new Date(valor);
      if(valor && fecha){
        this._from = new NgbDate(fecha.getFullYear(), fecha.getMonth()+1, fecha.getDate());
      }
    }

  @Input()
    get to(): any {
      return this._to; 
    }
    set to(valor : any) {
      let fecha = new Date(valor);
      if(valor && fecha){
        this._to = new NgbDate(fecha.getFullYear(), fecha.getMonth()+1, fecha.getDate());
      }
    }

  @Output() fromChange: EventEmitter<Date>; // hace binding al padre.
  @Output() toChange: EventEmitter<Date>; // hace binding al padre.
  
  @Input() disabled: boolean;
  @Input() required: boolean;
  @Input() oneDay: boolean = false;
  
  _from: NgbDate;
  _to: NgbDate;
  hoveredDate: NgbDate;
  
  @Output() onChange = new EventEmitter(); // llama a un evento

  mountsCount : number;

  constructor(calendar: NgbCalendar){
    this.fromChange = new EventEmitter<Date>();
    this.toChange = new EventEmitter<Date>();
    
    //if(!this._from){
    //  this._from = calendar.getNext(calendar.getToday(), 'd', -30);
    //}
   // if(!this._to){
   //   this._to = calendar.getToday();
   // }
    this.disabled = false;
    this.mountSizes(window.screen.width);
  }

  onDateSelection(date: NgbDate) {
    if(this.oneDay){
        this._from = date;
        this._to = date;
    } else {
      if (!this._from && !this._to) {
        this._from = date;
      } else if (this._from && !this._to && date.after(this._from)) {
        this._to = date;
      } else {
        this._to = null;
        this._from = date;
      }
    }


    if(this._from){
      this.fromChange.emit(new Date(this._from.year, this._from.month-1, this._from.day));
    } else {
      this.fromChange.emit(null);
    }

    if(this._to){
      this.toChange.emit(new Date(this._to.year, this._to.month-1, this._to.day));
    } else {
      this.toChange.emit(null);
    }
    this.onChange.emit(); // new 
  }

  isHovered(date: NgbDate) {
    return this._from && !this._to && this.hoveredDate && date.after(this._from) && date.before(this.hoveredDate);
  }

  isInside(date: NgbDate) {
    return date.after(this._from) && date.before(this._to);
  }

  isRange(date: NgbDate) {
    return date.equals(this._from) || date.equals(this._to) || this.isInside(date) || this.isHovered(date);
  }
  
  // custom

  mountSizes(ancho: number){
    if(ancho<992){
      this.mountsCount = 1;
    } else {
      this.mountsCount = 2;
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.mountSizes(event.target.innerWidth);
  }

}
*/