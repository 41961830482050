import { map } from "rxjs/operators";
import { AngularFirestore, Query } from "@angular/fire/firestore";
import { AzureCosmos } from "../azure/cosmos";

export interface catalogo {
	catalogo_id?: number,
	catalogo_padre_id?: number,
	descripcion?: string,
	descricion_larga?: string,
	estado?: string,
	variable_1?: string,
	variable_2?: string,
	variable_3?: number
}

export class FIRECatalogo {

	private firestore: AngularFirestore;
	private coleccion: string = "catalogs"; // '/APM_DESA/ADM/catalogs/'
	private enviroment: string;
	private document: string;
	private azureCosmos: AzureCosmos

	constructor(firestore: AngularFirestore, enviroment: string, document: string, azureCosmos: AzureCosmos) {
		this.firestore = firestore;
		this.enviroment = enviroment;
		this.document = document;
		this.azureCosmos = azureCosmos
	}

	getCatalogoByPadres(ids: any, filter?: any) { // ids [1,2,3]

		let _querys = []

		_querys.push("c.catalogo_padre_id IN (" + ids.join(",") + ")")
		_querys.push('c.estado = true')

		if (filter) {
			if (filter['unidad_negocio_id']) { _querys.push('c.unidad_negocio_id = ' + filter.unidad_negocio_id) }
		}
		/*
			let _query = ""
			if (_querys.length > 0) {
				_query = " AND " + _querys.join(" AND ")
			}
		*/
		return this.azureCosmos.getItems(this.document, this.coleccion, _querys.join(" AND "), "c.id, c.catalogo_id, c.codigo, c.catalogo_padre_id, c.descricion_larga, c.descripcion, c.estado, c.unidad_negocio_id, c.variable_1, c.variable_2, c.variable_3, c.idioma, c.unidades")

	}

	getCatalogoByPadresAlias(alias: any, filter?: any) { // ids [1,2,3]

		let _querys = []

		let _alias = []
		alias.forEach(it => {
			_alias.push('"' + it + '"')
		})

		_querys.push("c.catalog_parent_alias IN (" + _alias.join(",") + ")")
		_querys.push('c.estado = true')

		if (filter) {
			if (filter['unidad_negocio_id']) { _querys.push('c.unidad_negocio_id = ' + filter.unidad_negocio_id) }
		}
		/*
			let _query = ""
			if (_querys.length > 0) {
				_query = " AND " + _querys.join(" AND ")
			}
		*/
		return this.azureCosmos.getItems(this.document, this.coleccion, _querys.join(" AND "), "c.id, c.catalogo_id, c.codigo, c.catalogo_padre_id, c.descricion_larga, c.descripcion, c.estado, c.unidad_negocio_id, c.variable_1, c.variable_2, c.variable_3, c.idioma, c.unidades, c.catalog_parent_alias, c.alias")

	}

	getCatalogoByPadre(id, filter?: any) {

		if (this.azureCosmos.client) {

			let _querys = []
			if (filter) {
				if (filter['unidad_negocio_id']) { _querys.push('c.unidad_negocio_id = ' + filter.unidad_negocio_id) }
			}

			let _query = ""
			if (_querys.length > 0) {
				_query = " AND " + _querys.join("AND")
			}

			return this.azureCosmos.getItems(this.document, this.coleccion, "c.catalogo_padre_id = " + id + _query)

		} else {

			return this.firestore.collection<catalogo>('/' + this.enviroment + '/' + this.document + '/' + this.coleccion, ref => {
				let query: Query = ref
				query = query.where('catalogo_padre_id', '==', id)
				if (filter) {
					if (filter['unidad_negocio_id']) { query = query.where('unidad_negocio_id', '==', filter.unidad_negocio_id) }
					// se pueden añadir los filtros que crean convenientes
				}
				return query;
			}
				//ref.where('catalogo_padre_id', '==', id)
			).snapshotChanges().pipe(
				map(actions => {
					return actions.map((a: any) => {
						const data = a.payload.doc.data();
						const id = a.payload.doc.id;
						return { id, ...data };
					});
				})
			)

		}
	}

	getCatalogosAll() {
		if (this.azureCosmos.client) {
			return this.azureCosmos.getItems(this.document, this.coleccion)
		} else {
			return this.firestore.collection<catalogo>('/' + this.enviroment + '/' + this.document + '/' + this.coleccion).snapshotChanges().pipe(
				map(actions => {
					return actions.map((a: any) => {
						const data = a.payload.doc.data();
						const id = a.payload.doc.id;
						return { id, ...data };
					});
				})
			)
		}
	}

	addCatalogo(catalogo: catalogo, tipoServicio?: number) {
		if (!this.validarFire(tipoServicio)) {
			return this.azureCosmos.set(this.document, this.coleccion, catalogo, "" + catalogo.catalogo_id)
		} else {
			return this.firestore.doc<catalogo>('/' + this.enviroment + '/' + this.document + '/' + this.coleccion + '/' + catalogo.catalogo_id).set(catalogo)
		}
	}

	addCatalogos(catalogos: catalogo[], tipoServicio?: number) {
		if (!this.validarFire(tipoServicio)) {
			return this.azureCosmos.setItems(this.document, this.coleccion, catalogos, "catalogo_id")
		} else {
			for (var i in catalogos) {
				this.addCatalogo(catalogos[i], tipoServicio);
			}
		}
	}

	editCatalogo(catalogo: catalogo) {
		if (this.azureCosmos.client) {
			this.azureCosmos.update(this.document, this.coleccion, catalogo, "" + catalogo.catalogo_id).catch(() => {
				this.addCatalogo(catalogo)
			})
		} else {
			this.firestore.doc<catalogo>('/' + this.enviroment + '/' + this.document + '/' + this.coleccion + '/' + catalogo.catalogo_id).update(catalogo).catch(error => {
				this.addCatalogo(catalogo)
			})
		}
	}

	removeCatalogo(catalogo: catalogo) {
		if (this.azureCosmos.client) {
			this.azureCosmos.delete(this.document, this.coleccion, "" + catalogo.catalogo_id)
		} else {
			this.firestore.doc<catalogo>('/' + this.enviroment + '/' + this.document + '/' + this.coleccion + '/' + catalogo.catalogo_id).delete()
		}
	}

	removeAll() {
		return this.firestore.collection('/' + this.enviroment + '/' + this.document + '/' + this.coleccion).get().forEach((doc) => {
			var batch = this.firestore.firestore.batch();
			doc.docs.forEach((doc) => {
				batch.delete(doc.ref);
			});
			return batch.commit().then(() => {
				return doc.size;
			});
		})
	}

	private validarFire(tipoServicio: number) {
		let firebase: boolean
		if (tipoServicio == 2) {
			firebase = true
		} else {
			if (tipoServicio == 1 || this.azureCosmos.client) {
				firebase = false
			} else {
				firebase = true
			}
		}
		return firebase
	}

	eliminarCatalogoPadre(catalogo: catalogo, tipoServicio?: number) {
		if (!this.validarFire(tipoServicio)) {
			return this.azureCosmos.deleteByWhere(this.document, this.coleccion, "c.catalogo_padre_id = " + catalogo.catalogo_id + " OR c.catalogo_id = " + catalogo.catalogo_id)
		} else {
			return this.firestore.collection('/' + this.enviroment + '/' + this.document + '/' + this.coleccion, ref => ref.where('catalogo_padre_id', '==', catalogo.catalogo_id)).get().forEach((doc) => {
				var batch = this.firestore.firestore.batch();
				doc.docs.forEach((doc) => {
					batch.delete(doc.ref);
				});
				return batch.commit().then(() => {
					return doc.size;
				});
			})
		}
	}

	eliminarCatalogsIds(catalogos_id: any[]) {
		if (this.azureCosmos.client) {
			return this.azureCosmos.deleteByWhere(this.document, this.coleccion, "c.catalogo_id IN (" + catalogos_id.join(",") + ")")
		}
	}

	eliminarCatalogsAlias(alias: any[]) {
		if (this.azureCosmos.client) {
			let _alias = []
			alias.forEach(it => {
				_alias.push('"' + it + '"')
			})
			return this.azureCosmos.deleteByWhere(this.document, this.coleccion, "c.alias IN (" + _alias.join(",") + ") OR c.catalog_parent_alias IN (" + _alias.join(",") + ")")
		}
	}

}