import { Injectable } from '@angular/core';

import { Loader, Ad, Camera } from './ohCore/components/oh.core';
import { MainService } from './ohCore/services/oh.mainService';
import { INLANDLog } from './firebase/log';

export const ohObjDateFormatCustom = {
  format : "DD/MM/YYYY",
  formatTime : "DD/MM/YYYY HH:mm",
  I18n : {
    weekdays: ['Lu', 'Ma', 'Mi', 'Ju', 'Vi', 'Sa', 'Do'],
    mounts: ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'],
    smallMounts: ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul', 'Ago', 'Sep', 'Oct', 'Nov', 'Dic'],
    weekLabel: 'Sem'
  }
};

@Injectable()
export class OHService {

  constructor(private ohMainService : MainService){}

  public getOH(){
    return this.ohMainService;
  }

  public initialize(loader : Loader, aviso : Ad, camera : Camera, loaderAttributes : any, data : any, logs ?: INLANDLog){
    loader.setAttributes(loaderAttributes);
    this.ohMainService.setLoader(loader);
    this.ohMainService.setAd(aviso);
    this.ohMainService.setCamera(camera);
    this.ohMainService.setData(data);
    this.ohMainService.setLogs(logs);
  }

}