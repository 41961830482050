import { Component, Input, EventEmitter, Output, ViewChild, OnDestroy } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { NgModel, NgForm, FormGroup, FormBuilder, AbstractControl, NG_VALIDATORS, NG_VALUE_ACCESSOR, ValidationErrors, ControlValueAccessor, Validator } from '@angular/forms';
import { NgbModal, NgbTypeahead, NgbTypeaheadConfig } from '@ng-bootstrap/ng-bootstrap';
import { debounceTime, distinctUntilChanged, map, filter, tap, switchMap, catchError, finalize } from 'rxjs/operators';
import { Observable, Subject, merge, of, OperatorFunction, Subscription } from 'rxjs';

@Component({
    selector: 'oh-predictive-text',
    styleUrls: ['./oh.predictiveText.css'],
    templateUrl: './oh.predictiveText.html',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            multi: true,
            useExisting: PredictiveText
        },
        {
            provide: NG_VALIDATORS,
            multi: true,
            useExisting: PredictiveText
        }
    ]
})

export class PredictiveText implements ControlValueAccessor, Validator, OnDestroy {

    isValid: boolean = true

    @Input()
    set value(value: any) {
        this._value = value
        this.mapPtValue()
    }
    @Output() valueChange = new EventEmitter(); // llama a un evento

    _value: string

    _ptValue: any
    _ptObsValue: any

    isObserver: boolean

    @Input()
    set data(data: any) {
        this.isObserver = (data && typeof (data) == "function") ? true : false
        this._data = data
        this.mapPtValue()
    }
    _data: any

    @Input() searchBy: any;              // Array of fields so search, Ex: ['descripcion']
    //@Input() return: string;                    // Element of array to return

    @Input()
    set return(myreturn: string) {        // Element of array to return
        this._return = myreturn
        //this.mapPtValue()
    }

    _return: string

    @Input() form: NgForm;
    @Input() name: string;              // Is neccesary for "form" input
    @Input() readonly: boolean;
    @Input() disabled: boolean;
    @Input() required: boolean = false;
    @Input() openOnClick: boolean = true;       // list result on click or focus input
    @Input() numViewOpen: number = 15;          // number of results show when click or focus input

    @Input() formGroup: FormGroup;
    @Input() fControlName: string;

    @Input() resultTemplate: any;

    @Input() placeholder: string = 'Search...';// placeholder for Input

    @Input()
    set selectedLabel(selectedLabel: any) {
        this._selectedLabel = selectedLabel
        this.mapPtValue()
    }
    _selectedLabel: any

    @Output() selectedLabelChange = new EventEmitter(); // llama a un evento

    @ViewChild('inp_predictiveText', { static: false }) inp_predictiveText: NgbTypeahead;
    focus$ = new Subject<string>();
    click$ = new Subject<string>();

    @Input() forceMobile: boolean
    isMobile: boolean
    isSearching: boolean

    @ViewChild('inp_hd', { static: false }) inp_hd: NgModel;

    @Output() onChangeEvent: EventEmitter<any> = new EventEmitter();
    @Output() onClean: EventEmitter<any> = new EventEmitter();

    // Reactive
    myChange: Subscription

    @Input() size: string;
    @Input() mapData: any;

    @ViewChild('modalSearch', { static: false }) modalSearch: any;

    constructor(config: NgbTypeaheadConfig, private http: HttpClient, private modalService: NgbModal, private fb: FormBuilder) {
        this.valueChange = new EventEmitter<any>();
    }

    ngOnInit() {
        this.isMobile = this.forceMobile || (window['browserInfo'] && window['browserInfo']['isMobile'] ? window['browserInfo'].isMobile() : false)
    }

    checkValidators() {

    }

    ngAfterViewInit() {
        setTimeout(() => {
            if (this.required && this.form && this.inp_hd) {
                this.inp_hd.name = 'inp_hd' + this.name;
                this.form.addControl(this.inp_hd);
            }
        })
    }

    ngOnDestroy() {
        if (this.myChange) {
            this.myChange.unsubscribe()
        }
    }

    onSearchEvent: OperatorFunction<string, readonly string[]> = (text$: Observable<string>) => {

        var pt = document.getElementById("inp_predictiveText")
        var isOpenpt = false
        if (pt) {
            isOpenpt = document.getElementById("inp_predictiveText").className.indexOf("open") != -1 ? true : false
        }

        var debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
        const clicksWithClosedPopup$ = this.click$.pipe(filter(() => !isOpenpt)); // this.inp_predictiveText.isPopupOpen()

        const inputFocus$ = this.focus$;

        if (this.openOnClick) {
            debouncedText$ = merge(debouncedText$, inputFocus$, clicksWithClosedPopup$)
        }

        if (this.isObserver) {
            return debouncedText$.pipe(
                switchMap(term =>
                    (this.mapData ? this._data(term, this.mapData) : this._data(term)).pipe(
                        finalize(() => {
                            this.isSearching = false
                        }),
                        catchError(() => {
                            return of([]);
                        })
                    )
                ),
                tap(() => {
                    this.isSearching = true
                })
            )
        } else {
            return debouncedText$.pipe(
                map((term) => {
                    if (this.openOnClick) {
                        if (term === '') {
                            return this._data
                        } else {
                            return this._data.filter(it => this.getMappedValues(it).toLowerCase().indexOf(term.toLowerCase()) > -1).slice(0, 10)
                        }
                    } else {
                        if (term.length < 2) {
                            return []
                        } else {
                            return this._data.filter(it => this.getMappedValues(it).toLowerCase().indexOf(term.toLowerCase()) > -1).slice(0, 10)
                        }
                    }
                })
            )
        }

    }

    onClick($event: any) {
        if ($event.srcElement.offsetWidth) {
            var root: any = document.querySelector(':root');
            root.style.setProperty('--ngtypeaheadmaxwidth', $event.srcElement.offsetWidth + 'px');
        }
    }

    /*  Get de mapped value
        searchBy -> "description"                           Can search one value
        searchBy -> ["description", "description_large"]    Can search multiple values
        searchBy -> null                                    If it's null search for the first value of the element
    */
    getMappedValues(item) {
        var _fieldSearch = ""
        if (item) {
            if (typeof (item) == "string") {
                _fieldSearch = item
            } else {
                if (this.searchBy) { // "fullname" or "['fullname','lastname']"
                    if (typeof (this.searchBy) == "object") {
                        let fields = []
                        this.searchBy.forEach(it => {
                            fields.push(item[it])
                        })
                        _fieldSearch = fields.join(" - ")
                    } else {
                        _fieldSearch = item[this.searchBy]
                    }
                } else {
                    for (var it in item) {
                        _fieldSearch = item[it]
                        break
                    }
                }
            }
        }
        return _fieldSearch
    }

    isSelected: boolean
    onSelect($event: any, c?: any) {
        this.markAsTouched();
        if (this.isSelected && c) {
            c("")
        }
    }

    customFormatter = (result: any) => {
        return this.getMappedValues(result)
    }

    clearSelection() {
        if (!this.disabled && !this.readonly) {
            this._value = null;
            this.valueChange.emit(null)
            this._ptValue = null;
            this._ptObsValue = null;
            this.onChangeEvent.emit(null);
            this.onChange(this._value);
            this.selectedLabelChange.emit(null);
            this.isSelected = false;
            this.onClean.emit();
        }
    }

    mapPtValue() {
        this.markAsTouched();

        if (this._value && this._data && !this.isObserver && this._data.length > 0) {
            if (this._return) {
                this._ptValue = this._data.find(it => it[this._return] == this._value)
            } else {
                this._ptValue = this._data.find(it => this.shallowEqual(it, this._value))
            }
            if (!this._ptValue) {
                this._value = null
            }
        } else {
            this._ptValue = null
            this.onChange(this._value);
        }

        if (this._value && this._selectedLabel && this.isObserver) {
            this._ptObsValue = this._selectedLabel
        }
    }

    private shallowEqual(object1, object2) {
        const keys1 = Object.keys(object1);
        const keys2 = Object.keys(object2);
        if (keys1.length !== keys2.length) {
            return false;
        }
        for (let key of keys1) {
            if (object1[key] !== object2[key]) {
                return false;
            }
        }
        return true;
    }

    openModalSearch(modal: any) {
        console.log(modal);
        this.modalService.open(modal, { backdrop: 'static' }).result.then((result) => {
        }, (reason) => {
        });
        window.setTimeout(() => {
            document.getElementById("inp_predictiveText").focus()
        })
    }

    templateFormatter = (selectedValue: any) => {
        var realValue = this._return ? selectedValue[this._return] : selectedValue

        this.value = realValue;
        this.valueChange.emit(realValue);
        this.onChange(realValue);
        this.onChangeEvent.emit(selectedValue);
        this.isSelected = true
        if (this.isObserver) {
            this._ptObsValue = selectedValue
        }
        return selectedValue[this.searchBy]
    }

    //#region ControlValueAccesor
    onChange = (quantity) => { };
    onTouched = () => { };
    touched = false;

    writeValue(value: any) {
        this.value = value;
    }
    registerOnChange(onChange: any) {
        this.onChange = onChange;
    }
    registerOnTouched(onTouched: any) {
        this.onTouched = onTouched;
    }
    markAsTouched() {
        if (!this.touched) {
            this.onTouched();
            this.touched = true;
        }
    }
    setDisabledState(disabled: boolean) {
        this.disabled = disabled;
    }

    validate(control: AbstractControl): ValidationErrors | null {

        if (control.status == "VALID" && control.errors && control.errors.required && !control.value) {
            this.required = true
            this.isValid = false
        }

        if (!this.myChange) {
            this.myChange = control.statusChanges.subscribe(c => {
                if (c != "DISABLED") {
                    this.required = (c == "INVALID") ? true : false
                    this.isValid = (c == "VALID") ? true : false
                }
            })
        }
        return null;
    }

}