import { Input, Component } from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { OHService } from "src/app/tis.ohService";
// <oh-elipsis-text [inputText]="variableInputTexto" [type]="'INLINE'" [maxWidh]="250" [measure]="'px'"></oh-elipsis-text>
// <oh-elipsis-text [inputText]="variableInputTexto" [type]="'MULTILINE'" [lines]="2"></oh-elipsis-text> '''SE OBVIA LOS OTROS DOS PARAMETROS/optional'''

// <oh-elipsis-text
// [inputText]="item.glosa"
// [type]="'HTML'"
// ></oh-elipsis-text>
@Component({
  selector: "oh-elipsis-text",
  templateUrl: "./oh.elipsis-text.html",
  styleUrls: ["./oh.elipsis-text.css"],
})
export class ElipsisText {
  @Input() inputText: any;
  @Input() type?: "INLINE" | "MULTILINE" | "HTML" | string = "INLINE";
  @Input() measure?: "px" | "%" | "rem" | string = "px"; //default: px
  @Input() maxWidh: any = 200; //default: 200
  @Input() lines: any = 2; //default: 2

  class: any;
  constructor(public ngbModal: NgbModal, private ohService: OHService) {
    this.class = {};
  }

  ngAfterViewInit() {
    switch (this.type) {
      case "INLINE":
        this.class = {
          "white-space": "nowrap",
          overflow: "hidden",
          "text-overflow": "ellipsis",
          "max-width": "" + this.maxWidh + this.measure,
        };
        break;
      case "MULTILINE":
        this.class = {
          overflow: "hidden",
          display: "-webkit-box",
          "-webkit-line-clamp": "" + this.lines,
          "-webkit-box-orient": "vertical",
        };
        break;
      default:
        this.class = {};
    }
  }

  openModal(modalCorreos) {
    this.ngbModal
      .open(modalCorreos, { backdrop: "static", size: "xl" })
      .result.then(
        (result) => {
          if (result == "Save") {
          }
        },
        (reason) => {}
      );
  }

  copyContent() {
    var copyText: any = document.getElementById("textAreaCopy");
    copyText.select();
    document.execCommand("copy");
    this.ohService.getOH().getAd().success("Copiado correctamente");
  }
}
