import { Input, Component } from '@angular/core';
import { AngularFireStorage } from '@angular/fire/storage';
import { Observable, Subscription } from 'rxjs';
import { INDAzureStorage } from 'src/app/azure/storage';
import { environment } from 'src/environments/environment';
import { ohStorage } from '../../services/oh.core';
// <oh-thumbnail [id]="item.usuario_id" [name]="item.nombres" [lastName]="item.apellidos" [size]="sm"></oh-thumbnail>
@Component({
  selector: 'oh-thumbnail',
  templateUrl: './oh.thumbnail.html',
  styleUrls: ['./oh.thumbnail.css'],
})
export class Thumbnail {
  private storage: ohStorage;

  photo: any = null;
  @Input()
  set id(id: any) {
    this.getPictureSM(id);
    //this.photo = null;
  }

  @Input() shortName: boolean = true;
  @Input() size: string; // xs = 30px | sm = 36px | md = 72px | lg = 128px
  @Input() strong: boolean = false;
  @Input() showName: boolean = true;
  @Input() monocromatic: boolean = false;
  @Input() textInDown: boolean = false;
  @Input() align: string = 'text-left';

  _size: number = 36;
  _name: string;
  _nameShort: string = '';
  _nameFirst: string = '';
  _lastName: string = '';
  _lastNameShort: string = '';

  index_color: number = 0;

  test: string = '#daded4';

  colors: any = [
    ['#FEC8D8', '#754956'],
    ['#FFFD96', '#666537'],
    ['#added4', '#38665b'],
    ['#BEE1EE', '#586d75'],
    ['#b3e3c6', '#2f593f'],
    ['#b3d2e6', '#37576b'],
    ['#E0BBE4', '#67406b'],
    ['#E6E6CA', '#5e5e43'],
    ['#FF6961', '#702622'],
    ['#97C9E5', '#47606e'],
    ['#FFB447', '#8c6224'],
    ['#8DA290', '#445446'],
  ];

  @Input()
  set name(name: any) {
    if (name && name.length > 0) {
      this._name = name;
      let indice = name.indexOf(' ');
      let nameShort = name;
      if (indice > 0) {
        nameShort = name.substring(0, name.indexOf(' '));
      }
      this._nameFirst = nameShort.charAt(0).toUpperCase();
      this._nameShort = this._nameFirst + nameShort.substring(1).toLowerCase();
    }
    this.getColor();
    this.getSize();
  }

  @Input()
  set lastName(lastName: any) {
    if (lastName && lastName.length > 0) {
      this._lastName = lastName;
      this._lastNameShort = lastName.charAt(0).toUpperCase();
    }
    this.getColor();
    this.getSize();
  }

  constructor(private fireStorage: AngularFireStorage, private indAzureStorage: INDAzureStorage) {
    this.storage = new ohStorage();
  }

  _thumbnail: Subscription;

  ngOnDestroy() {
    if (this._thumbnail && this._thumbnail.unsubscribe) {
      this._thumbnail.unsubscribe();
      console.log('loadFBPictured');
    }
  }

  getSize() {
    switch (this.size) {
      case 'xs':
        this._size = 27;
        break;
      case 'sm':
        this._size = 36;
        break;
      case 'md':
        this._size = 72;
        break;
      case 'lg':
        this._size = 128;
        break;
      default:
        this._size = 36;
        break;
    }
  }

  getClass() {
    let class_ = '';
    class_ += this.size == 'xs' ? 'fa-sm' : '';
    class_ += this.strong ? 'text-bold text-success' : '';
    return class_;
  }

  getColor() {
    if (this._name && this._lastName) {
      let nombre = this._name + ' ' + this._lastName;

      let indice = 0;
      if (nombre.length >= this.colors.length) {
        indice = nombre.length % this.colors.length;
      } else {
        indice = nombre.length;
      }
      this.index_color = indice;
    }
  }

  getPictureSM(usuario_id) {
    this.loadFBPicture(usuario_id, {});
    /*
    let thumbs = this.storage.item("APM_DATA", "thumb") || {}
    if(thumbs[usuario_id]){

      this.photo = thumbs[usuario_id]
      var image = new Image()
          image.onerror = (errorEvent) => {

            if(thumbs[usuario_id]){
              delete thumbs[usuario_id]
              this.storage.add("APM_DATA", "thumb", thumbs)
            }
            this.photo = null

            this.loadFBPicture(usuario_id, thumbs)

          }
          image.src = this.photo;


    } else {
      this.loadFBPicture(usuario_id, thumbs)
    }
*/
  }

  private async loadFBPicture(usuario_id: any, thumbs: any) {
    if (usuario_id) {

      if (this.indAzureStorage.isEnabled()) {

        this.indAzureStorage.getItem("inland", "PICTURE/SM/" + usuario_id).then((it) => {
          if (it && it.url) {
            this.photo = it.url
            thumbs[usuario_id] = it.url
          }
        })

      } else {

        let fb_ref = this.fireStorage.ref('/' + environment.firebase_coleccion_base + '/PICTURE/SM/' + usuario_id)

        this._thumbnail = fb_ref.getDownloadURL().subscribe(url => {
          if (url) {
            this.photo = url
            thumbs[usuario_id] = url
          }
          this._thumbnail.unsubscribe()
        })

      }

    }
  }
}
